import { redirect, RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { z } from "zod";

import {
  AddHalfProductDocument,
  AddHalfProductMutation,
  AddHalfProductMutationVariables,
} from "type";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./State";
import { formSchema } from "./formSchema";
import { Form } from "./components/Form";

export type ErrorTypeName =
  | AddHalfProductMutation["halfProduct"]["create"]["errors"][0]["__typename"]
  | "UnhandledError";

export type ActionData = {
  id: string | null;
  errors: {
    __typename: ErrorTypeName;
    message: string;
  }[];
};

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }) => {
    const formData = (await request.json()) as z.infer<typeof formSchema>;
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["createHalfProduct"],
        queryFn: async () =>
          graphQLClient.request<
            AddHalfProductMutation,
            AddHalfProductMutationVariables
          >({
            document: AddHalfProductDocument,
            variables: {
              input: {
                name: formData.name,
                ingredients:
                  formData.ingredients.length === 0
                    ? null
                    : formData.ingredients.map((ingredient) => ({
                        id: ingredient.id,
                        weight: ingredient.weight,
                      })),
                subHalfProducts:
                  formData.subHalfProducts.length === 0
                    ? null
                    : formData.subHalfProducts.map((halfProduct) => ({
                        id: halfProduct.id,
                        weight: halfProduct.weight,
                      })),
              },
            },
          }),
        staleTime: 0,
      })
    ).halfProduct.create;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "HalfProductNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
        return {
          id: null,
          errors: [
            {
              __typename: "UnhandledError",
              message: "Unhandled Error", // Probably pass some stuff?
            },
          ],
        };
      }
      return { id: null, errors: [error] };
    }
    return { id: result.halfProduct!.id, errors: [] };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.AddHalfProduct as string,
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Form />
      </StateProvider>
    ),
  };
};

export { getRoute as getAddHalfProductRoute };
