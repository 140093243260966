import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

import { useLocation } from "react-router";
import {
  GetIngredientsFilteredDocument,
  GetIngredientsFilteredQuery,
  GetIngredientsFilteredQueryVariables,
} from "type";
import { useGqlContext } from "GqlContext";

export type Ingredient = GetIngredientsFilteredQuery["ingredients"][0];

interface StateContext {
  searchValue: string | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  ingredients: Ingredient[];
  setIngredients: React.Dispatch<React.SetStateAction<Ingredient[]>>;
  isLoading: boolean;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { queryClient, graphQLClient } = useGqlContext();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    setIsLoading(true);
    queryClient.cancelQueries({ queryKey: ["ingredientsFiletered"] }, {});
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      try {
        const fetchedIngredients = (
          await queryClient.fetchQuery({
            queryKey: ["ingredientsFiletered"],
            queryFn: ({ signal }) => {
              return graphQLClient.request<
                GetIngredientsFilteredQuery,
                GetIngredientsFilteredQueryVariables
              >({
                document: GetIngredientsFilteredDocument,
                variables: {
                  where: searchValue
                    ? { name: { contains: searchValue } }
                    : null,
                },
                signal,
              });
            },
            staleTime: 0,
          })
        ).ingredients;
        setIngredients(fetchedIngredients);
        setIsLoading(false);
      } catch {
        const controller = new AbortController();
        controller.abort();
      }
    }, 300);
    setTimeoutId(newTimeoutId);
  }, [searchValue]);

  return (
    <StateContext.Provider
      value={{
        searchValue,
        setSearchValue,
        ingredients,
        setIngredients,
        isLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
