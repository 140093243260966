import { useEffect } from "react";
import { useActionData } from "react-router";

import { useStateContext } from "../State";
import { ActionData } from "../route";
import { Item } from "./Item";

const ItemList: React.FC = () => {
  const actionData = useActionData() as ActionData;

  const { halfProducts, setHalfProducts, isLoading } = useStateContext();

  useEffect(() => {
    if (actionData) {
      if (actionData.id !== null) {
        setHalfProducts(
          halfProducts.filter((halfProduct) => halfProduct.id !== actionData.id)
        );
      }
    }
  }, [actionData]);

  return (
    <div className="overflow-auto">
      <ul className="grid gap-y-px">
        {isLoading ? (
          <div className="text-white text-center">Ładowanie...</div>
        ) : halfProducts.length == 0 ? (
          <div className="text-center text-white">Brak półproduktów</div>
        ) : (
          halfProducts.map((halfProduct) => (
            <Item key={halfProduct.id} halfProduct={halfProduct} />
          ))
        )}
      </ul>
    </div>
  );
};

export { ItemList };
