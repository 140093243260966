import { useRef } from "react";

import { useStateContext } from "../state/StateProvider";
import { AddDayButton as AddDayButtonMobile } from "./mobile/AddDayButton";
import { AddDayButton as AddDayButtonPc } from "./pc/AddDayButton";
import { DayComponent } from "./Day";
import { CopyWeekButton } from "./CopyWeek";

const Days: React.FC = () => {
  const { isMobile, week } = useStateContext();
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="flex h-full w-full overflow-auto overscroll-contain gap-4 p-4"
      ref={containerRef}
    >
      <div className="flex gap-4 justify-between">
        {week
          .toList()
          .map((day) =>
            day.id ? <DayComponent key={day.id} day={day} /> : null
          )}
      </div>
      <div className="flex flex-col gap-2">
        {week.toList().some((day) => day.id === null) ? (
          isMobile ? (
            <AddDayButtonMobile parentRef={containerRef} />
          ) : (
            <AddDayButtonPc parentRef={containerRef} />
          )
        ) : null}
        <CopyWeekButton />
      </div>
    </div>
  );
};

export { Days };
