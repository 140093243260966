import React from "react";

import { ItemList } from "../components/ItemList";
import { Header } from "./Header";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "Shadcn/Resizable";

const Page: React.FC = () => {
  return (
    <div className="mx-auto px-5 pb-3 flex flex-grow h-full w-full max-w-xl flex-col ">
      <Header />
      <ResizablePanelGroup direction="vertical">
        <ResizablePanel defaultSize={70} className="min-h-[30%]">
          <div className="flex flex-grow h-full w-full bg-yellow-500">
            <ItemList />
          </div>
        </ResizablePanel>
        <ResizableHandle className="min-h-2" />
        <ResizablePanel defaultSize={30} className="min-h-[25%] max-h-[70%]">
          <div className="flex h-full items-center justify-center bg-red-500">
            <span className="font-semibold">Summary</span>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export { Page as PageMobile };
