import React from "react";

import { HalfProductTree, useStateContext } from "./State";
import { Button } from "Shadcn/Button";
import { useNavigate } from "react-router";
import { pathing } from "pages/staff/pathing";
import { useDeviceContext } from "DeviceContext";

const DetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceContext();
  const { backRef, searchValue, halfProductTree } = useStateContext();

  const renderHalfProducts = (
    halfProducts: HalfProductTree["halfProducts"]
  ) => {
    return halfProducts?.map((hp) => (
      <div key={hp.id}>
        <span className="text-lg font-bold">{hp.name}</span>
        {`, ${hp.weight}g`}
        <br />
        {hp.ingredients && hp.ingredients.length > 0 ? (
          <span className="font-medium text-bgray-100">składniki:</span>
        ) : null}
        {hp.ingredients.map((ingredient) => (
          <div className="ml-4 text-bgray-100">
            {ingredient.name}, {ingredient.weight}g
          </div>
        ))}
        {hp.halfProducts.length > 0 ? (
          <span className="font-medium text-bgray-100">pólprodukty:</span>
        ) : null}
        {hp.halfProducts && hp.halfProducts.length > 0 && (
          <div className="ml-4">{renderHalfProducts(hp.halfProducts)}</div>
        )}
      </div>
    ));
  };

  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <div className="flex flex-col flex-grow h-full w-full justify-between">
        <div className="text-white py-4 overflow-auto px-5">
          <div className="max-w-xl mx-auto">
            <div className="text-xl font-bold pb-1">
              {halfProductTree?.name}
            </div>

            {halfProductTree?.ingredients &&
            halfProductTree.ingredients.length > 0 ? (
              <span className="font-medium text-bgray-100">składniki:</span>
            ) : null}
            {halfProductTree?.ingredients?.map((ingredient) => (
              <div key={ingredient.id} className="ml-4 text-bgray-100">
                {ingredient.name}, {ingredient.weight}g
              </div>
            ))}
            {halfProductTree?.halfProducts &&
            halfProductTree.halfProducts.length > 0 ? (
              <span className="font-medium text-bgray-100">pólprodukty:</span>
            ) : null}
            <div className="ml-4">
              {halfProductTree?.halfProducts &&
                renderHalfProducts(halfProductTree.halfProducts)}
            </div>
          </div>
        </div>
        <div
          className={
            "flex w-full pr-[var(--scrollbar-width)]" +
            (isMobile ? " bg-bgray-600" : "")
          }
        >
          <div className="flex w-full max-w-xl mx-auto py-2 px-5 bg-bgray-600">
            <Button
              className="hover:bg-black"
              onClick={() =>
                backRef
                  ? navigate(backRef)
                  : navigate(pathing.BrowseHalfProducts as string, {
                      state: { searchValue: searchValue },
                    })
              }
            >
              Wróć
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DetailsPage as Page };
