import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva } from "class-variance-authority";
import { useMemo, useRef, useState } from "react";
import { Category, useStateContext } from "./State";
import { useGqlContext } from "GqlContext";
import {
  RenameCategoryDocument,
  RenameCategoryMutation,
  RenameCategoryMutationVariables,
} from "type";

const Edit: React.FC<{ category: Category }> = ({
  category: { id, name: initialName },
}) => {
  const { queryClient, graphQLClient } = useGqlContext();
  const { categories, setCategories } = useStateContext();

  const otherCategories = useMemo(() => {
    return categories.filter((category) => category.id !== id);
  }, [categories]);

  const [name, setName] = useState<string>(initialName);
  const [isCheckDisabled, setIsCheckDisabled] = useState<boolean>(true);
  const [isNameTaken, setIsNameTaken] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const checkVariants = cva(
    "absolute right-0 h-full w-8 hover:bg-black hover:border hidden",
    {
      variants: {
        default: {
          disabled: "disabled:hidden",
          focus:
            "group-focus-within:block transition-all duration-50 disabled:hidden", // grabed
        },
      },
    }
  );

  const onClick = () => {
    if (inputRef) {
      const input = inputRef.current;
      if (input) {
        const _name = input.value.trim();

        queryClient
          .fetchQuery({
            queryKey: ["renameCategory"],
            queryFn: async () =>
              graphQLClient.request<
                RenameCategoryMutation,
                RenameCategoryMutationVariables
              >({
                document: RenameCategoryDocument,
                variables: {
                  input: {
                    id: id,
                    name: _name,
                  },
                },
              }),
            staleTime: 0,
          })
          .then((result) => {
            if (result.category.rename.errors.length === 0) {
              setName(_name);
              setCategories((categories) =>
                categories.map((category) =>
                  category.id === id
                    ? { ...result.category.rename.category! }
                    : category
                )
              );
            }
          })
          .finally(() => {
            input.blur();
            input.value = _name;
          });
      }
    }
  };

  return (
    <div className="group focus-within relative flex w-full items-center">
      <input
        ref={inputRef}
        className="w-full py-1 pl-2 border font-semibold border-bgray-400 bg-inherit hover:bg-inherit focus:outline-none focus:border-bgray-100"
        defaultValue={name}
        onChange={(event) => {
          const _name = event.target.value.trim();
          const nameIsTaken = otherCategories.some(
            (category) => category.name === _name
          );
          setIsCheckDisabled(_name === name || nameIsTaken || _name === "");
          setIsNameTaken(nameIsTaken);
        }}
        onBlur={() => {
          if (inputRef?.current) {
            inputRef.current.value = name;
            setIsNameTaken(false);
            setIsCheckDisabled(true);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !isCheckDisabled) {
            event.preventDefault();
            onClick();
          }
        }}
      />
      <button
        disabled={isCheckDisabled}
        className={checkVariants({
          default: isCheckDisabled ? "disabled" : "focus",
        })}
        onMouseDown={(event) => event.preventDefault()}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faCheck} />
      </button>
      {isNameTaken ? (
        <div className="absolute right-0 pr-2 font-semibold text-red-400">
          Nazwa w użyciu!
        </div>
      ) : null}
    </div>
  );
};

export { Edit };
