import { WeekPicker } from "../components/WeekPicker";

const Header: React.FC = () => {
  return (
    <div className="flex h-full items-center justify-between px-4 text-white bg-bgray-500 ">
      <WeekPicker />
    </div>
  );
};

export { Header };
