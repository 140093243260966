import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  Customer,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  StaffMember,
  WhoAmIDocument,
  WhoAmIQuery,
} from "./type";

import { useGqlContext } from "./GqlContext";

export type AuthData = {
  userType: Customer | StaffMember | null;
  isAuthenticated: boolean;
};

interface AuthContext {
  authData: AuthData;
  login: (password: string) => Promise<AuthData>;
  logout: () => void;
  validateAuthentication: () => Promise<void>;
  user: Customer | StaffMember | undefined;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { queryClient, graphQLClient } = useGqlContext();

  const LOCAL_STORAGE_AUTH = ".auth";
  const UNAUTHENTICATED: AuthData = {
    userType: null,
    isAuthenticated: false,
  };

  const [authData, setAuthData] = useState<AuthData>(UNAUTHENTICATED);
  const [user, setUser] = useState<Customer | StaffMember | undefined>(
    undefined
  );

  const login = async (password: string): Promise<AuthData> => {
    try {
      const payload = (
        await queryClient.fetchQuery({
          queryKey: ["login"],
          queryFn: async () =>
            graphQLClient.request<LoginMutation, LoginMutationVariables>({
              document: LoginDocument,
              variables: { password: password },
            }),
          staleTime: 0,
        })
      ).login;
      let authData: AuthData;
      if (payload.errors.length > 0) {
        authData = UNAUTHENTICATED;
      } else if (payload.user) {
        switch (payload.user.__typename) {
          case "StaffMember":
            authData = {
              userType: {
                __typename: payload.user.__typename,
                id: "00000000-0000-0000-0000-000000000001",
                role: payload.user.role,
              },
              isAuthenticated: true,
            };
            break;
          case "Customer":
            authData = {
              userType: {
                __typename: payload.user.__typename,
                id: "00000000-0000-0000-0000-000000000002",
              },
              isAuthenticated: true,
            };
            break;
          default:
            throw new Error("Unexpected authentication error.");
        }
        localStorage.setItem(LOCAL_STORAGE_AUTH, JSON.stringify(authData));
        setUser({ ...payload.user, id: authData.userType!.id });
      } else {
        console.error(payload);
        throw new Error("Unexpected authentication error.");
      }
      setAuthData(authData);
      return authData;
    } catch (error) {
      console.error("Login failed", error);
      return UNAUTHENTICATED;
    }
  };

  const logout = () => {
    queryClient
      .fetchQuery({
        queryKey: ["logout"],
        queryFn: async () =>
          graphQLClient.request<LogoutMutation>({
            document: LogoutDocument,
          }),
      })
      .then((result) => {
        setUser(undefined);
        setAuthData((prev) => ({
          ...prev,
          ...UNAUTHENTICATED,
        }));
        if (result.logout) {
          localStorage.removeItem(LOCAL_STORAGE_AUTH);
        }
      });
  };

  const validateAuthentication = async () => {
    const unauth = () => {
      setUser(undefined);
      localStorage.removeItem(LOCAL_STORAGE_AUTH);
      setAuthData(UNAUTHENTICATED);
    };

    let authDataRaw = localStorage.getItem(LOCAL_STORAGE_AUTH);
    if (!authDataRaw) {
      unauth();
      return;
    }

    let authData: AuthData;
    try {
      authData = JSON.parse(authDataRaw);
    } catch {
      unauth();
      return;
    }
    if (authData.isAuthenticated) {
      try {
        const { whoAmI: user } = await queryClient.fetchQuery({
          queryKey: ["whoAmI"],
          queryFn: async () =>
            graphQLClient.request<WhoAmIQuery>({
              document: WhoAmIDocument,
            }),
          staleTime: 0,
        });
        switch (user.__typename) {
          case "StaffMember":
            let id = "00000000-0000-0000-0000-000000000001";
            setUser({ ...user, id: id });
            authData = {
              userType: {
                __typename: user.__typename,
                id: id,
                role: user.role,
              },
              isAuthenticated: true,
            };
            setAuthData({ ...authData });
            break;
          case "Customer":
            id = "00000000-0000-0000-0000-000000000002";
            setUser({ ...user, id: id });
            authData = {
              userType: {
                __typename: user.__typename,
                id: id,
              },
              isAuthenticated: true,
            };
            setAuthData({ ...authData });
            break;
          default:
            throw new Error("Unexpected authentication error.");
        }
        return;
      } catch (ex: any) {
        if (ex && ex.response) {
          const response = ex.response;
          if (response.status && response.status === 200) {
            if (response.errors && response.errors.length === 1) {
              const error = response.errors[0];
              if (
                error.extensions &&
                error.extensions.code === "AUTH_NOT_AUTHORIZED"
              ) {
                unauth();
                return;
              }
            }
          }
        }
      }
    }
    unauth();
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        login,
        logout,
        validateAuthentication,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
