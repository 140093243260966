import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cva } from "class-variance-authority";
import { GripVertical } from "lucide-react";

// import { Badge } from "./ui/badge";
// import { Card, CardContent, CardHeader } from "./ui/card";
// import { Button } from "./ui/button";

import { Badge } from "Shadcn/Badge";
import { Card, CardContent, CardHeader } from "Shadcn/Card";
import { Button } from "Shadcn/Button";
import { DragProduct } from "../State";

interface ProductCardProps {
  product: DragProduct;
  isOverlay?: boolean;
}

export type ProductType = "Product";

export interface ProductDragData {
  type: ProductType;
  product: DragProduct;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, isOverlay }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: product.id,
    data: {
      type: "Product",
      product: product,
    } satisfies ProductDragData,
    attributes: {
      roleDescription: "Product",
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva("flex items-center border border-white px-1", {
    variants: {
      dragging: {
        // default: "border-2 border-transparent",
        over: "ring-2 opacity-30",
        overlay: "ring-2 ring-primary",
      },
    },
  });

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <Button
        variant={"ghost"}
        {...attributes}
        {...listeners}
        className="p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab"
      >
        {/* <span className="sr-only">Move task</span> */}
        <GripVertical />
      </Button>
      <div>{product.content}</div>
    </div>
  );

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <CardHeader className="px-3 py-3 space-between flex flex-row border-b-2 border-secondary relative">
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className="p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab"
        >
          <span className="sr-only">Move task</span>
          <GripVertical />
        </Button>
        <Badge variant={"outline"} className="ml-auto font-semibold">
          Task
        </Badge>
      </CardHeader>
      <CardContent className="px-3 pt-3 pb-6 text-left whitespace-pre-wrap">
        {product.content}
      </CardContent>
    </Card>
  );
};

export { ProductCard };
