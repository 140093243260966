"use client";

import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";

import { toLocaleISOString } from "utils/toLocaleISOString";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { useStateContext } from "../State";

import { MainProductSelect } from "./MainProductSelect";
import { SubProductsSelect } from "./SubProductsSelect";
import { Summary } from "./Summary";
import { DatePicker } from "./DatePicker";
import { useEffect, useState } from "react";
import { ActionData } from "../route";

const CreateForm: React.FC = () => {
  const navigate = useNavigate();
  const submit = useSubmit();

  const actionData = useActionData() as ActionData;

  const { form } = useStateContext();

  type Status = "IDLE" | "IN_PROGRESS" | "FINISHED" | "FAILURE";
  const [status, setStatus] = useState<Status>("IDLE");

  useEffect(() => {
    if (actionData) {
      form.reset();
      if (actionData.data) {
        setStatus("FINISHED");
        if (actionData.data.deliveryDate) {
          const dateSnapshot = new Date(actionData.data.deliveryDate);
          form.setValue("deliveryDate", dateSnapshot);
        }
      } else {
        setStatus("FAILURE");
      }
    }
  }, [actionData]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setStatus("IN_PROGRESS");
    submit(
      {
        ...values,
        deliveryDate: toLocaleISOString(values.deliveryDate ?? new Date()),
      }, // fix
      { method: "post", encType: "application/json" }
    );
  };

  const onChange =
    (
      field: ControllerRenderProps<z.infer<typeof formSchema>, "totalQuantity">
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      value = event.target.value.replace(/[^0-9]/g, "");

      field.onChange(parseFloat(value));
    };

  const renderStatus = () => {
    switch (status) {
      case "IDLE":
        return null;
      case "IN_PROGRESS":
        return <span className="text-yellow-400">Tworzenie zamówienia...</span>;
      case "FINISHED":
        return <span className="text-bgreen-100">Ukończono!</span>;
      case "FAILURE":
        return <span className="text-bgreen-100">Błąd.</span>;
      default:
        return null;
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onFocus={() => setStatus("IDLE")}
        className="space-y-2"
      >
        <FormField
          control={form.control}
          name="mainProduct"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Wybierz produkt główny</FormLabel>
              <FormControl>
                <MainProductSelect zMainProduct={field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="subProducts"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Wybierz produkty dodatkowe</FormLabel>
              <FormControl>
                <SubProductsSelect zSubProducts={field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormItem>
          <FormLabel>Podsumowanie</FormLabel>
          <Summary />
        </FormItem>
        <FormField
          control={form.control}
          name="totalQuantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Całkowita liczba dań</FormLabel>
              <FormControl>
                <Input
                  className="bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black "
                  type="text"
                  placeholder="Podaj całkowitą liczbę dań..."
                  maxLength={15} // More than that causes science notation issues.
                  onChange={onChange(field)}
                  value={!Number.isNaN(field.value) ? field.value : ""}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="deliveryDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Wybierz datę dostarczenia</FormLabel>
              <FormControl>
                <DatePicker zDeliveryDate={field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between pt-2 pb-5">
          <Button
            type="button"
            onClick={() => navigate(pathing.BrowseOrders as string)}
            className="hover:bg-black"
          >
            Wróć
          </Button>
          <div className="flex items-center text-lg text-bgray-100">
            {renderStatus()}
          </div>
          <Button
            type="submit"
            className="text-white bg-bgreen-100 hover:bg-bgreen-300"
          >
            Zamów
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { CreateForm as Form };
