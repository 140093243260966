import { Link } from "react-router-dom";

import { pathing } from "pages/staff/pathing";

const CreateButton: React.FC = () => {
  return (
    <Link
      className="flex items-center  pl-2 pr-3 px-4 rounded-lg bg-bgreen-100 text-white font-semibold"
      to={pathing.CreateOrder as string}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
      </svg>
      Złóż
    </Link>
  );
};

export { CreateButton };
