import { Link, useNavigate } from "react-router-dom";

import { Button } from "Shadcn/Button";

import { useAuthContext } from "AuthContext";

import { pathing } from "pages/staff/pathing";
import { Admin } from "Admin";
import { Chef } from "Chef";

const CompanyCard: React.FC = () => {
  return (
    <Link to="/" className="flex items-center gap-2 text-lg font-semibold">
      <img src="./LogoWhite.png" className="w-12 h-12"></img>
      <span>Nasze Bistro</span>
    </Link>
  );
};

const MenuItem: React.FC<{ content: string; href: string }> = ({
  content,
  href,
}) => {
  return (
    <Link
      to={href}
      className="flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-bgreen-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus:bg-gray-800 dark:focus:text-gray-50"
    >
      <InboxIcon className="h-5 w-5" />
      {content}
    </Link>
  );
};

const AdminCard: React.FC = () => {
  return (
    <div className="flex items-center gap-2">
      <img
        src="./LogoWhite.png"
        width="32"
        height="32"
        className="rounded-full"
        alt="Avatar"
      />
      <div className="flex flex-col">
        <p className="text-sm font-medium">Piotr </p>
        <p className="text-xs text-gray-200"> kontakt@nasze-bistro.pl</p>
      </div>
    </div>
  );
};

const ChefCard: React.FC = () => {
  return (
    <div className="flex items-center gap-2">
      <img
        src="./chef.png"
        width="32"
        height="32"
        className="rounded-full"
        alt="Avatar"
      />
      <div className="flex flex-col">
        <p className="text-lg font-medium">Szef kuchni</p>
      </div>
    </div>
  );
};

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuthContext();

  return (
    <div className="flex z-40 min-w-[280px] h-full flex-col justify-between p-4 bg-bgray-600 text-white drop-shadow-sideMenu">
      <div className="flex flex-col gap-4">
        <CompanyCard />
        <Admin>
          <MenuItem
            content="Składniki"
            href={pathing.BrowseIngredients as string}
          />
        </Admin>
        <MenuItem
          content="Półprodukty"
          href={pathing.BrowseHalfProducts as string}
        />
        <Admin>
          <MenuItem content="Kategorie" href={pathing.Categories as string} />
        </Admin>
        <MenuItem content="Produkty" href={pathing.BrowseProducts as string} />
        {/* <MenuItem content="Menu" href={pathing.Menu as string} /> */}
        <Admin>
          <MenuItem
            content="Zamówienia"
            href={pathing.BrowseOrders as string}
          />
        </Admin>
        <MenuItem
          content="Produkcja"
          href={pathing.BrowseProduction as string}
        />
        {/* <MenuItem content="PDF" href={pathing.PDF as string} />
        <MenuItem content="JPG" href={pathing.JPG as string} /> */}
      </div>
      <div className="flex flex-col gap-4">
        <Button
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          Wyloguj się
        </Button>
        <Admin>
          <AdminCard />
        </Admin>
        <Chef>
          <ChefCard />
        </Chef>
      </div>
    </div>
  );
};

function InboxIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
      <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
    </svg>
  );
}

function MountainIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
    </svg>
  );
}

export { SideMenu };
