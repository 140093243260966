import React, { useState } from "react";

import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ProductionItem } from "../State";
import { PDF } from "./PDF";
import { faL, faPrint } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";
import { toLocaleISOString } from "utils/toLocaleISOString";

const PDFDownload: React.FC<{ productionItem: ProductionItem }> = ({
  productionItem,
}) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const downloadImage = async (element: HTMLDivElement) => {
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }"
    );

    const canvas = await html2canvas(element, { scale: 2, logging: false });
    style.remove();

    const dataUrl = canvas.toDataURL("image/png");

    const docDefinition: TDocumentDefinitions = {
      pageSize: {
        width: canvas.width,
        height: canvas.height,
      },
      content: [
        {
          image: dataUrl,
          width: canvas.width,
          height: canvas.height,
          absolutePosition: { x: 0, y: 0 },
        },
      ],
    };

    pdfMake
      .createPdf(docDefinition)
      .download(
        `${productionItem.dishId}_${toLocaleISOString(
          new Date(productionItem.deliveryDate)
        )
          .split("-")
          .reverse()
          .join("-")}.pdf`
      );
  };

  return (
    <>
      <button
        className="flex text-white hover:text-[#f40f02]"
        onClick={() => setShouldRender(true)}
      >
        <FontAwesomeIcon className="h-6 w-6" icon={faPrint} />
      </button>
      {shouldRender ? (
        <PDF
          templateUrl="./crop_no_resize_blank.jpg"
          productionItem={productionItem}
          setShouldRender={setShouldRender}
          onRenderComplete={downloadImage}
        />
      ) : null}
    </>
  );
};

export { PDFDownload };
