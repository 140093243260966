import { createBrowserRouter, RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { getRootRoute } from "./root/route";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";
import { Role } from "type";

const getRouter = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient,
  role: Role
) => {
  const rootRoute = getRootRoute(queryClient, graphQLClient);
  rootRoute.children = rootRoute.children
    ?.filter((child) => {
      if (!child.roles) return true;
      return Array.isArray(child.roles)
        ? child.roles.includes(role)
        : child.roles === role;
    })
    .map((child) => {
      const { roles, ...rest } = child;
      return { ...rest };
    });
  return createBrowserRouter([rootRoute as RouteObject], {
    future: { v7_relativeSplatPath: true },
  });
};

export { getRouter as getStaffRouter };
