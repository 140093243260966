// Dont like it but its simplest while sufficient solution i think.

export type Pathing = {
  [key: string]: string | Pathing;
};

export const pathing: Pathing = {
  Error: "/error",
  Home: "/",
  BrowseIngredients: "/Skladniki",
  AddIngredient: "/Skladniki/Dodaj",
  EditIngredient: "/Skladniki/:ingredientId/Edytuj",
  BrowseHalfProducts: "/Polprodukty",
  AddHalfProduct: "/Polprodukty/Dodaj",
  EditHalfProduct: "/Polprodukty/:halfProductId/Edytuj",
  HalfProductDetails: "/Polprodukty/:halfProductId/Szczegoly",
  Categories: "/Kategorie",
  BrowseProducts: "/Produkty",
  AddProduct: "/Produkty/Dodaj",
  EditProduct: "/Produkty/:productId/Edytuj",
  ProductDetails: "/Produkty/:productId/Szczegoly",
  Menu: "/Menu",
  BrowseOrders: "/Zamowienia",
  CreateOrder: "/Zamowienia/Zloz",
  BrowseProduction: "/Produkcja",
  PDF: "/pdf",
  JPG: "/jpg",
};
