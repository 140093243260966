import React, { createContext, useContext, ReactNode, useState } from "react";

import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { GetProductionDataQuery } from "type";

export type ProductionItem = GetProductionDataQuery["production"][0];
export type Product = ProductionItem["mainProduct"];

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  productionItems: ProductionItem[];
  setProductionItems: React.Dispatch<React.SetStateAction<ProductionItem[]>>;
  productionDate: Date | undefined;
  setProductionDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  labelBytes: Uint8Array;
  setLabelBytes: React.Dispatch<React.SetStateAction<Uint8Array>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productionItems, setProductionItems] = useState<ProductionItem[]>([]);
  const [labelBytes, setLabelBytes] = useState<Uint8Array>(new Uint8Array());

  const [productionDate, setProductionDate] = useState<Date | undefined>(
    new Date()
  );

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        isLoading,
        setIsLoading,
        productionItems,
        setProductionItems,
        productionDate,
        setProductionDate,
        labelBytes,
        setLabelBytes,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
