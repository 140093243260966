import { Days } from "../components/Days";
import { Header } from "./Header";

const Container: React.FC = () => {
  return (
    <div className="relative min-h-0 max-h-full grow overflow-auto overscroll-contain outline-none">
      <div className="absolute z-10 inset-x-0 h-24">
        <Header />
      </div>
      <div className="absolute z-0 inset-x-0 pt-24 h-full bg-bgray-400">
        <Days />
      </div>
    </div>
  );
};

export { Container };
