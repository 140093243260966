import {
  faCircleInfo,
  faCopy,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pathing } from "pages/staff/pathing";
import { useNavigate, useSubmit } from "react-router-dom";

import { HalfProduct, useStateContext } from "../State";
import { Admin } from "Admin";
import { Require } from "Require";
import { useRef } from "react";

const Item: React.FC<{
  halfProduct: HalfProduct;
}> = ({ halfProduct }) => {
  const submit = useSubmit();
  const navigate = useNavigate();
  const { searchValue } = useStateContext();

  const f = (number: number, decimalPoint: number): string => {
    return number.toFixed(decimalPoint).toString().replace(".", ",");
  };

  const liRef = useRef<HTMLLIElement | null>(null);

  const deleteHalfProduct = async () => {
    if (liRef?.current) {
      liRef.current.style.opacity = "0.2";
      liRef.current.style.transition = "opacity 0.4s";
    }

    requestAnimationFrame(() => {
      submit(
        { id: halfProduct.id },
        {
          method: "delete",
          encType: "application/json",
        }
      );
    });
  };

  return (
    <li
      ref={liRef}
      className="flex items-center gap-4 p-4 bg-bgray-400 hover:bg-bgray-500"
    >
      <div className="flex-1">
        <h4 className="font-semibold text-white">{halfProduct.name}</h4>
        {halfProduct.ingredientNames.length > 0 ? (
          <div className="text-sm py-1 text-white">
            {halfProduct.ingredientNames.join(", ")}
          </div>
        ) : null}
        <p className="text-sm text-gray-200">
          wartość energetyczna: {f(halfProduct.kilocalories, 0)} kcal, tłuszcze:{" "}
          {f(halfProduct.fat, 1)}g , tłuszcze nasycone:{" "}
          {f(halfProduct.saturatedFat, 1)}g, węglowodany:{" "}
          {f(halfProduct.carbohydrates, 1)}, cukry: {f(halfProduct.sugars, 1)},
          błonnik: {f(halfProduct.fiber, 1)}
          g, białko: {f(halfProduct.protein, 1)}g, sól: {f(halfProduct.salt, 2)}
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <Admin>
          <button
            className="text-bred hover:text-red-800"
            onClick={deleteHalfProduct}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            className="text-bgreen-100 hover:text-bgreen-300"
            onClick={() =>
              navigate(
                (pathing.EditHalfProduct as string).replace(
                  ":halfProductId",
                  halfProduct.id
                ),
                { state: { searchValue: searchValue } }
              )
            }
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button
            className="flex text-white hover:text-bgray-100 p-1"
            onClick={() =>
              navigate(pathing.AddHalfProduct as string, {
                state: {
                  searchValue: searchValue,
                  fromBrowse: halfProduct,
                  fromEdit: null,
                },
              })
            }
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </Admin>
        <Require Admin Chef>
          <button
            className="flex text-white hover:text-bgray-100 p-1"
            onClick={() =>
              navigate(
                (pathing.HalfProductDetails as string).replace(
                  ":halfProductId",
                  halfProduct.id
                ),
                { state: { searchValue: searchValue } }
              )
            }
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </button>
        </Require>
      </div>
    </li>
  );
};

export { Item };
