import { useLoaderData } from "react-router";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { Toggle } from "Shadcn/Toggle";

import { formSchema } from "../formSchema";
import { LoaderData } from "../route";

const CategoryPanel: React.FC<{
  zodCategoryId: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "categoryId"
  >;
}> = ({ zodCategoryId }) => {
  const { categories } = useLoaderData() as LoaderData;

  return (
    <div>
      {categories.map((category) => (
        <Toggle
          className=" border-bgray-300 border text-white mr-2 mb-2 data-[state=on]:bg-black data-[state=on]:text-white data-[state=on]:border-1 data-[state=on]:border-black hover:bg-black hover:border-black hover:text-white"
          key={category.id}
          pressed={zodCategoryId.value === category.id}
          onPressedChange={(isPressed: boolean) =>
            zodCategoryId.onChange(isPressed ? category.id : null)
          }
        >
          {category.name}
        </Toggle>
      ))}
    </div>
  );
};

export { CategoryPanel };
