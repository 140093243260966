import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { z } from "zod";

import {
  CreateProductMutation,
  CreateProductMutationVariables,
  CreateProductDocument,
  GetProductTagsAndCategoriesQuery,
  GetProductTagsAndCategoriesDocument,
} from "type";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./State";
import { formSchema } from "./formSchema";
import { Form } from "./components/Form";

export type ErrorTypeName =
  | CreateProductMutation["product"]["create"]["errors"][0]["__typename"]
  | "UnhandledError";

export type ActionData = {
  id: string | null;
  errors: {
    __typename: ErrorTypeName;
    message: string;
  }[];
};

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) => async () => {
    return await queryClient.fetchQuery({
      queryKey: ["productTags"],
      queryFn: async () =>
        graphQLClient.request<GetProductTagsAndCategoriesQuery>({
          document: GetProductTagsAndCategoriesDocument,
        }),
      staleTime: 0,
    });
  };

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }): Promise<ActionData> => {
    const formData = (await request.json()) as z.infer<typeof formSchema>;
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["createProduct"],
        queryFn: async () =>
          graphQLClient.request<
            CreateProductMutation,
            CreateProductMutationVariables
          >({
            document: CreateProductDocument,
            variables: {
              input: {
                name: formData.name,
                price: formData.price,
                image: formData.image,
                ingredients:
                  formData.ingredients.length === 0
                    ? null
                    : formData.ingredients.map((ingredient) => ({
                        id: ingredient.id,
                        weight: ingredient.weight,
                      })),
                halfProducts:
                  formData.halfProducts.length === 0
                    ? null
                    : formData.halfProducts.map((halfProduct) => ({
                        id: halfProduct.id,
                        weight: halfProduct.weight,
                      })),
                tags: formData.tags.length === 0 ? null : formData.tags,
                categoryId: formData.categoryId,
              },
            },
          }),
        staleTime: 0,
      })
    ).product.create;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "ProductNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
        return {
          id: null,
          errors: [
            {
              __typename: "UnhandledError",
              message: "Unhandled Error", // Probably pass some stuff?
            },
          ],
        };
      }
      return { id: null, errors: [error] };
    }
    return { id: result.product!.id, errors: [] };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.AddProduct as string,
    loader: getLoader(queryClient, graphQLClient),
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Form />
      </StateProvider>
    ),
  };
};

export { getRoute as getAddProductRoute };
