import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { pathing } from "pages/staff/pathing";

import { Page } from "./Page";
import { StateProvider } from "./State";

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.BrowseProduction as string,
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Page />
      </StateProvider>
    ),
  };
};

export { getRoute as getBrowseProductionRoute };
