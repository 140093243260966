import {
  AddMenuItemDocument,
  AddMenuItemMutation,
  AddMenuItemMutationVariables,
} from "type";

import { useStateContext } from "../state/StateProvider";
import { Day } from "../state/types";

const AddItemButton: React.FC<{ day: Day }> = ({ day }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();

  const addItem = () => {
    queryClient
      .fetchQuery({
        queryKey: ["addDailyMenu"],
        queryFn: async () =>
          graphQLClient.request<
            AddMenuItemMutation,
            AddMenuItemMutationVariables
          >({
            document: AddMenuItemDocument,
            variables: {
              dailyMenuId: day.id,
            },
          }),
        staleTime: 0,
      })
      .then(
        ({
          menu: {
            daily: {
              item: { add: id },
            },
          },
        }) => {
          const dayOfWeek = week[day.key];
          const newItem = {
            id: id,
            dayKey: day.key,
            quantity: null,
            mainProduct: null,
            subProductLists: [],
          };
          setWeek({
            ...week,
            [day.key]: {
              ...dayOfWeek,
              items: [...dayOfWeek.items, newItem],
            },
          });
        }
      );
  };

  return (
    <button
      className="items-center justify-center flex h-fit w-[52] mx-auto my-2 gap-1 py-2 pr-6 pl-4 rounded-lg text-white bg-bgreen-100 hover:bg-bgreen-300"
      // className="items-center justify-center flex h-fit gap-1 mt-2 p-2 bg-bgreen-100 hover:bg-bgreen-300"
      onClick={addItem}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
      </svg>
      Dodaj danie
    </button>
  );
};

export { AddItemButton };
