import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  useSensor,
  useSensors,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import { coordinateGetter } from "../../../dndCooridnateGetter";
import { useState } from "react";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { CategoryFC, DragData } from "./Category";
import { Category, useStateContext } from "./State";
import { ScrollArea, ScrollBar } from "Shadcn/ScrollArea";
import { useGqlContext } from "GqlContext";
import {
  CategoryPlaceAfterDocument,
  CategoryPlaceAfterMutation,
  CategoryPlaceAfterMutationVariables,
} from "type";

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ScrollArea className="flex flex-grow">
      <div className="flex flex-grow h-full flex-col">{children}</div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

const List: React.FC = () => {
  const { queryClient, graphQLClient } = useGqlContext();
  const { categories, setCategories } = useStateContext();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    })
  );
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);

  function onDragStart(event: DragStartEvent) {
    if (isPending) return;
    const data = event.active.data.current as DragData;
    setActiveCategory(data.category);
  }

  function onDragEnd(event: DragEndEvent) {
    if (isPending) return;
    setIsPending(true);
    setActiveCategory(null);
    const { active, over } = event;
    if (!over || over.id === active.id) return;

    setCategories((categories) => {
      const activeCategoryindex = categories.findIndex(
        (category) => category.id === active.id
      );
      const overCategoryIndex = categories.findIndex(
        (category) => category.id === over.id
      );
      return arrayMove(categories, activeCategoryindex, overCategoryIndex);
    });

    const activeIndex = categories.findIndex(
      (category) => category.id === active.id
    );
    const overIndex = categories.findIndex(
      (category) => category.id === over.id
    );

    const afterId =
      activeIndex < overIndex ? over.id : categories[overIndex - 1]?.id || null;

    queryClient
      .fetchQuery({
        queryKey: ["reorderCategories"],
        queryFn: async () =>
          graphQLClient.request<
            CategoryPlaceAfterMutation,
            CategoryPlaceAfterMutationVariables
          >({
            document: CategoryPlaceAfterDocument,
            variables: {
              input: {
                id: active.id,
                after: afterId,
              },
            },
          }),
        staleTime: 0,
      })
      .then(() => setIsPending(false));
  }

  function onDragOver(event: DragOverEvent) {
    // nothing to do here?
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <Container>
        <SortableContext items={categories}>
          {categories.map((category) => (
            <CategoryFC
              key={category.id}
              category={category}
              disabled={isPending}
            />
          ))}
        </SortableContext>
      </Container>

      {"document" in window &&
        createPortal(
          <DragOverlay>
            {activeCategory && (
              <CategoryFC
                isOverlay
                category={activeCategory}
                disabled={isPending}
              />
            )}
          </DragOverlay>,
          document.body
        )}
    </DndContext>
  );
};

export { List };
