import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { useDndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useMemo } from "react";
// import { Task, TaskCard } from "./TaskCard";
import { cva } from "class-variance-authority";
import { GripVertical } from "lucide-react";

import { Card, CardContent, CardHeader } from "Shadcn/Card";
import { Button } from "Shadcn/Button";
import { ScrollArea, ScrollBar } from "Shadcn/ScrollArea";
import { ProductCard } from "./Product";
import { DragProduct } from "../State";
import { Row } from "../State";

export type RowType = "Row";

export interface ColumnDragData {
  type: RowType;
  row: Row;
}

interface BoardRowProps {
  row: Row;
  //   tasks: Task[];
  products: DragProduct[];
  isOverlay?: boolean;
}

const BoardRow: React.FC<BoardRowProps> = ({ row, products, isOverlay }) => {
  const productIds = useMemo(() => {
    return products.map((product) => product.id);
  }, [products]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // id: column.id,
    id: row.id,
    data: {
      //   type: "Column",
      type: "Row",
      //   column,
      row,
    } satisfies ColumnDragData,
    attributes: {
      //   roleDescription: `Column: ${column.title}`,
      roleDescription: `Row: ${row.id}`,
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva(
    // "h-[150px] max-h-[500px] w-[350px] max-w-full bg-primary-foreground flex flex-col flex-shrink-0 snap-center",
    "flex flex-row w-full items-center border border-black",
    {
      variants: {
        dragging: {
          // default: "border-2 border-transparent",
          over: "ring-2 opacity-30",
          overlay: "ring-2 ring-primary",
        },
      },
    }
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      // className="flex flex-row w-full items-center border border-black"

      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <Button
        variant={"ghost"}
        {...attributes}
        {...listeners}
        // className=" p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
        className="p-0.5 cursor-grab"
      >
        {/* <span className="sr-only">{`Move column: ${column.title}`}</span> */}
        <GripVertical />
      </Button>
      <div className="flex w-full gap-1">
        <SortableContext items={productIds}>
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </SortableContext>
      </div>
    </div>
  );

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <CardHeader className="p-4 font-semibold border-b-2 text-left flex flex-row space-between items-center">
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className=" p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
        >
          {/* <span className="sr-only">{`Move column: ${column.title}`}</span> */}
          <span className="sr-only">{`Move column: ${row.id}`}</span>
          <GripVertical />
        </Button>
        {/* <span className="ml-auto"> {column.title}</span> */}
        <span className="ml-auto"> {row.id}</span>
      </CardHeader>
      <ScrollArea>
        <CardContent className="flex flex-grow flex-col gap-2 p-2">
          <SortableContext items={productIds}>
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </SortableContext>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};

const BoardContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dndContext = useDndContext();

  const variations = cva("w-full px-2 md:px-0 flex lg:justify-center pb-4", {
    variants: {
      dragging: {
        default: "snap-x snap-mandatory",
        active: "snap-none",
      },
    },
  });

  return (
    <ScrollArea
      className={variations({
        dragging: dndContext.active ? "active" : "default",
      })}
    >
      <div className="flex w-full gap-4 items-center flex-col justify-center">
        {children}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

export { BoardRow, BoardContainer };
