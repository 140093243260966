"use client";

import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import { useEffect, useRef } from "react";

import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { useStateContext } from "../State";

import { HalfProductsSelect } from "./HalfProductsSelect";
import { IngredientsSelect } from "./IngredientsSelect";
import { Summary } from "./Summary";
import { DropZone } from "./DropZone";
import { ControllerRenderProps } from "react-hook-form";
import { TagsPanel } from "./TagsPanel";
import { ActionData } from "../route";
import { Header } from "./Header";
import { PC } from "PC";
import { useDeviceContext } from "DeviceContext";
import { CategoryPanel } from "./CategoryPanel";

const CreateForm: React.FC = () => {
  const { isMobile } = useDeviceContext();

  const actionData = useActionData() as ActionData;
  const navigate = useNavigate();
  const submit = useSubmit();

  const { form, basedOn, backRef, searchValue } = useStateContext();

  const nameInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (actionData) {
      if (actionData.errors) {
        const productNameTakenError = actionData.errors.find(
          (error) => error.__typename === "ProductNameTakenError"
        );
        if (productNameTakenError) {
          form.setError("name", {
            type: productNameTakenError.__typename,
            message: `Istnieje już produkt o nazwie: "${
              form.getValues().name
            }".`,
          });
        }
      }
      if (actionData.id !== null) {
        const state = { searchValue: searchValue };
        navigate(
          (pathing.EditProduct as string).replace(
            ":productId",
            actionData.id as string
          ),
          backRef !== null
            ? {
                state: {
                  ...state,
                  backRef: backRef,
                },
              }
            : { state: state }
        );
      }
    }
  }, [actionData]);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    submit(values, { method: "post", encType: "application/json" });
  };

  const onChange =
    (field: ControllerRenderProps<z.infer<typeof formSchema>, "price">) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      value = event.target.value.replace(/[^0-9.,]/g, "").replace(".", ",");
      // Split the value into the integer and fractional parts (if a decimal is present)
      const parts = value.split(",");
      if (parts[0]) {
        parts[0] = parts[0].replace(/^0+/, "") || "0"; // Ensures "0" if input was "0000"
        if (parts.length === 1) {
          value = parts[0];
        }
      }
      // Ensure there is only one decimal point in the value
      if (parts.length > 2) {
        value = `${parts[0]},${parts[1]}`;
      }
      // Allow only one digit after the decimal point
      if (parts.length === 2 && parts[1].length > 2) {
        value = `${parts[0]},${parts[1].slice(0, 2)}`;
      }

      field.onChange(value);
    };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col h-full w-full overflow-auto space-y-2"
      >
        <div className="flex flex-col flex-grow h-full w-full overflow-auto">
          <div className={"overflow-auto " + (isMobile ? "px-5" : "")}>
            <div className="max-w-xl mx-auto pb-5">
              <Header />
              {basedOn !== null && basedOn !== undefined ? (
                <div className="text-yellow-300">Na podstawie: "{basedOn}"</div>
              ) : null}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj nazwę..."
                        {...field}
                        ref={(input) => {
                          nameInputRef.current = input;
                        }}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Cena</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj cenę..."
                        onChange={onChange(field)}
                        value={field.value}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ingredients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Składniki</FormLabel>
                    <FormControl>
                      <IngredientsSelect zIngredients={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="halfProducts"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Półprodukty</FormLabel>
                    <FormControl>
                      <HalfProductsSelect zHalfProducts={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Podsumowanie</FormLabel>
                <Summary />
              </FormItem>
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zdjęcie</FormLabel>
                    <FormControl>
                      <DropZone zodImage={field} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="tags"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Oznaczenia</FormLabel>
                    <FormControl>
                      <TagsPanel zodTagsArray={field} {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="categoryId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Kategorie</FormLabel>
                    <FormControl>
                      <CategoryPanel zodCategoryId={field} {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div
            className={
              "relative flex w-full pr-[var(--scrollbar-width)] " +
              (isMobile ? " bg-bgray-600" : "")
            }
          >
            <div className="flex w-full max-w-xl mx-auto justify-between px-3 py-2 bg-bgray-600">
              <Button
                type="button"
                onClick={() =>
                  backRef === null || backRef === undefined
                    ? navigate(pathing.BrowseProducts as string, {
                        state: { searchValue: searchValue },
                      })
                    : navigate(
                        (pathing.EditProduct as string).replace(
                          ":productId",
                          backRef
                        ),
                        {
                          state: { searchValue: searchValue },
                        }
                      )
                }
                className="hover:bg-black"
              >
                Wróć
              </Button>
              <Button
                type="submit"
                className="text-white bg-bgreen-100 hover:bg-bgreen-300"
              >
                Dodaj
              </Button>
            </div>
            <PC>
              <div className="absolute bottom-0 right-0 h-full w-[var(--scrollbar-width)] bg-bgray-600"></div>
            </PC>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { CreateForm as Form };
