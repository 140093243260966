import { useCallback, useState } from "react";
import { useLoaderData } from "react-router";

import {
  GetProductsForMenuItemQuery,
  GetProductsForMenuItemDocument,
  GetProductsForMenuItemQueryVariables,
  SetMainProductMutation,
  SetMainProductMutationVariables,
  SetMainProductDocument,
} from "type";

import { Input } from "Shadcn/Input";
import { Popover, PopoverContent, PopoverTrigger } from "Shadcn/Popover";

import { useStateContext } from "../state/StateProvider";
import { Item, LoaderData, Product } from "../state/types";

const MainProduct: React.FC<{
  item: Item;
}> = ({ item }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const { initialProducts } = useLoaderData() as LoaderData;
  const [products, setProducts] = useState<Product[]>(initialProducts);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [inputValue, setInputValue] = useState<string>(
    item.mainProduct ? item.mainProduct.name : ""
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      return;
    }
    if (isOpen) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < products.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : products.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < products.length) {
          handleSelect(products[highlightedIndex]);
        }
      }
      return;
    }
    if (["ArrowDown", "Enter", "ArrowUp"].some((key) => key === event.key)) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  const refetch = useCallback(
    async (value: string) => {
      queryClient.cancelQueries({ queryKey: ["menuItemProducts"] }, {});

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(async () => {
        try {
          const products = (
            await queryClient.fetchQuery({
              queryKey: ["menuItemProducts"],
              queryFn: ({ signal }) => {
                return graphQLClient.request<
                  GetProductsForMenuItemQuery,
                  GetProductsForMenuItemQueryVariables
                >({
                  document: GetProductsForMenuItemDocument,
                  variables: { where: { name: { contains: value.trim() } } },
                  signal,
                });
              },
              staleTime: 0,
            })
          ).products;
          setProducts(products);
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 200);

      setTimeoutId(newTimeoutId);
    },
    [timeoutId]
  );

  const setMainProduct = async (productId: string) => {
    await queryClient.fetchQuery({
      queryKey: ["setMainProduct"],
      queryFn: async () =>
        graphQLClient.request<
          SetMainProductMutation,
          SetMainProductMutationVariables
        >({
          document: SetMainProductDocument,
          variables: {
            menuItemId: item.id,
            productId: productId,
          },
        }),
      staleTime: 0,
    });
  };

  const updateItemInWeek = async (product: Product) => {
    const dayOfWeek = week[item.dayKey];
    const itemIndex = dayOfWeek.items.findIndex(
      (_item) => _item.id === item.id
    );
    const updatedItems = [
      ...dayOfWeek.items.slice(0, itemIndex),
      { ...item, mainProduct: product },
      ...dayOfWeek.items.slice(itemIndex + 1),
    ];
    setWeek({
      ...week,
      [item.dayKey]: {
        ...dayOfWeek,
        items: updatedItems,
      },
    });
  };

  const handleSelect = (product: Product) => {
    setInputValue(product.name);
    setMainProduct(product.id);
    refetch(product.name);
    updateItemInWeek(product);
    setIsOpen(false);
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    refetch(value);
    setIsOpen(true);
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setHighlightedIndex(0)}>
      <PopoverTrigger asChild>
        <Input
          type="text"
          className="w-full rounded-none border-none h-fit px-3 py-2 mt-1 bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black"
          placeholder="Wybierz produkt główny..."
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={() => setIsOpen(false)}
          onChange={handleInputChange}
          value={inputValue}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[--radix-popover-trigger-width] rouned-none top-0 p-0"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div className="flex flex-col ">
          {products.length > 0 ? (
            products.map((product, index) => (
              <button
                key={product.id}
                className={`text-left p-1 hover:bg-gray-200 ${
                  highlightedIndex === index ? "bg-gray-200" : ""
                }`}
                onMouseEnter={() => setHighlightedIndex(index)}
                onClick={() => handleSelect(product)}
              >
                {product.name}
              </button>
            ))
          ) : (
            <div className="text-center p-1">Brak produktów</div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export { MainProduct };
