import { useGqlContext } from "GqlContext";
import { useRef, useState } from "react";
import { Input } from "Shadcn/Input";
import {
  AddCategoryDocument,
  AddCategoryMutation,
  AddCategoryMutationVariables,
} from "type";
import { useStateContext } from "./State";

const NameInput: React.FC = () => {
  const { queryClient, graphQLClient } = useGqlContext();
  const { categories, setCategories } = useStateContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isAddDisabled, setIsAddDisabled] = useState<boolean>(true);
  const [isNameTaken, setIsNameTaken] = useState<boolean>(false);

  const onClick = () => {
    if (inputRef) {
      const input = inputRef.current;
      if (input) {
        queryClient
          .fetchQuery({
            queryKey: ["addCategory"],
            queryFn: async () =>
              graphQLClient.request<
                AddCategoryMutation,
                AddCategoryMutationVariables
              >({
                document: AddCategoryDocument,
                variables: {
                  name: input.value.trim(),
                  after:
                    categories.length > 0
                      ? categories[categories.length - 1].id
                      : null,
                },
              }),
            staleTime: 0,
          })
          .then((result) =>
            setCategories((categories) => [
              ...categories,
              result.category.add.category!,
            ])
          );
      }
    }
  };

  return (
    <div className="flex p-2 gap-2 items-center bg-bgray-500">
      <div className="flex relative w-full items-center">
        <Input
          ref={inputRef}
          placeholder="podaj nazwe kategorii..."
          className="bg-bgray-100"
          onChange={(event) => {
            const name = event.target.value.trim();
            setIsAddDisabled(name === "");
            setIsNameTaken(
              categories.some((category) => category.name === name)
            );
          }}
        />
        {isNameTaken ? (
          <div className="absolute right-0 pr-2 font-semibold text-red-400">
            Nazwa w użyciu!
          </div>
        ) : null}
      </div>
      <button
        className="flex h-full items-center pl-2 pr-3 px-4 rounded-lg bg-bgreen-100 text-white font-semibold disabled:cursor-not-allowed disabled:opacity-40"
        onClick={onClick}
        disabled={isAddDisabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
        </svg>
        Dodaj
      </button>
    </div>
  );
};

export { NameInput as Input };
