import { useEffect, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { SelectSingleEventHandler } from "react-day-picker";
import { pl } from "date-fns/locale";

import { Calendar } from "Shadcn/Calendar";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "Shadcn/Dialog";

import { toLocaleISOString } from "utils/toLocaleISOString";

import { formSchema } from "../formSchema";
import { useActionData } from "react-router";
import { ActionData } from "../route";

const DatePicker: React.FC<{
  zDeliveryDate: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "deliveryDate"
  >;
}> = ({ zDeliveryDate }) => {
  const actionData = useActionData() as ActionData;
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (actionData) {
      if (actionData.data) {
        if (actionData.data.deliveryDate) {
          const dateSnapshot = new Date(actionData.data.deliveryDate);
          zDeliveryDate.onChange(dateSnapshot);
          return dateSnapshot;
        }
      }
      zDeliveryDate.onChange(undefined);
      return undefined;
    } else {
      const initialDate = new Date();
      zDeliveryDate.onChange(initialDate);
      return initialDate;
    }
  });

  useEffect(() => {
    if (actionData) {
      if (actionData.data) {
        if (actionData.data.deliveryDate) {
          const dateSnapshot = new Date(actionData.data.deliveryDate);
          setSelectedDate(dateSnapshot);
          zDeliveryDate.value = dateSnapshot;
          zDeliveryDate.onChange(dateSnapshot);
        }
      } else {
        setSelectedDate(undefined);
      }
    }
  }, [actionData]);

  const onSelect: SelectSingleEventHandler = (date: Date | undefined) => {
    setSelectedDate(date);
    zDeliveryDate.onChange(date);
  };

  const format = (date: Date) =>
    toLocaleISOString(date).split("-").reverse().join(".");

  return (
    <Dialog>
      <DialogTrigger className="flex h-10 rounded-md items-center px-3 py-2 text-sm bg-bgray-100">
        {selectedDate ? format(selectedDate) : "Wybierz datę"}
      </DialogTrigger>
      <DialogContent className="scale-125 flex flex-col gap-1 w-fit p-1 border-none text-white bg-bgray-600">
        <DialogHeader className="flex mx-auto py-2.5">
          <DialogTitle>Wybierz datę</DialogTitle>
        </DialogHeader>
        <div className="bg-bgray-400 h-[0.5px] mx-1"></div>
        <div className="flex mx-auto">
          <Calendar
            mode="single"
            locale={pl}
            selected={selectedDate}
            onSelect={onSelect}
            className="rounded-md border"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { DatePicker };
