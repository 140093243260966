import { useAuthContext } from "AuthContext";
import { ReactNode } from "react";

import { Role } from "type";

const Admin: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();

  if (user === undefined) return null;
  if (user.__typename !== "StaffMember") return null;

  return <>{user.role === Role.Admin ? children : null}</>;
};

export { Admin };
