import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { QueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import {
  GetProductTreeDocument,
  GetProductTreeQuery,
  GetProductTreeQueryVariables,
  ProductTree as ProductTreeGQL,
} from "type";

export type ProductTree = Omit<
  ProductTreeGQL,
  "halfProducts" | "ingredients"
> & {
  [K in "halfProducts" | "ingredients"]?: ProductTreeGQL[K];
};

interface StateContext {
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  backRef: string | undefined;
  searchValue: string | undefined;
  productTree: ProductTree | undefined;
  quantity: number;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const { productId } = useParams();
  const location = useLocation();

  const [backRef] = useState<string | undefined>(
    location.state?.backRef ? location.state.backRef : undefined
  );
  const [searchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productTree, setProductTree] = useState<ProductTree>();
  const [quantity] = useState<number>(
    location.state !== null ? location.state.quantity : 1
  );

  useEffect(() => {
    queryClient
      .fetchQuery({
        queryKey: ["productTree"],
        queryFn: ({ signal }) => {
          return graphQLClient.request<
            GetProductTreeQuery,
            GetProductTreeQueryVariables
          >({
            document: GetProductTreeDocument,
            variables: {
              id: productId,
            },
            signal,
          });
        },
        staleTime: 0,
      })
      .then((result) => {
        setProductTree(result.productTree as ProductTree);
        setIsLoading(false);
      });
  }, []);

  return (
    <StateContext.Provider
      value={{
        queryClient,
        graphQLClient,
        backRef,
        searchValue,
        productTree,
        quantity,
      }}
    >
      {/* return loading while children is undef? */}
      {isLoading ? null : children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
