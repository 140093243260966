import React from "react";

import { Container } from "./Container";

const Page: React.FC = () => {
  return (
    <div className="flex w-full">
      <Container />
    </div>
  );
};

export { Page as PagePC };
