import { LoaderFunctionArgs, RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { z } from "zod";

import {
  EditProductMutation,
  EditProductMutationVariables,
  EditProductDocument,
  GetEditProductPageDataQuery,
  GetEditProductPageDataQueryVariables,
  GetEditProductPageDataDocument,
} from "type";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./State";
import { formSchema } from "./formSchema";
import { Form } from "./components/Form";

export type LoaderData = {
  currentProduct: GetEditProductPageDataQuery["products"][0];
  productTags: GetEditProductPageDataQuery["productTags"];
  categories: GetEditProductPageDataQuery["categories"];
};

export type ActionData = {
  data: string | null;
  error: EditProductMutation["product"]["edit"]["errors"][0] | null;
};

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<LoaderData> => {
    const result = await queryClient.fetchQuery({
      queryKey: ["editProductPageData"],
      queryFn: async () =>
        graphQLClient.request<
          GetEditProductPageDataQuery,
          GetEditProductPageDataQueryVariables
        >({
          document: GetEditProductPageDataDocument,
          variables: {
            id: params.productId as string,
          },
        }),
      staleTime: 0,
    });
    return {
      currentProduct: result.products[0],
      productTags: result.productTags,
      categories: result.categories,
    };
  };

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }) => {
    const formData = (await request.json()) as z.infer<typeof formSchema>;
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["createProduct"],
        queryFn: async () =>
          graphQLClient.request<
            EditProductMutation,
            EditProductMutationVariables
          >({
            document: EditProductDocument,
            variables: {
              input: {
                id: formData.id,
                name: formData.name,
                price: formData.price,
                image: formData.image,
                ingredients:
                  formData.ingredients.length === 0
                    ? null
                    : formData.ingredients.map((ingredient) => ({
                        id: ingredient.id,
                        weight: ingredient.weight,
                      })),
                halfProducts:
                  formData.halfProducts.length === 0
                    ? null
                    : formData.halfProducts.map((halfProduct) => ({
                        id: halfProduct.id,
                        weight: halfProduct.weight,
                      })),
                tags: formData.tags.length === 0 ? null : formData.tags,
                categoryId: formData.categoryId,
              },
            },
          }),
        staleTime: 0,
      })
    ).product.edit;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "ProductNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
      }
      return {
        data: null,
        error: error ?? null,
      };
    }
    return {
      data: result.product?.id,
      error: null,
    };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.EditProduct as string,
    loader: getLoader(queryClient, graphQLClient),
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Form />
      </StateProvider>
    ),
  };
};

export { getRoute as getEditProductRoute };
