export const PRODUCT_TAG_MAP = {
  kurczak: "/tags/kurczak.png",
  indyk: "/tags/indyk.png",
  wieprzowina: "/tags/wieprzowina.png",
  wołowina: "/tags/wołowina.png",
  ryba: "/tags/ryba.png",
  wegetariańskie: "/tags/wegetariańskie.png",
  fit: "/tags/fit.png",
  "niska zawartość węglowodanów": "/tags/niska_zawartość_węglowodanów.png",
  "bez glutenu": "/tags/bez_glutenu.png",
  "niski indeks glikemiczny": "/tags/niski_indeks_glikemiczny.png",
  "wysoko białkowe": "/tags/wysoko_białkowe.png",
  keto: "/tags/keto.png",
  ostre: "/tags/ostre.png",
  "bardzo ostre": "/tags/bardzo_ostre.png",
};
