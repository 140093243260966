import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import React, { useEffect, useState } from "react";
import { RouterProvider, RouterProviderProps } from "react-router-dom";
import ReactDOM from "react-dom/client";

import { getStaffRouter } from "pages/staff/router";
import { getLoginRouter } from "pages/auth/router";
import { GetCustomerRouter } from "pages/customer/router";

import { AuthData, AuthProvider, useAuthContext } from "AuthContext";
import { GqlProvider, useGqlContext } from "GqlContext";
import { DeviceContextProvider } from "DeviceContext";

// export const queryClient = new QueryClient();

// const LoadingPage: React.FC = () => {
//   return (
//     <div className="flex bg-blue-300 h-screen w-screen">
//       <div className="text-9xl m-auto">LOADING</div>
//     </div>
//   );
// };

// const Root: React.FC = () => {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [showLoadingPage, setShowLoadingPage] = useState(false);

//   useEffect(() => {
//     let timer: NodeJS.Timeout | null = null;

//     const setLoadingTimeout = () => {
//       timer = setTimeout(() => {
//         setShowLoadingPage(true);
//       }, 200);
//     };

//     setLoadingTimeout();

//     return () => {
//       if (timer) {
//         clearTimeout(timer);
//       }
//     };
//   }, []);

//   const queryClient = new QueryClient();
//   const router = getRouter(queryClient, setIsLoaded);

//   return (
//     <React.StrictMode>
//       <QueryClientProvider client={queryClient}>
//         {/* {!isLoaded ? showLoadingPage ? <LoadingPage /> : null : null} */}
//         <RouterProvider router={router} />
//       </QueryClientProvider>
//     </React.StrictMode>
//   );
// };

const Root: React.FC = () => {
  const { queryClient, graphQLClient } = useGqlContext();
  const { authData, validateAuthentication } = useAuthContext();

  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [isRouterRdy, setIsRouterRdy] = useState<boolean>(false);
  const [router, setRouter] = useState<
    RouterProviderProps["router"] | undefined
  >(undefined);

  useEffect(() => {
    setIsRouterRdy(false);
    const initializeAuthentication = async () => {
      setIsValidating(true);
      await validateAuthentication();
      setIsValidating(false);
    };

    initializeAuthentication();
  }, []);

  useEffect(() => {
    if (!isValidating && authData.userType) {
      switch (authData.userType.__typename) {
        case "StaffMember":
          setRouter(
            getStaffRouter(queryClient, graphQLClient, authData.userType.role)
          );
          break;
        case "Customer":
          setRouter(GetCustomerRouter(queryClient, graphQLClient));
          break;
        default:
          throw new Error("Unexpected authentication error.");
      }
      setIsRouterRdy(true);
    } else if (!isValidating) {
      setRouter(getLoginRouter());
      setIsRouterRdy(true);
    }
  }, [authData, isValidating]);

  // TODO Setup for that when cookies/local storage is emptyied call logout.
  // useEffect(() => {
  //   const handleStorageChange = (event: StorageEvent) => {
  //     console.log(event);
  //   };

  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  return (
    // prevents flashing, do better!
    <div className="h-dvh w-dvw bg-bgray-600">
      {isRouterRdy ? <RouterProvider router={router!} /> : <></>}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <GqlProvider>
        <AuthProvider>
          <DeviceContextProvider>
            <Root />
          </DeviceContextProvider>
        </AuthProvider>
      </GqlProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

serviceWorkerRegistration.register();
