import { useEffect, useState } from "react";
import { QueryClient } from "@tanstack/react-query";
import { useLoaderData } from "react-router";

import { GetIngredientsFilteredQuery } from "type";

import { SearchInput } from "../components/SearchInput";
import { ItemList } from "../components/ItemList";
import { Header } from "./Header";

const Container: React.FC = () => {
  // const { ingredients: initialIngredients } = useLoaderData() as {
  //   queryClient: QueryClient;
  //   ingredients: GetIngredientsFilteredQuery["ingredients"];
  // };

  // const [ingredients, setIngredients] = useState<
  //   GetIngredientsFilteredQuery["ingredients"]
  // >([]);

  // const [areIngredientsLoading, setAreIngredientsLoading] =
  //   useState<boolean>(true);

  // useEffect(() => {
  //   setIngredients(initialIngredients);
  //   setAreIngredientsLoading(false);
  // }, [initialIngredients]);

  return (
    <div className="mx-auto px-5 pb-5 flex flex-grow max-w-3xl flex-col ">
      <Header />
      <SearchInput
      // setIngredients={setIngredients}
      // setAreIngredientsLoading={setAreIngredientsLoading}
      />
      <ItemList
      // ingredients={ingredients}
      // areIngredientsLoading={areIngredientsLoading}
      />
    </div>
  );
};

export { Container };
