import { useEffect, useState } from "react";

import {
  AddDailyMenuDocument,
  AddDailyMenuMutation,
  AddDailyMenuMutationVariables,
} from "type";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "Shadcn/Select";

import { useStateContext } from "../../state/StateProvider";
import { DayKey, Day } from "../../state/types";

const DaySelect: React.FC<{
  setData: React.Dispatch<
    React.SetStateAction<{ key: DayKey; date: string } | null>
  >;
}> = ({ setData }) => {
  const { week } = useStateContext();

  const [selectedDay, setSelectedDay] = useState<string>(
    JSON.stringify(week.monday)
  );

  useEffect(() => {
    let day: Day | undefined = undefined;
    const lastDayIndex = week.toList().findLastIndex((day) => day.id !== null);
    if (lastDayIndex !== -1 && lastDayIndex !== 4) {
      day = week.toList()[lastDayIndex + 1];
    } else {
      day = week.toList().find((day) => day.id === null);
    }

    if (day) {
      setData({ key: day.key, date: day.date });
      setSelectedDay(JSON.stringify(day));
    } else {
      setData({ key: week.monday.key, date: week.monday.date });
      setSelectedDay(JSON.stringify(week.monday));
    }
  }, [week]);

  // TODO
  if (week.toList().some((day) => day.date === "")) {
    return;
  }

  return (
    <Select
      value={selectedDay}
      onValueChange={(dayJSON) => {
        const day = JSON.parse(dayJSON);
        setSelectedDay(JSON.stringify(day));
        setData({ key: day.key, date: day.date });
      }}
    >
      <SelectTrigger className="w-[180px] h-full border-none rounded-none font-medium bg-bgray-100 focus-visible:ring-black focus-visible:ring-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="rounded-none border-none bg-white">
        <SelectGroup className="m-0 p-0">
          {week.toList().map((day) =>
            !day.id ? (
              <SelectItem
                key={day.key}
                value={JSON.stringify(day)}
                className="rounded-none data-[state=checked]:bg-gray-400 hover:bg-gray-200"
              >
                {day.name}
              </SelectItem>
            ) : null
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const AddDayButton: React.FC<{
  parentRef: React.RefObject<HTMLDivElement>;
}> = ({ parentRef }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();

  const [selectedDayData, setSelectedDayData] = useState<{
    key: DayKey;
    date: string;
  } | null>(null);

  const addDay = () => {
    if (selectedDayData) {
      queryClient
        .fetchQuery({
          queryKey: ["addDailyMenu"],
          queryFn: async () =>
            graphQLClient.request<
              AddDailyMenuMutation,
              AddDailyMenuMutationVariables
            >({
              document: AddDailyMenuDocument,
              variables: {
                date: selectedDayData.date,
              },
            }),
          staleTime: 0,
        })
        .then(
          ({
            menu: {
              daily: { add: id },
            },
          }) => {
            setWeek({
              ...week,
              [selectedDayData.key]: {
                ...week[selectedDayData.key],
                id: id,
                date: selectedDayData.date,
                quantity: null,
              },
            });
          }
        );
    }
  };

  // useEffect(() => {
  //   if (parentRef.current) {
  //     parentRef.current.scrollTo({
  //       left: parentRef.current.scrollWidth,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [week]);

  return (
    <div className="flex h-[52px] items-center bg-bgray-600 ">
      <DaySelect setData={setSelectedDayData} />
      <button
        className="items-center flex h-full w-[52px] gap-1 p-2 text-white hover:bg-black"
        onClick={addDay}
      >
        <svg
          className="h-full w-full p-1.5"
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
        </svg>
      </button>
    </div>
  );
};

export { AddDayButton };
