import { useEffect, useState } from "react";

// import { Item } from "./Item";
import { Product, useStateContext } from "../State";
import { Item } from "./Item";

const ItemList: React.FC = () => {
  const { menuItem } = useStateContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // replcae state with memo?
  // const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const [products] = useState<Product[]>(
    menuItem.subProductLists.flatMap(
      (subProductList) => subProductList.products
    )
  ); // will never change?

  return (
    <div className="flex flex-col w-full ">
      <div className="p-4 bg-red-400">{menuItem.mainProduct?.name}</div>
      <ul className="w-full grid gap-y-px overflow-auto">
        {products.map((product) => (
          <Item key={product.id} product={product} />
        ))}

        {
          isLoading ? null : menuItem.subProductLists.length == 0 ? (
            <div className="text-center text-white">Brak zamówień</div>
          ) : null
          // [...menu, ...menu, ...menu, ...menu, ...menu].map((menuItem) => (
          //   <>
          //     <Item key={menuItem.id} menuItem={menuItem} />
          //   </>
          // ))
        }
      </ul>
    </div>
  );
};

export { ItemList };
