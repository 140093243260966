"use client";

import { ControllerRenderProps } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useActionData, useNavigate, useSubmit } from "react-router-dom";

import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { useStateContext } from "../State";
import { ActionData } from "../route";

import { HalfProductsSelect } from "./HalfProductsSelect";
import { IngredientsSelect } from "./IngredientsSelect";
import { Summary } from "./Summary";
import { DropZone } from "./DropZone";
import { TagsPanel } from "./TagsPanel";
import { useDeviceContext } from "DeviceContext";
import { Header } from "./Header";
import { PC } from "PC";
import { CategoryPanel } from "./CategoryPanel";

const CreateForm: React.FC = () => {
  const { isMobile } = useDeviceContext();

  const actionData = useActionData() as ActionData;
  const navigate = useNavigate();
  const submit = useSubmit();

  const { form, backRef, searchValue } = useStateContext();

  const nameInputRef = useRef<HTMLInputElement | null>(null);

  type Status = "IDLE" | "IN_PROGRESS" | "FINISHED";
  const [status, setStatus] = useState<Status>("IDLE");

  useEffect(() => {
    if (actionData) {
      if (actionData.error) {
        form.setError("name", {
          type: actionData.error.__typename,
          message: `Istnieje już produkt o nazwie: "${form.getValues().name}".`,
        });
      } else {
        setStatus("FINISHED");
      }
    }
  }, [actionData]);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setStatus("IN_PROGRESS");
    submit(values, { method: "post", encType: "application/json" });
  };

  const renderStatus = () => {
    switch (status) {
      case "IDLE":
        return null;
      case "IN_PROGRESS":
        return <span className="text-yellow-400">Zapisywanie...</span>;
      case "FINISHED":
        return <span className="text-bgreen-100">Ukończono!</span>;
      default:
        return null;
    }
  };

  const onChange =
    (field: ControllerRenderProps<z.infer<typeof formSchema>, "price">) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      value = event.target.value.replace(/[^0-9.,]/g, "").replace(".", ",");
      // Split the value into the integer and fractional parts (if a decimal is present)
      const parts = value.split(",");
      if (parts[0]) {
        parts[0] = parts[0].replace(/^0+/, "") || "0"; // Ensures "0" if input was "0000"
        if (parts.length === 1) {
          value = parts[0];
        }
      }
      // Ensure there is only one decimal point in the value
      if (parts.length > 2) {
        value = `${parts[0]},${parts[1]}`;
      }
      // Allow only one digit after the decimal point
      if (parts.length === 2 && parts[1].length > 2) {
        value = `${parts[0]},${parts[1].slice(0, 2)}`;
      }

      field.onChange(value);
    };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onFocus={() => setStatus("IDLE")}
        className="flex flex-col h-full w-full overflow-auto space-y-2"
      >
        <div className="flex flex-col flex-grow h-full w-full overflow-auto">
          <div className={"overflow-auto " + (isMobile ? "px-5" : "")}>
            <div className="max-w-xl mx-auto pb-5">
              <Header />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj nazwę..."
                        {...field}
                        ref={(input) => {
                          nameInputRef.current = input;
                        }}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Cena</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj cenę..."
                        onChange={onChange(field)}
                        value={field.value}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ingredients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Składniki</FormLabel>
                    <FormControl>
                      <IngredientsSelect zIngredients={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="halfProducts"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Półprodukty</FormLabel>
                    <FormControl>
                      <HalfProductsSelect zHalfProducts={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Podsumowanie</FormLabel>
                <Summary />
              </FormItem>
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zdjęcie</FormLabel>
                    <FormControl>
                      <DropZone zodImage={field} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="tags"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Oznaczenia</FormLabel>
                    <FormControl>
                      <TagsPanel zodTagsArray={field} {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="categoryId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Kategorie</FormLabel>
                    <FormControl>
                      <CategoryPanel zodCategoryId={field} {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div
            className={
              "relative flex w-full pr-[var(--scrollbar-width)] " +
              (isMobile ? " bg-bgray-600" : "")
            }
          >
            <div className="flex w-full max-w-xl mx-auto justify-between px-3 py-2 bg-bgray-600">
              <Button
                type="button"
                onClick={() => {
                  // Unfortunate hax, but because of the way react router works only way i found to make this work, otherwise state would run once before loader setting defaults from the current route on the form and then another run with correct data would do nothing. To fix this we would probably have to redo how this works, ie get rid of loaders and action altogether for example.
                  navigate("/");
                  backRef === null || backRef === undefined
                    ? navigate(pathing.BrowseProducts as string, {
                        state: { searchValue: searchValue },
                      })
                    : navigate(
                        (pathing.EditProduct as string).replace(
                          ":productId",
                          backRef
                        ),
                        {
                          state: { searchValue: searchValue },
                        }
                      );
                }}
                className="hover:bg-black"
              >
                Wróć
              </Button>
              <div className="flex items-center text-lg text-bgray-100">
                {renderStatus()}
              </div>
              <Button
                type="submit"
                className="text-white bg-bgreen-100 hover:bg-bgreen-300"
              >
                Edytuj
              </Button>
            </div>
            <PC>
              <div className="absolute bottom-0 right-0 h-full w-[var(--scrollbar-width)] bg-bgray-600"></div>
            </PC>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { CreateForm as Form };
