import { PagePC } from "./pc/Page";
import { PageMobile } from "./mobile/Page";

export const Page: React.FC = () => {
  return (
    <>
      <div className="hidden md:flex md:flex-grow">
        <PagePC />
      </div>
      <div className="flex flex-grow flex-col md:hidden">
        <PageMobile />
      </div>
    </>
  );
};
