import { redirect, RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { z } from "zod";

import {
  GetCreateOrderPageDataDocument,
  GetCreateOrderPageDataQuery,
  PlaceInternalOrderDocument,
  PlaceInternalOrderMutation,
  PlaceInternalOrderMutationVariables,
} from "type";

import { pathing } from "pages/staff/pathing";

import { Page } from "./Page";
import { StateProvider } from "./State";
import { formSchema } from "./formSchema";

export type ActionData = {
  data: { deliveryDate: string };
};

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async (): Promise<GetCreateOrderPageDataQuery["allProducts"]> => {
    return (
      await queryClient.fetchQuery({
        queryKey: ["menuPageData"],
        queryFn: async () =>
          graphQLClient.request<GetCreateOrderPageDataQuery>({
            document: GetCreateOrderPageDataDocument,
          }),
        staleTime: 0,
      })
    ).allProducts;
  };

// Requires some fixing
const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }) => {
    const formData = (await request.json()) as Omit<
      z.infer<typeof formSchema>,
      "deliveryDate"
    > & { deliveryDate: string };
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["placeInternalOrder"],
        queryFn: async () =>
          graphQLClient.request<
            PlaceInternalOrderMutation,
            PlaceInternalOrderMutationVariables
          >({
            document: PlaceInternalOrderDocument,
            variables: {
              input: {
                deliveryDate: formData.deliveryDate,
                quantity: formData.totalQuantity,
                mainProduct: {
                  id: formData.mainProduct.id,
                  quantity: formData.mainProduct.quantity,
                },
                subProducts:
                  formData.subProducts.length === 0
                    ? null
                    : formData.subProducts.map((product) => ({
                        id: product.id,
                        quantity: product.quantity,
                      })),
              },
            },
          }),
        staleTime: 0,
      })
    ).order.place.success;
    // if (result.errors.length != 0) {
    //   const error = result.errors.find(
    //     (error) => error.__typename === "ProductNameTakenError"
    //   );
    //   if (!error) {
    //     // TODO Unhandled error, what to do? Probably navigate to some global error page.
    //     // TODO Request should also have try catch to do the same.
    //   }
    //   return error;
    // }
    // return redirect(pathing.BrowseOrders as string);
    return {
      data: { deliveryDate: formData.deliveryDate },
    };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.CreateOrder as string,
    loader: getLoader(queryClient, graphQLClient),
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Page />
      </StateProvider>
    ),
  };
};

export { getRoute as getPlaceOrderRoute };
