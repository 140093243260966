import { useNavigate } from "react-router-dom";

import { pathing } from "pages/customer/pathing";

import { DailyMenuItem } from "../State";

const Item: React.FC<{ menuItem: DailyMenuItem }> = ({ menuItem }) => {
  const navigate = useNavigate();

  return (
    <li
      className="flex min-h-[80px] items-center px-4 cursor-pointer bg-bgray-400 hover:bg-bgray-500"
      onClick={() =>
        navigate(
          (pathing.Order as string).replace(":menuItemId", menuItem.id),
          { state: menuItem }
        )
      }
    >
      {menuItem.mainProduct?.name}
    </li>
  );
};

export { Item };
