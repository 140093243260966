import { RouteObject, redirect } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { pathing } from "pages/staff/pathing";

import {
  GetIngredientsFilteredQuery,
  GetIngredientsFilteredDocument,
  Ingredient,
} from "type";

import {
  RemoveIngredientMutation,
  RemoveIngredientMutationVariables,
  RemoveIngredientDocument,
} from "type";

import { Page } from "./Page";
import { StateProvider } from "./State";

// const getLoader =
//   (queryClient: QueryClient, graphQLClient: GraphQLClient) => async () => {
//     // TODO: Add error handling.
//     return {
//       queryClient: queryClient,
//       graphQLClient: graphQLClient,
//       ingredients: (
//         await queryClient.fetchQuery({
//           queryKey: ["ingredients"],
//           queryFn: async () =>
//             graphQLClient.request<GetIngredientsFilteredQuery>({
//               document: GetIngredientsFilteredDocument,
//             }),
//           staleTime: 0,
//         })
//       ).ingredients,
//     };
//   };

// const deleteIngredient = async (
//   queryClient: QueryClient,
//   graphQLClient: GraphQLClient,
//   ingredient: Ingredient
// ) => {
//   const result = (
//     await queryClient.fetchQuery({
//       queryKey: ["deleteIngredients", ingredient.id],
//       queryFn: async () =>
//         graphQLClient.request<
//           RemoveIngredientMutation,
//           RemoveIngredientMutationVariables
//         >({
//           document: RemoveIngredientDocument,
//           variables: { id: ingredient.id },
//         }),
//       staleTime: 0,
//     })
//   ).ingredient.remove;
//   if (result.errors.length != 0) {
//     const error = result.errors.find(
//       (error) => error.__typename === "IngredientNotFoundError"
//     );
//     if (!error) {
//       // TODO Unhandled error, what to do? Probably navigate to some global error page.
//       // TODO Request should also have try catch to do the same.
//     }
//     return error;
//   }
//   return result.id;
// };

// const editIngredeint = (ingredient: Ingredient & { searchValue: string }) => {
//   const editedUrl = (pathing.EditIngredient as string).replace(
//     ":ingredientId",
//     ingredient.id
//   );
//   const redirectUrl = `${editedUrl}
//     ?name=${encodeURIComponent(ingredient.name)}
//     &kilocalories=${encodeURIComponent(ingredient.kilocalories)}
//     &fat=${encodeURIComponent(ingredient.fat)}
//     &saturatedFat=${encodeURIComponent(ingredient.saturatedFat)}
//     &carbohydrates=${encodeURIComponent(ingredient.carbohydrates)}
//     &sugars=${encodeURIComponent(ingredient.sugars)}
//     &fiber=${encodeURIComponent(ingredient.fiber)}
//     &protein=${encodeURIComponent(ingredient.protein)}
//     &salt=${encodeURIComponent(ingredient.salt)}
//     &details=${ingredient.details ? encodeURIComponent(ingredient.details) : ""}
//     &description=${encodeURIComponent(ingredient.description)}
//     &searchValue=${encodeURIComponent(ingredient.searchValue)}
//   `.replace(/\s+/g, "");
//   return redirect(redirectUrl);
// };

// const getAction =
//   (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
//   async ({ request }: { request: Request }) => {
//     const ingredient = (await request.json()) as Ingredient & {
//       searchValue: string;
//     };
//     switch (request.method) {
//       case "PATCH":
//         return editIngredeint(ingredient);
//       case "DELETE":
//         return await deleteIngredient(queryClient, graphQLClient, ingredient);
//     }
//   };

const Route: RouteObject = {
  path: pathing.BrowseIngredients as string,
  // loader: getLoader(queryClient, graphQLClient),
  // action: getAction(queryClient, graphQLClient),
  element: (
    <StateProvider>
      <Page />
    </StateProvider>
  ),
};

export { Route as BrowseIngredientsRoute };
