import { cn } from "Shadcn/cn";
import { useStateContext } from "../State";
import { useEffect, useState } from "react";

import {
  HalfProduct as zHalfProduct,
  Ingredient as zIngredient,
} from "../formSchema";
import { z } from "zod";

type HalfProduct = z.infer<typeof zHalfProduct>;
type Ingredient = z.infer<typeof zIngredient>;

type Summary = {
  kilocalories: string;
  fat: string;
  saturatedFat: string;
  carbohydrates: string;
  sugars: string;
  fiber: string;
  protein: string;
  salt: string;
  weight: string;
};

const Summary: React.FC = () => {
  const { form, basedOn } = useStateContext();

  const summarize = (items: Ingredient[] | HalfProduct[]): Summary => {
    const f = (number: number, decimalPoint: number): string => {
      return number.toFixed(decimalPoint).toString().replace(".", ",");
    };
    const summary = items.reduce(
      (summary, item) => {
        summary.kilocalories += (item.details.kilocalories / 100) * item.weight;
        summary.fat += (item.details.fat / 100) * item.weight;
        summary.saturatedFat += (item.details.saturatedFat / 100) * item.weight;
        summary.carbohydrates +=
          (item.details.carbohydrates / 100) * item.weight;
        summary.sugars += (item.details.sugars / 100) * item.weight;
        summary.fiber += (item.details.fiber / 100) * item.weight;
        summary.protein += (item.details.protein / 100) * item.weight;
        summary.salt += (item.details.salt / 100) * item.weight;
        summary.weight += item.weight;
        return summary;
      },
      {
        kilocalories: 0,
        fat: 0,
        saturatedFat: 0,
        carbohydrates: 0,
        sugars: 0,
        fiber: 0,
        protein: 0,
        salt: 0,
        weight: 0,
      }
    );
    return {
      kilocalories: f(summary.kilocalories, 0),
      fat: f(summary.fat, 1),
      saturatedFat: f(summary.saturatedFat, 1),
      carbohydrates: f(summary.carbohydrates, 1),
      sugars: f(summary.sugars, 1),
      fiber: f(summary.fiber, 1),
      protein: f(summary.protein, 1),
      salt: f(summary.salt, 2),
      weight: f(summary.weight, 0),
    };
  };

  const [summary, setSummary] = useState<Summary>(summarize([]));

  useEffect(() => {
    if (basedOn !== null) {
      const values = form.getValues();
      const ingredients = values.ingredients as Ingredient[];
      const halfProducts = values.halfProducts as HalfProduct[];
      setSummary(summarize([...ingredients, ...halfProducts]));
    }
  }, []);

  useEffect(() => {
    const subscription = form.watch((values) => {
      const ingredients = values.ingredients as Ingredient[];
      const halfProducts = values.halfProducts as HalfProduct[];
      setSummary(summarize([...ingredients, ...halfProducts]));
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const left = cn("w-1/2 border border-bgray-300 px-1");
  const right = cn("w-1/2 border border-bgray-300 px-1");

  return (
    <div className="flex flex-col gap-0.5 text-sm text-white">
      <table className="w-full table-auto font-medium">
        <tbody>
          <tr>
            <td className={`${left} border-white font-bold`}>
              Wartość odżywcza
            </td>
            <td className={`${right} border-white font-bold`}>
              w porcji ({summary.weight}g)
            </td>
          </tr>
          <tr>
            <td className={left}>Wartość energetyczna</td>
            <td className={right}>{summary.kilocalories}kcal</td>
          </tr>
          <tr>
            <td className={left}>Tłuszcz</td>
            <td className={right}>{summary.fat}g</td>
          </tr>
          <tr>
            <td className={left}>w tym kwasy tłuszczowe</td>
            <td className={right}>{summary.saturatedFat}g</td>
          </tr>
          <tr>
            <td className={left}>Węglowodany</td>
            <td className={right}>{summary.carbohydrates}g</td>
          </tr>
          <tr>
            <td className={left}>w tym cukry</td>
            <td className={right}>{summary.sugars}g</td>
          </tr>
          <tr>
            <td className={left}>Błonnik</td>
            <td className={right}>{summary.fiber}g</td>
          </tr>
          <tr>
            <td className={left}>Białko</td>
            <td className={right}>{summary.protein}g</td>
          </tr>
          <tr>
            <td className={left}>Sól</td>
            <td className={right}>{summary.salt}g</td>
          </tr>
        </tbody>
      </table>
      <div className="font-medium text-base text-white">
        Waga całkowita: {summary.weight}g
      </div>
    </div>
  );
};

export { Summary };
