import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { Role } from "type";

import { ErrorPage } from "./ErrorPage";
import { Root } from "./Root";
import { BrowseIngredientsRoute } from "../ingredients/browse/route";
import { getAddIngredientRoute } from "../ingredients/add/route";
import { getEditIngredientRoute } from "../ingredients/edit/route";
import { getBrowseHalfProductsRoute } from "../halfproducts/browse/route";
import { getAddHalfProductRoute } from "../halfproducts/add/route";
import { getEditHalfProductRoute } from "../halfproducts/edit/route";
import { getHalfProductDetailsRoute } from "../halfproducts/details/route";
import { CategoriesRoute } from "../categories/route";
import { getBrowseProductsRoute } from "../products/browse/route";
import { getAddProductRoute } from "../products/add/route";
import { getEditProductRoute } from "../products/edit/route";
import { getMenuRoute } from "../menu/route";
import { getBrowseOrdersRoute } from "../orders/browse/route";
import { getPlaceOrderRoute } from "../orders/place/route";
import { getBrowseProductionRoute } from "../production/browse/route";
import { getProductDetailsRoute } from "../products/details/route";

type RouteObjectAuthorized = Omit<RouteObject, "children"> & {
  children?: (Omit<RouteObject, "children"> & { roles?: Role[] | Role })[];
};

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObjectAuthorized => {
  return {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true },
      {
        ...BrowseIngredientsRoute,
        roles: Role.Admin,
      },
      {
        ...getAddIngredientRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      {
        ...getEditIngredientRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      getBrowseHalfProductsRoute(queryClient, graphQLClient),
      {
        ...getAddHalfProductRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      {
        ...getEditHalfProductRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      getHalfProductDetailsRoute(queryClient, graphQLClient),
      { ...CategoriesRoute, roles: Role.Admin },
      getBrowseProductsRoute(queryClient, graphQLClient),
      {
        ...getAddProductRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      {
        ...getEditProductRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      getProductDetailsRoute(queryClient, graphQLClient),
      {
        ...getMenuRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      {
        ...getBrowseOrdersRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      {
        ...getPlaceOrderRoute(queryClient, graphQLClient),
        roles: Role.Admin,
      },
      getBrowseProductionRoute(queryClient, graphQLClient),
    ],
  };
};

export { getRoute as getRootRoute };
