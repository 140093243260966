import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGqlContext } from "GqlContext";
import {
  DeleteCategoryDocument,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from "type";
import { Category, useStateContext } from "./State";
import { forwardRef } from "react";

const Delete = forwardRef<HTMLDivElement, { category: Category }>(
  ({ category }, ref) => {
    const { queryClient, graphQLClient } = useGqlContext();
    const { setCategories } = useStateContext();

    const onClick = () => {
      if (ref && "current" in ref) {
        const categoryRef = ref.current;
        if (categoryRef) {
          categoryRef.style.opacity = "0.2";
          categoryRef.style.transition = "opacity 0.2s";
        }
      }

      requestAnimationFrame(() => {
        queryClient
          .fetchQuery({
            queryKey: ["deleteCategory"],
            queryFn: async () =>
              graphQLClient.request<
                DeleteCategoryMutation,
                DeleteCategoryMutationVariables
              >({
                document: DeleteCategoryDocument,
                variables: {
                  id: category.id,
                },
              }),
            staleTime: 0,
          })
          .then((result) => {
            if (result.category.delete.errors.length === 0) {
              setCategories((categories) =>
                categories.filter((_category) => _category.id !== category.id)
              );
            }
          });
      });
    };

    return (
      <button className="text-bred hover:text-red-800" onClick={onClick}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    );
  }
);

export { Delete };
