import { Sheet, SheetTrigger, SheetContent } from "Shadcn/Sheet";
import { Button } from "Shadcn/Button";

import { SideMenu } from "../components/SideMenu";

const MenuButton: React.FC<React.ComponentProps<typeof Button>> = (props) => {
  return (
    <Button
      {...props}
      variant="ghost"
      size="icon"
      className="rounded-full text-white"
    >
      <MenuIcon className="h-6 w-6 text-white" />
      <span className="sr-only">Toggle navigation menu</span>
    </Button>
  );
};

const SideMenuSheet: React.FC = () => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <MenuButton />
      </SheetTrigger>
      <SheetContent side="left" className="bg-white dark:bg-gray-950">
        <SideMenu />
      </SheetContent>
    </Sheet>
  );
};

function MenuIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

export { SideMenuSheet };
