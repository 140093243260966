import { Header } from "./Header";

import { Form } from "../components/Form";

const Container: React.FC = () => {
  return (
    <div className="flex mx-auto px-5 h-fit w-full flex-grow max-w-xl flex-col ">
      <Header />
      <Form />
    </div>
  );
};

export { Container };
