import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { QueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import {
  GetHalfProductTreeDocument,
  GetHalfProductTreeQuery,
  GetHalfProductTreeQueryVariables,
  HalfProductTree as HalfProductTreeGQL,
} from "type";

export type HalfProductTree = Omit<
  HalfProductTreeGQL,
  "halfProducts" | "ingredients"
> & {
  [K in "halfProducts" | "ingredients"]?: HalfProductTreeGQL[K];
};

interface StateContext {
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  backRef: string | undefined;
  searchValue: string | undefined;
  halfProductTree: HalfProductTree | undefined;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const { halfProductId } = useParams();
  const location = useLocation();

  const [backRef] = useState<string | undefined>(
    location.state?.backRef ? location.state.backRef : undefined
  );
  const [searchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const [halfProductTree, setHalfProductTree] = useState<HalfProductTree>();

  useEffect(() => {
    queryClient
      .fetchQuery({
        queryKey: ["halfProductTree"],
        queryFn: ({ signal }) => {
          return graphQLClient.request<
            GetHalfProductTreeQuery,
            GetHalfProductTreeQueryVariables
          >({
            document: GetHalfProductTreeDocument,
            variables: {
              id: halfProductId,
            },
            signal,
          });
        },
        staleTime: 0,
      })
      .then((result) => {
        setHalfProductTree(result.halfProductTree as HalfProductTree);
      });
  }, []);

  return (
    <StateContext.Provider
      value={{
        queryClient,
        graphQLClient,
        backRef,
        searchValue,
        halfProductTree,
      }}
    >
      {/* return loading while children is undef? */}
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
