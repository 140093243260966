import { useDeviceContext } from "DeviceContext";

const Header: React.FC = () => {
  const { isMobile } = useDeviceContext();

  const [classNameContainer, classNameHeader] = isMobile
    ? ["flex justify-between pt-7 pb-4 text-white", "text-2xl font-bold"]
    : ["flex justify-between pt-8 pb-8 text-white", "text-3xl font-bold"];

  return (
    <div className={classNameContainer}>
      <h1 className={classNameHeader}>Dodaj produkt</h1>
    </div>
  );
};

export { Header };
