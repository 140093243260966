import React, { useEffect, useRef, useState } from "react";

import { ProductionItem, Product } from "../State";
import { toLocaleISOString } from "utils/toLocaleISOString";

import { PRODUCT_TAG_MAP } from "productTagMap";

const ProductFC: React.FC<{ product: Product; fontSize: number }> = ({
  product,
  fontSize,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const factor = 2.7;
  const ingredientsFontSizeFactor = 0.7;

  const [dimension, setDimension] = useState<number>(fontSize * factor);

  useEffect(() => {
    setDimension(fontSize * factor);
  }, [fontSize]);

  const getTagUrl = (tag: Product["tags"][0]) => {
    type ProductTagKey = keyof typeof PRODUCT_TAG_MAP;
    return PRODUCT_TAG_MAP[tag as ProductTagKey];
  };

  return (
    <div className="flex gap-1">
      <div ref={ref} className="flex flex-col flex-grow justify-center">
        <div
          style={{ fontSize: Math.floor(fontSize) }}
          className="font-bold"
        >{`${product.name} ${
          product.quantity > 1 ? `x${product.quantity} ` : ""
        }(${product.weight * product.quantity}g) `}</div>
        {product.ingredients.length > 0 ? (
          <div
            style={{
              fontSize: Math.floor(ingredientsFontSizeFactor * fontSize),
            }}
          >{`(${product.ingredients.join(", ")})`}</div>
        ) : null}
      </div>
      <div className="flex flex-wrap justify-end">
        {product.tags.map((tag) => (
          <img
            src={getTagUrl(tag)}
            className="my-auto"
            style={{ width: dimension, height: dimension, minWidth: dimension }}
          />
        ))}
      </div>
    </div>
  );
};

const PDFAll: React.FC<{
  templateUrl: string;
  setPdfRender: any;
  productionItem: ProductionItem;
}> = ({ templateUrl, setPdfRender, productionItem }) => {
  const jpgRef = useRef<HTMLDivElement | null>(null); // Create a ref
  const contentDivRef = useRef<HTMLDivElement | null>(null); // Create a ref
  const [fontSize, setFontSize] = useState<number>(60);
  const [resizeComplete, setResizeComplete] = useState<boolean>(false);

  useEffect(() => {
    const contentDiv = contentDivRef.current;
    if (contentDiv) {
      // Results of height for some reason are a little bit off but its managable for now.
      const height = contentDiv.getBoundingClientRect().height;
      if (height > 390) {
        setFontSize(fontSize - 1);
      } else {
        setResizeComplete(true);
      }
    }
  }, [fontSize]);

  useEffect(() => {
    if (resizeComplete) {
      const element = jpgRef.current;
      if (element) {
        setPdfRender(element);
      }
    }
  }, [resizeComplete]);

  const [date, setDate] = useState<string>();

  useEffect(() => {
    const date = new Date(productionItem.deliveryDate);
    date.setDate(date.getDate() + 2);
    const dateText = toLocaleISOString(date).split("-").reverse().join(".");
    setDate(dateText);
  }, []);

  const subProductFontFactor = 0.7;

  return (
    <div
      ref={jpgRef}
      className="absolute text-black bottom-[-9999px] left-[-9999px] z-0 w-[1890px] h-[531px]"
      style={{
        fontFamily: "Verdana, sans-serif",
      }}
    >
      <img
        src={templateUrl}
        className="absolute bottom-0 left-0 w-full h-full"
      ></img>
      <div
        ref={contentDivRef}
        className="flex flex-col relative left-[402px] top-[68px]  min-h-[390px] w-[904px] gap-2 p-1 justify-center"
      >
        <ProductFC product={productionItem.mainProduct} fontSize={fontSize} />
        {productionItem.subProducts.map((product) => (
          <ProductFC
            product={product}
            fontSize={Math.floor(subProductFontFactor * fontSize)}
          />
        ))}
      </div>
      <div
        className="absolute right-[33px] bottom-[58px] w-[369px] text-center"
        style={{ fontSize: 30 }}
      >
        {date}
      </div>
      <div
        className="absolute right-[32px] bottom-[399px] h-[32px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.kilocalories}kcal
      </div>
      <div
        className="absolute right-[32px] bottom-[364px] h-[33px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.fat}g
      </div>
      <div
        className="absolute right-[32px] bottom-[330px] h-[32px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.saturatedFat}g
      </div>
      <div
        className="absolute right-[32px] bottom-[295px] h-[33px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.carbohydrates}g
      </div>
      <div
        className="absolute right-[32px] bottom-[261px] h-[32px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.sugars}g
      </div>{" "}
      <div
        className="absolute right-[32px] bottom-[226px] h-[33px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.fiber}g
      </div>{" "}
      <div
        className="absolute right-[32px] bottom-[192px] h-[32px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.protein}g
      </div>
      <div
        className="absolute right-[32px] bottom-[159px] h-[31px] w-[104px] pl-1 content-center"
        style={{ fontSize: 21 }}
      >
        {productionItem.salt}g
      </div>
      <div
        className="-rotate-90 absolute right-[295px] bottom-[177px] h-[140px] w-[382px] text-center content-center"
        style={{ fontSize: 64 }}
      >
        {productionItem.price}PLN
      </div>
    </div>
  );
};

export { PDFAll };
