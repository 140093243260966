import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { StateProvider } from "./State";
import { Page } from "./Page";
import {
  GetDailyMenusDocument,
  GetDailyMenusQuery,
  GetDailyMenusQueryVariables,
} from "type";
import { toLocaleISOString } from "utils/toLocaleISOString";
import { pathing } from "pages/customer/pathing";

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) => async () => {
    const dailyMenu = (
      await queryClient.fetchQuery({
        queryKey: ["dailyMenusForCustomer"],
        queryFn: async () =>
          graphQLClient.request<
            GetDailyMenusQuery,
            GetDailyMenusQueryVariables
          >({
            document: GetDailyMenusDocument,
            variables: {
              date: toLocaleISOString(new Date()),
            },
          }),
        staleTime: 0,
      })
    ).dailyMenus;

    if (dailyMenu.length === 1) {
      return dailyMenu[0].items;
    }
    return [];
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.Menu as string,
    loader: getLoader(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Page />
      </StateProvider>
    ),
  };
};

export { getRoute as getMenuRoute };
