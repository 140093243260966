"use client";

import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import { useEffect, useState } from "react";

import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { useStateContext } from "../State";
import { ActionData } from "../route";

import { SubHalfProductsSelect } from "./SubHalfProductsSelect";
import { IngredientsSelect } from "./IngredientsSelect";
import { Summary } from "./Summary";
import { PC } from "PC";
import { useDeviceContext } from "DeviceContext";
import { Header } from "./Header";

const EditForm: React.FC = () => {
  const { isMobile } = useDeviceContext();

  const actionData = useActionData() as ActionData;
  const navigate = useNavigate();
  const submit = useSubmit();

  const { form, backRef, searchValue } = useStateContext();

  type Status = "IDLE" | "IN_PROGRESS" | "FINISHED";
  const [status, setStatus] = useState<Status>("IDLE");

  useEffect(() => {
    if (actionData) {
      if (actionData.error) {
        form.setError("name", {
          type: actionData.error.__typename,
          message: `Istnieje już półprodukt o nazwie: "${
            form.getValues().name
          }".`,
        });
      } else {
        setStatus("FINISHED");
      }
    }
  }, [actionData]);

  useEffect(() => {
    form.setFocus("name");
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setStatus("IN_PROGRESS");
    submit(values, { method: "post", encType: "application/json" });
  };

  const renderStatus = () => {
    switch (status) {
      case "IDLE":
        return null;
      case "IN_PROGRESS":
        return <span className="text-yellow-400">Zapisywanie...</span>;
      case "FINISHED":
        return <span className="text-bgreen-100">Ukończono!</span>;
      default:
        return null;
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onFocus={() => setStatus("IDLE")}
        className="flex flex-col h-full w-full overflow-auto space-y-2"
      >
        <div className="flex flex-col flex-grow h-full w-full overflow-auto justify-between">
          <div className={"overflow-auto " + (isMobile ? "px-5" : "")}>
            <div className="max-w-xl mx-auto pb-5">
              <Header />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj nazwę..."
                        {...field}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ingredients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Składniki</FormLabel>
                    <FormControl>
                      <IngredientsSelect zIngredients={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subHalfProducts"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Półprodukty</FormLabel>
                    <FormControl>
                      <SubHalfProductsSelect zSubHalfProducts={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Podsumowanie</FormLabel>
                <Summary />
              </FormItem>
            </div>
          </div>
          <div
            className={
              "relative flex w-full pr-[var(--scrollbar-width)] " +
              (isMobile ? " bg-bgray-600" : "")
            }
          >
            <div className="flex w-full max-w-xl mx-auto justify-between px-3 py-2 bg-bgray-600">
              <Button
                type="button"
                onClick={() => {
                  // Unfortunate hax, but because of the way react router works only way i found to make this work, otherwise state would run once before loader setting defaults from the current route on the form and then another run with correct data would do nothing. To fix this we would probably have to redo how this works, ie get rid of loaders and action altogether for example.
                  navigate("/");
                  backRef === null || backRef === undefined
                    ? navigate(pathing.BrowseHalfProducts as string, {
                        state: { searchValue: searchValue },
                      })
                    : navigate(
                        (pathing.EditHalfProduct as string).replace(
                          ":halfProductId",
                          backRef
                        ),
                        {
                          state: { searchValue: searchValue },
                        }
                      );
                }}
                className="hover:bg-black"
              >
                Wróć
              </Button>
              <div className="flex items-center text-lg text-bgray-100">
                {renderStatus()}
              </div>
              <Button
                type="submit"
                className="text-white bg-bgreen-100 hover:bg-bgreen-300"
              >
                Edytuj
              </Button>
              <PC>
                <div className="absolute bottom-0 right-0 h-full w-[var(--scrollbar-width)] bg-bgray-600"></div>
              </PC>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { EditForm as Form };
