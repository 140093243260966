import { SelectSingleEventHandler } from "react-day-picker";
import { pl } from "date-fns/locale";

import { Calendar } from "Shadcn/Calendar";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "Shadcn/Dialog";

import { toLocaleISOString } from "utils/toLocaleISOString";
import { useStateContext } from "../State";

const DatePicker: React.FC = () => {
  const { deliveryDate, setDeliveryDate } = useStateContext();

  const onSelect: SelectSingleEventHandler = (date: Date | undefined) => {
    setDeliveryDate(date);
  };

  const format = (date: Date) =>
    toLocaleISOString(date).split("-").reverse().join(".");

  return (
    <div className="flex pb-2">
      <Dialog>
        <DialogTrigger className="flex h-10 rounded-md items-center px-3 py-2 text-sm bg-bgray-100">
          {deliveryDate ? format(deliveryDate) : "Wybierz datę"}
        </DialogTrigger>
        {/* <DialogContent className="scale-125 flex flex-col gap-1 w-fit p-1 border-none text-white bg-bgray-600"> */}
        <DialogContent className="scale-125 flex flex-col gap-1 w-fit p-1 border-none text-white bg-bgray-600">
          <DialogHeader className="flex mx-auto py-2.5">
            <DialogTitle>Wybierz datę</DialogTitle>
          </DialogHeader>
          <div className="bg-bgray-400 h-[0.5px] mx-1"></div>
          <div className="flex mx-auto">
            <Calendar
              mode="single"
              locale={pl}
              selected={deliveryDate}
              onSelect={onSelect}
              className="rounded-md border"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export { DatePicker };
