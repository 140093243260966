import { useEffect, useRef } from "react";

import { Input } from "Shadcn/Input";

import { useStateContext } from "../State";

const SearchInput: React.FC = () => {
  const { searchValue, setSearchValue } = useStateContext();

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      if (searchValue) {
        inputRef.current.value = searchValue;
      }
    }
  }, []);

  return (
    <div className="pb-4">
      <Input
        ref={inputRef}
        placeholder="Wyszukaj produkt..."
        onChange={(event) => setSearchValue(event.target.value.trim())}
      ></Input>
    </div>
  );
};

export { SearchInput };
