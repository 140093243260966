import { useCallback, useEffect, useState } from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  CreateSubProductListDocument,
  CreateSubProductListMutation,
  CreateSubProductListMutationVariables,
  RemoveMenuItemDocument,
  RemoveMenuItemMutation,
  RemoveMenuItemMutationVariables,
  SetMenuItemQuantityDocument,
  SetMenuItemQuantityMutation,
  SetMenuItemQuantityMutationVariables,
} from "type";

import { Input } from "Shadcn/Input";

import { Item } from "../state/types";
import { useStateContext } from "../state/StateProvider";
import { MainProduct } from "./MainProduct";
import { SubProductList } from "./SubProductList";

const QuantityBox: React.FC<{ item: Item }> = ({ item }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const [quantity, setQuantity] = useState<string | null>(
    item.quantity ? item.quantity.toString() : ""
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(
    week.toList().filter((day) => day.key === item.dayKey)[0].quantity === null
  );
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    setQuantity(item.quantity ? item.quantity.toString() : "");
  }, [item, week]);

  useEffect(() => {
    const dayOfWeek = week.toList().filter((day) => day.key === item.dayKey)[0];
    const hasQuantity = dayOfWeek.quantity !== null;
    setIsDisabled(hasQuantity ? false : true);
  }, [week]);

  const handleInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value.replace(/[^0-9]/g, "");

      // Still some flickering when "top" value is eg. 5 and we spam press 3
      setQuantity(!quantity ? value : value < quantity ? value : quantity);

      if (quantity === value) {
        return;
      }

      const dayOfWeek = week[item.dayKey];
      if (dayOfWeek.quantity) {
        if (dayOfWeek.quantity < parseInt(value)) {
          value = dayOfWeek.quantity.toString();
        }
      }

      queryClient.cancelQueries({ queryKey: ["setDailyMenuQauntity"] }, {});

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(async () => {
        try {
          await queryClient.fetchQuery({
            queryKey: ["setDailyMenuQauntity"],
            queryFn: ({ signal }) => {
              return graphQLClient.request<
                SetMenuItemQuantityMutation,
                SetMenuItemQuantityMutationVariables
              >({
                document: SetMenuItemQuantityDocument,
                variables: {
                  id: item.id,
                  quantity: parseInt(value),
                },
                signal,
              });
            },
            staleTime: 0,
          });
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 500);

      const itemIndex = dayOfWeek.items.findIndex(
        (_item) => _item.id === item.id
      );
      const updatedItems = [
        ...dayOfWeek.items.slice(0, itemIndex),
        { ...item, quantity: parseInt(value) },
        ...dayOfWeek.items.slice(itemIndex + 1),
      ];
      setWeek({
        ...week,
        [item.dayKey]: {
          ...dayOfWeek,
          items: updatedItems,
        },
      });
      setTimeoutId(newTimeoutId);
    },
    [timeoutId, week]
  );
  return (
    <Input
      disabled={isDisabled}
      value={quantity!}
      onChange={handleInput}
      className="w-7 h-8 rounded-none text-center p-0.5 focus-visible:outline-none
      text-white bg-bgray-500 border-0 border-b-2 border-white placeholder:text-bgray-100"
      placeholder="..."
      maxLength={3}
    ></Input>
  );
};

const ItemComponent: React.FC<{
  index: number;
  item: Item;
}> = ({ index, item }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();

  const removeItem = () => {
    queryClient
      .fetchQuery({
        queryKey: ["removeMenuItem"],
        queryFn: async () =>
          graphQLClient.request<
            RemoveMenuItemMutation,
            RemoveMenuItemMutationVariables
          >({
            document: RemoveMenuItemDocument,
            variables: {
              id: item.id,
            },
          }),
        staleTime: 0,
      })
      .then(() => {
        const dayOfWeek = week[item.dayKey];
        setWeek({
          ...week,
          [item.dayKey]: {
            ...dayOfWeek,
            items: dayOfWeek.items.filter((i) => i.id !== item.id),
          },
        });
      });
  };

  const addSubProductList = () => {
    queryClient
      .fetchQuery({
        queryKey: ["createSubProductList"],
        queryFn: async () =>
          graphQLClient.request<
            CreateSubProductListMutation,
            CreateSubProductListMutationVariables
          >({
            document: CreateSubProductListDocument,
            variables: {
              menuItemId: item.id,
            },
          }),
        staleTime: 0,
      })
      .then(
        ({
          menu: {
            daily: {
              item: {
                subProductList: { create: id },
              },
            },
          },
        }) => {
          const dayOfWeek = week[item.dayKey];
          const itemIndex = dayOfWeek.items.findIndex(
            (_item) => _item.id === item.id
          );
          const updatedItem = {
            ...item,
            subProductLists: [
              ...item.subProductLists,
              {
                id: id,
                dayKey: item.dayKey,
                itemId: item.id,
                products: [],
              },
            ],
          };
          const updatedItems = [
            ...dayOfWeek.items.slice(0, itemIndex),
            updatedItem,
            ...dayOfWeek.items.slice(itemIndex + 1),
          ];
          setWeek({
            ...week,
            [item.dayKey]: {
              ...dayOfWeek,
              items: updatedItems,
            },
          });
        }
      );
  };

  return (
    <div className="mt-2 bg-bgray-500">
      <div className="flex items-center justify-between text-white bg-bgray-600 p-2 ">
        <div className="flex font-medium">
          Danie {index + 1}
          {/* {item.id.substring(0, 8)} */}
        </div>
        <div className="flex gap-1 items-center">
          <QuantityBox item={item} />
          <button
            className="flex top-2 right-2 text-bred hover:text-red-800 h-fit p-2"
            onClick={removeItem}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 m-2">
        <MainProduct item={item} />
        <div className="flex flex-col gap-2">
          {item.subProductLists.map((list, i) => (
            <SubProductList key={list.id} index={i + 1} subProductList={list} />
          ))}
        </div>
        <button
          className="items-center justify-center flex h-fit w-fit mx-auto gap-1 py-1 pr-2 pl-2 rounded-lg text-white  hover:bg-bgreen-300"
          onClick={addSubProductList}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
          </svg>
          {/* Dodaj produkty */}
        </button>
      </div>
    </div>
  );
};

export { ItemComponent };
