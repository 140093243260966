import { Github } from "lucide-react";
import { Button } from "./components/ui/button";
import { KanbanBoard } from "./components/KanbanBoard";

const FooterLink = ({ children }: { children: React.ReactNode }) => {
  return (
    <Button
      variant="link"
      asChild
      className="scroll-m-20 text-xl font-semibold tracking-tight"
    >
      {children}
    </Button>
  );
};

const Kanban: React.FC = () => {
  return <KanbanBoard />;
};

export { Kanban };
