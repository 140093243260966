import { useState } from "react";

import { Item } from "./Item";
import { useStateContext } from "../State";

const ItemList: React.FC = () => {
  const { menu } = useStateContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <ul className="w-full grid gap-y-px overflow-auto">
      {isLoading ? null : menu.length == 0 ? (
        <div className="text-center text-white">Brak zamówień</div>
      ) : (
        [...menu, ...menu, ...menu, ...menu, ...menu].map((menuItem) => (
          <>
            <Item key={menuItem.id} menuItem={menuItem} />
          </>
        ))
      )}
    </ul>
  );
};

export { ItemList };
