import { cn } from "Shadcn/cn";
import { useStateContext } from "../State";
import { useEffect, useState } from "react";

import { Product } from "../formSchema";
import { z } from "zod";

type Summary = {
  price: string;
  weight: string;
  kilocalories: string;
  fat: string;
  saturatedFat: string;
  carbohydrates: string;
  sugars: string;
  fiber: string;
  protein: string;
  salt: string;
};

const Summary: React.FC = () => {
  const { form } = useStateContext();

  const summarize = (products: z.infer<typeof Product>[]): Summary => {
    const f = (number: number, decimalPoint: number): string => {
      return number.toFixed(decimalPoint).toString().replace(".", ",");
    };
    const summary = products.reduce(
      (summary, product) => {
        summary.price += product.details.price * product.quantity;
        summary.weight += product.details.weight * product.quantity;
        summary.kilocalories += product.details.kilocalories * product.quantity;
        summary.fat += product.details.fat * product.quantity;
        summary.saturatedFat += product.details.saturatedFat * product.quantity;
        summary.carbohydrates +=
          product.details.carbohydrates * product.quantity;
        summary.sugars += product.details.sugars * product.quantity;
        summary.fiber += product.details.fiber * product.quantity;
        summary.protein += product.details.protein * product.quantity;
        summary.salt += product.details.salt * product.quantity;
        return summary;
      },
      {
        price: 0,
        weight: 0,
        kilocalories: 0,
        fat: 0,
        saturatedFat: 0,
        carbohydrates: 0,
        sugars: 0,
        fiber: 0,
        protein: 0,
        salt: 0,
      }
    );
    return {
      price: f(summary.price, 2),
      weight: summary.weight.toString(),
      kilocalories: f(summary.kilocalories, 0),
      fat: f(summary.fat, 1),
      saturatedFat: f(summary.saturatedFat, 1),
      carbohydrates: f(summary.carbohydrates, 1),
      sugars: f(summary.sugars, 1),
      fiber: f(summary.fiber, 1),
      protein: f(summary.protein, 1),
      salt: f(summary.salt, 2),
    };
  };

  const [summary, setSummary] = useState<Summary>(summarize([]));

  useEffect(() => {
    const subscription = form.watch((values) => {
      const mainProduct = values.mainProduct as z.infer<typeof Product>;
      const subProducts = values.subProducts as z.infer<typeof Product>[];
      setSummary(
        summarize(mainProduct ? [mainProduct, ...subProducts] : subProducts)
      );
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const left = cn("w-1/2 border border-bgray-300 px-1");
  const right = cn("w-1/2 border border-bgray-300 px-1");

  return (
    <div className="flex flex-col gap-0.5 text-sm text-white">
      <table className="w-full table-auto font-medium">
        <tbody>
          <tr>
            <td className={left}>Wartość energetyczna</td>
            <td className={right}>{summary.kilocalories}kcal</td>
          </tr>
          <tr>
            <td className={left}>Tłuszcz</td>
            <td className={right}>{summary.fat}g</td>
          </tr>
          <tr>
            <td className={left}>w tym kwasy tłuszczowe</td>
            <td className={right}>{summary.saturatedFat}g</td>
          </tr>
          <tr>
            <td className={left}>Węglowodany</td>
            <td className={right}>{summary.carbohydrates}g</td>
          </tr>
          <tr>
            <td className={left}>w tym cukry</td>
            <td className={right}>{summary.sugars}g</td>
          </tr>
          <tr>
            <td className={left}>Błonnik</td>
            <td className={right}>{summary.fiber}g</td>
          </tr>
          <tr>
            <td className={left}>Białko</td>
            <td className={right}>{summary.protein}g</td>
          </tr>
          <tr>
            <td className={left}>Sól</td>
            <td className={right}>{summary.salt}g</td>
          </tr>
        </tbody>
      </table>
      <div>
        <span className="font-medium">Cena całkowita: </span>
        <span className="text-bgray-100">{summary.price}PLN</span>
        <span className="font-medium"> waga całkowita: </span>
        <span className="text-bgray-100">{summary.weight}g</span>
      </div>
    </div>
  );
};

export { Summary };
