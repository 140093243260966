import React from "react";

import { RootPC } from "./pc/Root";
import { RootMobile } from "./mobile/Root";

export const Root: React.FC = () => {
  return (
    <>
      <div className="hidden md:block">
        <RootPC />
      </div>
      <div className="md:hidden">
        <RootMobile />
      </div>
    </>
  );
};
