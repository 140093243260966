import React, { useState } from "react";

import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Dialog, DialogTrigger, DialogContent } from "Shadcn/Dialog";

import { ProductionItem } from "../State";
import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import html2canvas from "html2canvas";
import { PDF } from "./PDF";

const PDFViewer: React.FC<{ productionItem: ProductionItem }> = ({
  productionItem,
}) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generatePdfAndSetUrl = async (element: HTMLDivElement) => {
    setIsLoading(true);
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }"
    );

    const canvas = await html2canvas(element, { scale: 1, logging: false });
    style.remove();

    const dataUrl = canvas.toDataURL("image/png");

    const docDefinition: TDocumentDefinitions = {
      pageSize: {
        width: canvas.width,
        height: canvas.height,
      },
      content: [
        {
          image: dataUrl,
          width: canvas.width,
          height: canvas.height,
          absolutePosition: { x: 0, y: 0 },
        },
      ],
    };

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Dialog
        onOpenChange={(open) => {
          if (!open) {
            setPdfUrl(""); // Reset pdfUrl when dialog is closed
            setIsLoading(false); // Reset loading state
          }
        }}
      >
        <DialogTrigger>
          <button
            className="flex text-white hover:text-[#f40f02]"
            onClick={() => {
              setIsLoading(true);
              setShouldRender(true);
            }}
          >
            <FontAwesomeIcon className="h-6 w-6" icon={faFilePdf} />
          </button>
        </DialogTrigger>
        <DialogContent className="h-dvh w-dvw max-w-5xl border-none p-0 px-12 text-white bg-bgray-600">
          {isLoading ? (
            <div className="flex h-full mx-auto items-center text-lg ">
              Ładowanie...
            </div>
          ) : (
            pdfUrl && (
              <iframe
                key={pdfUrl}
                src={pdfUrl}
                className="w-full h-full"
                title="PDF Viewer"
              ></iframe>
            )
          )}
        </DialogContent>
      </Dialog>
      {shouldRender ? (
        <PDF
          templateUrl="./crop_no_resize_full.jpg"
          productionItem={productionItem}
          setShouldRender={setShouldRender}
          onRenderComplete={generatePdfAndSetUrl}
        />
      ) : null}
    </>
  );
};

export { PDFViewer };
