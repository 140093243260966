import { useLoaderData } from "react-router";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { Toggle } from "Shadcn/Toggle";

import { formSchema } from "../formSchema";
import { GetProductTagsAndCategoriesQuery } from "type";

const TagsPanel: React.FC<{
  zodTagsArray: ControllerRenderProps<z.infer<typeof formSchema>, "tags">;
}> = ({ zodTagsArray }) => {
  const { productTags } = useLoaderData() as GetProductTagsAndCategoriesQuery;

  const updateZodArray = (tag: string, isPressed: boolean) => {
    if (isPressed) {
      zodTagsArray.value.push(tag);
    } else {
      zodTagsArray.value.splice(zodTagsArray.value.indexOf(tag), 1);
    }
    zodTagsArray.onChange(zodTagsArray.value);
  };

  return (
    <div>
      <div className="">
        {productTags.type.map((tag) => (
          <Toggle
            className=" border-bgray-300 border text-white mr-2 mb-2 data-[state=on]:bg-black data-[state=on]:text-white data-[state=on]:border-1 data-[state=on]:border-black hover:bg-black hover:border-black hover:text-white"
            key={tag}
            pressed={zodTagsArray.value.includes(tag)}
            onPressedChange={(isPressed: boolean) =>
              updateZodArray(tag, isPressed)
            }
          >
            {tag}
          </Toggle>
        ))}
      </div>
      <div className="h-0.5 bg-bgray-600 mx-1 mb-1"></div>
      <div className="">
        {productTags.other.map((tag) => (
          <Toggle
            className="border-bgray-300 border text-white mr-2 my-1 data-[state=on]:bg-black data-[state=on]:text-white data-[state=on]:border-1 data-[state=on]:border-black hover:bg-black hover:border-black hover:text-white"
            key={tag}
            pressed={zodTagsArray.value.includes(tag)}
            onPressedChange={(isPressed: boolean) =>
              updateZodArray(tag, isPressed)
            }
          >
            {tag}
          </Toggle>
        ))}
      </div>
      <div className="h-0.5 bg-bgray-600 mx-1 mt-1"></div>
      <div className="pb-2">
        {productTags.spiciness.map((tag) => (
          <Toggle
            className="border-bgray-300 border text-white mr-2 mt-2 data-[state=on]:bg-black data-[state=on]:text-white data-[state=on]:border-1 data-[state=on]:border-black hover:bg-black hover:border-black hover:text-white"
            key={tag}
            pressed={zodTagsArray.value.includes(tag)}
            onPressedChange={(isPressed: boolean) =>
              updateZodArray(tag, isPressed)
            }
          >
            {tag}
          </Toggle>
        ))}
      </div>
    </div>
  );
};

export { TagsPanel };
