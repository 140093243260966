import { Admin } from "Admin";
import { CreateButton } from "../components/CreateButton";

const Header: React.FC = () => {
  return (
    <div className="flex justify-between pt-8 pb-8 text-white">
      <h1 className="text-3xl font-bold">Półprodukty</h1>
      <Admin>
        <CreateButton />
      </Admin>
    </div>
  );
};

export { Header };
