import React, { createContext, useContext, ReactNode, useState } from "react";

import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { GetOrdersQuery } from "type";

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  orders: GetOrdersQuery["orders"];
  setOrders: React.Dispatch<React.SetStateAction<GetOrdersQuery["orders"]>>;
  deliveryDate: Date | undefined;
  setDeliveryDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [orders, setOrders] = useState<GetOrdersQuery["orders"]>([]);

  const [deliveryDate, setDeliveryDate] = useState<Date | undefined>(
    new Date()
  );

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        orders,
        setOrders,
        deliveryDate,
        setDeliveryDate,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
