import { useEffect, useRef } from "react";

import { useStateContext } from "./State";
// import { PagePC } from "./pc/Page";
import { PageMobile } from "./mobile/Page";

export const Page: React.FC = () => {
  const { setIsMobile } = useStateContext();

  const mobileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkAndSetDeviceType = () => {
      const mobileElement = mobileRef.current;
      if (mobileElement) {
        const mobileStyle = window.getComputedStyle(mobileElement);
        const mobileVisible = mobileStyle.display !== "none";
        setIsMobile(mobileVisible);
      }
    };

    checkAndSetDeviceType();
    window.addEventListener("resize", checkAndSetDeviceType);
    return () => {
      window.removeEventListener("resize", checkAndSetDeviceType);
    };
  }, []);

  return (
    <>
      {/* <div className="hidden md:flex md:flex-grow">
        <PagePC />
      </div> */}
      <div
        ref={mobileRef}
        // className="flex flex-grow flex-col overflow-auto md:hidden"
        className="flex flex-grow flex-col overflow-auto"
      >
        <PageMobile />
      </div>
    </>
  );
};
