import React, { createContext, useContext, useState, ReactNode } from "react";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { Day, Week } from "./types";

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  week: Week;
  setWeek: React.Dispatch<React.SetStateAction<Week>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [week, setWeek] = useState<Week>({
    monday: {
      id: null,
      key: "monday",
      name: "Poniedziałek",
      date: "",
      quantity: null,
      items: [],
    },
    tuesday: {
      id: null,
      key: "tuesday",
      name: "Wtorek",
      date: "",
      quantity: null,
      items: [],
    },
    wednesday: {
      id: null,
      key: "wednesday",
      name: "Środa",
      date: "",
      quantity: null,
      items: [],
    },
    thursday: {
      id: null,
      key: "thursday",
      name: "Czwartek",
      date: "",
      quantity: null,
      items: [],
    },
    friday: {
      id: null,
      key: "friday",
      name: "Piątek",
      date: "",
      quantity: null,
      items: [],
    },
    toList: function (): Day[] {
      const timeData = this as Week;
      return [
        timeData[week.monday.key],
        timeData[week.tuesday.key],
        timeData[week.wednesday.key],
        timeData[week.thursday.key],
        timeData[week.friday.key],
      ];
    },
  });

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        week,
        setWeek,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
