import React, { createContext, useContext, useState, ReactNode } from "react";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";
import { useLoaderData } from "react-router";
import { GetDailyMenusQuery } from "type";

export type DailyMenu = GetDailyMenusQuery["dailyMenus"][0]["items"];
export type DailyMenuItem = GetDailyMenusQuery["dailyMenus"][0]["items"][0];

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  menu: DailyMenu;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const initialMenu = useLoaderData() as DailyMenu;

  const [menu, setMenu] = useState<DailyMenu>(initialMenu);

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        menu,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
