import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVertical } from "lucide-react";
import { cva } from "class-variance-authority";

import { Category } from "./State";
import { Delete } from "./Delete";
import { useRef } from "react";
import { Edit } from "./Edit";

export interface DragData {
  category: Category;
}

const CategoryFC: React.FC<{
  category: Category;
  isOverlay?: boolean;
  disabled: boolean;
}> = ({ category, isOverlay, disabled }) => {
  const { setNodeRef, listeners, transform, transition, isDragging } =
    useSortable({
      id: category.id,
      data: {
        category: category,
      } satisfies DragData,
    });

  const categoryRef = useRef<HTMLDivElement | null>(null);

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva(
    "flex border-b gap-1 p-2 pl-1 text-white bg-bgray-400 border-bgray-600 hover:bg-bgray-500",
    {
      variants: {
        dragging: {
          over: "opacity-30 bg-bgray-600 hover:bg-bgray-600",
          overlay: "bg-bgray-600 hover:bg-bgray-600",
        },
      },
    }
  );

  return (
    <div
      ref={(node) => {
        setNodeRef(node);
        categoryRef.current = node;
      }}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <button
        {...listeners}
        className="cursor-grab disabled:cursor-not-allowed"
        disabled={disabled}
      >
        <GripVertical />
      </button>
      <div className="flex w-full gap-2.5">
        <Edit category={category} />
        <Delete ref={categoryRef} category={category} />
      </div>
    </div>
  );
};

export { CategoryFC };
