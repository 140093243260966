import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { GetMenuPageDataQuery, GetMenuPageDataDocument } from "type";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./state/StateProvider";
import { LoaderData } from "./state/types";
import { Page } from "./Page";

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async (): Promise<LoaderData> => {
    const resposne = await queryClient.fetchQuery({
      queryKey: ["menuPageData"],
      queryFn: async () =>
        graphQLClient.request<GetMenuPageDataQuery>({
          document: GetMenuPageDataDocument,
        }),
      staleTime: 0,
    });
    return { initialProducts: resposne.initialProducts };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.Menu as string,
    loader: getLoader(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Page />
      </StateProvider>
    ),
  };
};

export { getRoute as getMenuRoute };
