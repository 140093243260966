"use client";

import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import { useEffect, useRef } from "react";

import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { useStateContext } from "../State";

import { SubHalfProductsSelect } from "./SubHalfProductsSelect";
import { IngredientsSelect } from "./IngredientsSelect";
import { Summary } from "./Summary";
import { ActionData } from "../route";
import { useDeviceContext } from "DeviceContext";
import { PC } from "PC";
import { Header } from "./Header";

const CreateForm: React.FC = () => {
  const { isMobile } = useDeviceContext();

  const actionData = useActionData() as ActionData;
  const navigate = useNavigate();
  const submit = useSubmit();

  const { form, basedOn, backRef, searchValue } = useStateContext();

  const nameInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (actionData) {
      if (actionData.errors) {
        const halfProductNameTakenError = actionData.errors.find(
          (error) => error.__typename === "HalfProductNameTakenError"
        );
        if (halfProductNameTakenError) {
          form.setError("name", {
            type: halfProductNameTakenError.__typename,
            message: `Istnieje już półprodukt o nazwie: "${
              form.getValues().name
            }".`,
          });
        }
      }
      if (actionData.id !== null) {
        const state = { searchValue: searchValue };
        navigate(
          (pathing.EditHalfProduct as string).replace(
            ":halfProductId",
            actionData.id as string
          ),
          backRef !== null
            ? {
                state: {
                  ...state,
                  backRef: backRef,
                },
              }
            : { state: state }
        );
      }
    }
  }, [actionData]);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      form.setFocus("name", { shouldSelect: true });
    }, 1000);
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    submit(values, { method: "post", encType: "application/json" });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col h-full w-full overflow-auto space-y-2"
      >
        <div className="flex flex-col flex-grow h-full w-full overflow-auto justify-between">
          <div className={"overflow-auto " + (isMobile ? "px-5" : "")}>
            <div className="max-w-xl mx-auto pb-5">
              <Header />
              {basedOn !== null && basedOn !== undefined ? (
                <div className="text-yellow-300">Na podstawie: "{basedOn}"</div>
              ) : null}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj nazwę..."
                        {...field}
                        ref={(input) => {
                          nameInputRef.current = input;
                        }}
                        className="bg-bgray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ingredients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Składniki</FormLabel>
                    <FormControl>
                      <IngredientsSelect zIngredients={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subHalfProducts"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Półprodukty</FormLabel>
                    <FormControl>
                      <SubHalfProductsSelect zSubHalfProducts={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Podsumowanie</FormLabel>
                <Summary />
              </FormItem>
            </div>
          </div>
          <div
            className={
              "relative flex w-full pr-[var(--scrollbar-width)] " +
              (isMobile ? " bg-bgray-600" : "")
            }
          >
            <div className="flex w-full max-w-xl mx-auto justify-between px-3 py-2 bg-bgray-600">
              <Button
                type="button"
                onClick={() =>
                  backRef === null || backRef === undefined
                    ? navigate(pathing.BrowseHalfProducts as string, {
                        state: { searchValue: searchValue },
                      })
                    : navigate(
                        (pathing.EditHalfProduct as string).replace(
                          ":halfProductId",
                          backRef
                        ),
                        {
                          state: { searchValue: searchValue },
                        }
                      )
                }
                className="hover:bg-black"
              >
                Wróć
              </Button>
              <Button
                type="submit"
                className="text-white bg-bgreen-100 hover:bg-bgreen-300"
              >
                Dodaj
              </Button>
              <PC>
                <div className="absolute bottom-0 right-0 h-full w-[var(--scrollbar-width)] bg-bgray-600"></div>
              </PC>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { CreateForm as Form };
