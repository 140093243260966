import { useNavigate } from "react-router-dom";

import { pathing } from "pages/customer/pathing";

import { Product, useStateContext } from "../State";

const Item: React.FC<{ product: Product }> = ({ product }) => {
  const { order, setOrder, rows, setRows, products, setProducts } =
    useStateContext();

  const updateOrder = () => {
    console.log(rows);
    console.log(products);
    const lastRow = rows[rows.length - 1];
    console.log("last row: ", lastRow);
    let insertRow;
    if (lastRow) {
      insertRow =
        products.filter((product) => product.rowId === lastRow.id).length === 3
          ? {
              id:
                "r" +
                (
                  parseInt((lastRow.id as string).replace("r", "")) + 1
                ).toString(),
            }
          : lastRow;
    } else {
      insertRow = {
        id: "r1",
      };
    }
    console.log("insert row: ", insertRow);

    if (!rows.some((row) => row.id === insertRow.id)) {
      setRows((rows) => [...rows, insertRow]);
    }

    setProducts((products) => {
      let topProduct = products.sort(
        (a, b) => parseInt(b.id as string) - parseInt(a.id as string)
      )[0];
      const topId = topProduct ? topProduct.id : "1";
      return [
        ...products,
        {
          id: (parseInt(topId as string) + 1).toString(),
          rowId: insertRow.id,
          content: product.name,
        },
      ];
    });
  };

  return (
    <li
      className="flex p-4 cursor-pointer bg-bgray-400 hover:bg-bgray-500"
      onClick={() => updateOrder()}
    >
      {product.name}
    </li>
  );
};

export { Item };
