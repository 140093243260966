import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { pathing } from "pages/staff/pathing";
import { useStateContext } from "../State";

const CopyButton: React.FC = () => {
  const navigate = useNavigate();
  const { form, searchValue } = useStateContext();

  return (
    <button
      type="button"
      className="flex items-center  pl-3 pr-3 px-4 rounded-lg bg-bgreen-100 text-white font-semibold"
      onClick={() =>
        navigate(pathing.AddHalfProduct as string, {
          state: {
            searchValue: searchValue,
            fromBrowse: null,
            fromEdit: form.getValues(),
          },
        })
      }
    >
      <FontAwesomeIcon icon={faCopy} className="pr-2" />
      Kopiuj
    </button>
  );
};

export { CopyButton };
