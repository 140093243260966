import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

interface DeviceContext {
  isMobile: boolean;
}

export const DeviceContext = createContext<DeviceContext | undefined>(
  undefined
);

export const DeviceContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const checkAndSetDeviceType = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", checkAndSetDeviceType);
    return () => {
      window.removeEventListener("resize", checkAndSetDeviceType);
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = (): DeviceContext => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error(
      "useDeviceContext must be used within a DeviceContextProvider"
    );
  }
  return context;
};
