import { GetIngredientsFilteredQuery } from "type";

import { useStateContext } from "../State";
import { Item } from "./Item";

const ItemList: React.FC =
  // <{
  //   ingredients: GetIngredientsFilteredQuery["ingredients"];
  //   areIngredientsLoading: boolean;
  // }> = ({ ingredients, areIngredientsLoading }) => {
  () => {
    const { ingredients, isLoading } = useStateContext();

    return (
      <div className="overflow-auto">
        <ul className="grid gap-y-px">
          {isLoading ? (
            <div className="text-white text-center">Ładowanie...</div>
          ) : ingredients.length === 0 ? (
            <div key={0} className="text-center text-white">
              Brak składników
            </div>
          ) : (
            ingredients.map((ingredient) => (
              <Item key={ingredient.id} ingredient={ingredient} />
            ))
          )}
        </ul>
      </div>
    );
  };

export { ItemList };
