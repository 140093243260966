import React from "react";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { useStateContext } from "../State";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "Shadcn/Tooltip";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SummaryButton: React.FC = () => {
  const { isLoading, productionItems } = useStateContext();

  const generatePdf = () => {
    const f = (number: number) =>
      number.toFixed(2).toString().replace(".", ",");
    //   number % 1 !== 0
    // ? number.toFixed(2).toString().replace(".", ",")
    // : number;

    var definition: TDocumentDefinitions = {
      content: [
        {
          table: {
            widths: ["*", "auto", "auto", "auto"],
            body: [
              [
                { text: "", noWrap: false, border: [] },
                { text: "Liczba\n(szt.)", alignment: "center" },
                { text: "Cena\n(PLN)", alignment: "center" },
                { text: "Razem\n(PLN)", alignment: "center" },
              ],
              ...productionItems.map((item) => [
                { text: item.mainProduct.name },
                { text: item.totalQuantity, alignment: "center" },
                { text: f(item.price), alignment: "center" },
                {
                  text: f(item.totalQuantity * item.price),
                  alignment: "center",
                },
              ]),
              [
                { text: "", border: [] },
                { text: "", border: [] },
                { text: "Suma:", alignment: "right", border: [] },
                {
                  text: f(
                    productionItems.reduce(
                      (sum, item) => sum + item.totalQuantity * item.price,
                      0
                    )
                  ),
                  alignment: "center",
                },
              ],
            ],
          },
        },
      ],
    };
    pdfMake.createPdf(definition).open();
  };

  return (
    <TooltipProvider delayDuration={700}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            disabled={isLoading || productionItems.length === 0}
            onClick={generatePdf}
            className="flex h-10 rounded-md items-center gap-1.5 px-2 py-2 text-sm font-semibold bg-bgray-100 hover:bg-white disabled:cursor-not-allowed disabled:opacity-25"
          >
            <FontAwesomeIcon icon={faTable} className="h-6 w-6 text-black" />
          </button>
        </TooltipTrigger>
        <TooltipContent className="text-sm text-black bg-bgray-100">
          Podsumowanie
          <TooltipArrow width={11} height={5} className="fill-bgray-100" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <button
      onClick={generatePdf}
      className="flex h-10 rounded-md items-center gap-1.5 px-2 py-2 text-sm bg-bgray-100 hover:bg-white font-semibold"
    >
      <FontAwesomeIcon icon={faTable} className="h-6 w-6 text-black" />
    </button>
  );
};

export { SummaryButton };
