import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubmit } from "react-router-dom";

import { GetOrdersQuery } from "type";

import { toLocaleISOString } from "utils/toLocaleISOString";

const Item: React.FC<{ order: GetOrdersQuery["orders"][0] }> = ({ order }) => {
  const submit = useSubmit();

  const format = (date: Date) =>
    toLocaleISOString(date).split("-").reverse().join(".");

  const deleteOrder = async () => {
    submit(
      { id: order.id },
      {
        method: "delete",
        encType: "application/json",
      }
    );
  };

  return (
    <li className="flex items-center gap-4 p-4 text-white bg-bgray-400 hover:bg-bgray-500">
      <div className="flex-1">
        <h4 className="font-semibold">
          Na dzień: {format(new Date(order.deliveryDate))}
        </h4>
        {order.dishes.length === 0 ? (
          <span className="text-sm font-bold text-red-600">
            Produkty zostały usunięte.
          </span>
        ) : (
          <div className="flex justify-between">
            <div>
              <div className="inline-block text-sm lowercase text-bgray-100">
                {order.dishes[0].mainProduct.name} x{" "}
                {order.dishes[0].mainProduct.quantity}
                {order.dishes[0].subProducts.length > 0 && ", "}
              </div>{" "}
              {order.dishes[0].subProducts.map((dish, index) => (
                <div
                  key={dish.id}
                  className="inline-block text-sm lowercase text-bgray-100"
                >
                  {dish.name} x {dish.quantity}
                  {index !== order.dishes[0].subProducts.length - 1 && ", "}
                </div>
              ))}
            </div>
            <div className="text-sm font-semibold">
              Liczba: {order.dishes[0].quantity}
            </div>
          </div>
        )}
      </div>
      <button onClick={deleteOrder} className="text-bred hover:text-red-800 ">
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </li>
  );
};

export { Item };
