import { ItemList } from "../components/ItemList";
import { DatePicker } from "../components/DatePicker";
import { Header } from "./Header";
import { SummaryButton } from "../components/SummaryButton";
import { PDFDownloadAll } from "../components/PDFDownloadAll";
import { Admin } from "Admin";

const Page: React.FC = () => {
  return (
    <div className="mx-auto px-5 pb-5 flex flex-grow max-w-3xl flex-col ">
      <Header />
      <div className="flex flex-row justify-between pb-2">
        <DatePicker />
        <div className="flex flex-row gap-1">
          <SummaryButton />
          <Admin>
            <PDFDownloadAll />
          </Admin>
        </div>
      </div>
      <ItemList />
    </div>
  );
};

export { Page as PagePC };
