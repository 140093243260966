import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

import { useLocation } from "react-router";

import { useGqlContext } from "GqlContext";

import {
  GetProductsQuery,
  GetProductsQueryVariables,
  GetProductsDocument,
} from "type";
import { X } from "lucide-react";

export type Product = GetProductsQuery["products"][0];

interface StateContext {
  searchValue: string | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  products: Product[];
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  isLoading: boolean;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { queryClient, graphQLClient } = useGqlContext();

  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    setIsLoading(true);
    queryClient.cancelQueries({ queryKey: ["productsFiltered"] }, {});
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      try {
        const products = (
          await queryClient.fetchQuery({
            queryKey: ["productsFiltered"],
            queryFn: ({ signal }) => {
              return graphQLClient.request<
                GetProductsQuery,
                GetProductsQueryVariables
              >({
                document: GetProductsDocument,
                variables: { name: searchValue ? searchValue : "" },
                signal,
              });
            },
            staleTime: 0,
          })
        ).products;
        setProducts(products);
        setIsLoading(false);
      } catch {
        const controller = new AbortController();
        controller.abort();
      }
    }, 300);
    setTimeoutId(newTimeoutId);
  }, [searchValue]);

  return (
    <StateContext.Provider
      value={{
        products,
        setProducts,
        searchValue,
        setSearchValue,
        isLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
