import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router";

import { useGqlContext } from "GqlContext";

import {
  GetHalfProductsQuery,
  GetHalfProductsQueryVariables,
  GetHalfProductsDocument,
} from "type";

export type HalfProduct = GetHalfProductsQuery["halfProducts"][0];

interface StateContext {
  searchValue: string | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  halfProducts: HalfProduct[];
  setHalfProducts: React.Dispatch<React.SetStateAction<HalfProduct[]>>;
  isLoading: boolean;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { queryClient, graphQLClient } = useGqlContext();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const [halfProducts, setHalfProducts] = useState<HalfProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    setIsLoading(true);
    queryClient.cancelQueries({ queryKey: ["halfProductsFiltered"] }, {});
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      try {
        const halfProducts = (
          await queryClient.fetchQuery({
            queryKey: ["halfProductsFiltered"],
            queryFn: ({ signal }) => {
              return graphQLClient.request<
                GetHalfProductsQuery,
                GetHalfProductsQueryVariables
              >({
                document: GetHalfProductsDocument,
                variables: { name: searchValue ? searchValue : "" },
                signal,
              });
            },
            staleTime: 0,
          })
        ).halfProducts;
        setHalfProducts(halfProducts);
        setIsLoading(false);
      } catch {
        const controller = new AbortController();
        controller.abort();
      }
    }, 300);
    setTimeoutId(newTimeoutId);
  }, [searchValue]);

  return (
    <StateContext.Provider
      value={{
        halfProducts,
        setHalfProducts,
        searchValue,
        setSearchValue,
        isLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
