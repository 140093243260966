import React from "react";
import { Header } from "./Header";
import { SearchInput } from "../components/SearchInput";
import { ItemList } from "../components/ItemList";

const Page: React.FC = () => {
  return (
    <div className="flex flex-col flex-grow h-full w-full max-w-xl mx-auto px-5 pb-3">
      <Header />
      <SearchInput />
      <ItemList />
    </div>
  );
};

export { Page as PageMobile };
