import { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  useSensor,
  useSensors,
  KeyboardSensor,
  Announcements,
  UniqueIdentifier,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
// import { type Task, TaskCard } from "./TaskCard";
// import type { Column } from "./BoardColumn";
import { hasDraggableData } from "./utils";
import { coordinateGetter } from "./multipleContainersKeyboardPreset";
import { BoardContainer, BoardRow } from "./Row";
// import { ColumnId, defaultCols, initialTasks } from "./extra";

import { Row, useStateContext } from "../State";
import { ProductCard } from "./Product";
import { DragProduct } from "../State";

const Drag: React.FC = () => {
  //   const [columns, setColumns] = useState<Column[]>(defaultCols);
  const { rows, setRows } = useStateContext();

  // const pickedUpTaskColumn = useRef<ColumnId | null>(null);
  const pickedUpProduct = useRef<string | null>(null);
  const pickedUpRow = useRef<string | null>(null);
  //   const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  // const rowIds = useMemo(() => rows.map((row) => row.id), [rows]);

  //   const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const { products, setProducts } = useStateContext();

  //   const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeRow, setActiveRow] = useState<Row | null>(null);

  //   const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [activeProduct, setActiveProduct] = useState<DragProduct | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    })
  );

  // function getDraggingTaskData(taskId: UniqueIdentifier, columnId: ColumnId) {
  function getDraggingTaskData(
    rowId: UniqueIdentifier,
    productId: UniqueIdentifier
  ) {
    // const tasksInColumn = tasks.filter((task) => task.columnId === rowId);
    const productsInRow = products.filter((product) => product.rowId === rowId);
    // const taskPosition = tasksInColumn.findIndex((task) => task.id === taskId);
    const productPosition = productsInRow.findIndex(
      (product) => product.id === productId
    );
    const row = rows.find((row) => row.id === rowId);
    return {
      // tasksInColumn,
      productsInRow,
      // taskPosition,
      productPosition,
      row,
    };
  }

  // const announcements: Announcements = {
  //   onDragStart({ active }) {
  //     if (!hasDraggableData(active)) return;
  //     if (active.data.current?.type === "Column") {
  //       const startColumnIdx = columnsId.findIndex((id) => id === active.id);
  //       const startColumn = columns[startColumnIdx];
  //       return `Picked up Column ${startColumn?.title} at position: ${
  //         startColumnIdx + 1
  //       } of ${columnsId.length}`;
  //     } else if (active.data.current?.type === "Task") {
  //       pickedUpTaskColumn.current = active.data.current.task.columnId;
  //       const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
  //         active.id,
  //         pickedUpTaskColumn.current
  //       );
  //       return `Picked up Task ${
  //         active.data.current.task.content
  //       } at position: ${taskPosition + 1} of ${
  //         tasksInColumn.length
  //       } in column ${column?.title}`;
  //     }
  //   },
  //   onDragOver({ active, over }) {
  //     if (!hasDraggableData(active) || !hasDraggableData(over)) return;

  //     if (
  //       active.data.current?.type === "Column" &&
  //       over.data.current?.type === "Column"
  //     ) {
  //       const overColumnIdx = columnsId.findIndex((id) => id === over.id);
  //       return `Column ${active.data.current.column.title} was moved over ${
  //         over.data.current.column.title
  //       } at position ${overColumnIdx + 1} of ${columnsId.length}`;
  //     } else if (
  //       active.data.current?.type === "Task" &&
  //       over.data.current?.type === "Task"
  //     ) {
  //       const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
  //         over.id,
  //         over.data.current.task.columnId
  //       );
  //       if (over.data.current.task.columnId !== pickedUpTaskColumn.current) {
  //         return `Task ${
  //           active.data.current.task.content
  //         } was moved over column ${column?.title} in position ${
  //           taskPosition + 1
  //         } of ${tasksInColumn.length}`;
  //       }
  //       return `Task was moved over position ${taskPosition + 1} of ${
  //         tasksInColumn.length
  //       } in column ${column?.title}`;
  //     }
  //   },
  //   onDragEnd({ active, over }) {
  //     if (!hasDraggableData(active) || !hasDraggableData(over)) {
  //       pickedUpTaskColumn.current = null;
  //       return;
  //     }
  //     if (
  //       active.data.current?.type === "Column" &&
  //       over.data.current?.type === "Column"
  //     ) {
  //       const overColumnPosition = columnsId.findIndex((id) => id === over.id);

  //       return `Column ${
  //         active.data.current.column.title
  //       } was dropped into position ${overColumnPosition + 1} of ${
  //         columnsId.length
  //       }`;
  //     } else if (
  //       active.data.current?.type === "Task" &&
  //       over.data.current?.type === "Task"
  //     ) {
  //       const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
  //         over.id,
  //         over.data.current.task.columnId
  //       );
  //       if (over.data.current.task.columnId !== pickedUpTaskColumn.current) {
  //         return `Task was dropped into column ${column?.title} in position ${
  //           taskPosition + 1
  //         } of ${tasksInColumn.length}`;
  //       }
  //       return `Task was dropped into position ${taskPosition + 1} of ${
  //         tasksInColumn.length
  //       } in column ${column?.title}`;
  //     }
  //     pickedUpTaskColumn.current = null;
  //   },
  //   onDragCancel({ active }) {
  //     pickedUpTaskColumn.current = null;
  //     if (!hasDraggableData(active)) return;
  //     return `Dragging ${active.data.current?.type} cancelled.`;
  //   },
  // };

  function onDragStart(event: DragStartEvent) {
    console.log("DRAG START");
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;
    if (data?.type === "Row") {
      setActiveRow(data.row);
      return;
    }
    if (data?.type === "Product") {
      setActiveProduct(data.product);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveRow(null);
    setActiveProduct(null);
    console.log("DRAG END");
    const { active, over } = event;
    if (!over) return;
    const activeId = active.id;
    const overId = over.id;
    if (!hasDraggableData(active)) return;
    const activeData = active.data.current;
    if (activeId === overId) return;
    const isActiveARow = activeData?.type === "Row";
    if (!isActiveARow) return;
    setRows((rows) => {
      const activeRowIndex = rows.findIndex((col) => col.id === activeId);
      const overRowIndex = rows.findIndex((col) => col.id === overId);
      return arrayMove(rows, activeRowIndex, overRowIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;
    const activeId = active.id;
    const overId = over.id;
    if (activeId === overId) return;
    if (!hasDraggableData(active) || !hasDraggableData(over)) return;
    const activeData = active.data.current;
    const overData = over.data.current;
    const isActiveAProduct = activeData?.type === "Product";
    const isOverAProduct = overData?.type === "Product";
    if (!isActiveAProduct) return;

    // Im dropping a Task over another Task
    if (isActiveAProduct && isOverAProduct) {
      console.log("OVER PRODUCT");
      setProducts((products) => {
        const activeIndex = products.findIndex((p) => p.id === activeId);
        const overIndex = products.findIndex((p) => p.id === overId);
        const activeProduct = products[activeIndex];
        const overProduct = products[overIndex];
        if (
          activeProduct &&
          overProduct &&
          activeProduct.rowId !== overProduct.rowId
        ) {
          activeProduct.rowId = overProduct.rowId;
          return arrayMove(products, activeIndex, overIndex - 1);
        }
        return arrayMove(products, activeIndex, overIndex);
      });
    }
    const isOverARow = overData?.type === "Row";
    // Im dropping a Task over a row
    if (isActiveAProduct && isOverARow) {
      console.log("OVER ROW");
      setProducts((products) => {
        const activeIndex = products.findIndex((p) => p.id === activeId);
        const activeProduct = products[activeIndex];
        if (activeProduct) {
          activeProduct.rowId = overId as UniqueIdentifier;
          return arrayMove(products, activeIndex, activeIndex);
        }
        return products;
      });
    }
  }

  return (
    <DndContext
      //   accessibility={{
      //     announcements,
      //   }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <BoardContainer>
        {/* <div className="flex flex-col gap-2"> */}
        <SortableContext items={rows}>
          {rows.map((item) => (
            // <div className="p-2 border border-black">{item.id}</div>
            <BoardRow
              key={item.id}
              row={item}
              products={products.filter((product) => product.rowId === item.id)}
            />
            // <BoardColumn
            //   key={item.id}
            //   column={item}
            //   tasks={tasks.filter((task) => task.columnId === item.id)}
            // />
          ))}
        </SortableContext>
        {/* </div> */}
      </BoardContainer>

      {"document" in window &&
        createPortal(
          <DragOverlay>
            {activeRow && (
              <BoardRow
                isOverlay
                row={activeRow}
                products={products.filter(
                  (product) => product.rowId === activeRow.id
                )}
              />
            )}
            {/* {activeTask && <TaskCard task={activeTask} isOverlay />} */}
            {activeProduct && <ProductCard product={activeProduct} isOverlay />}
          </DragOverlay>,
          document.body
        )}
    </DndContext>
  );
};

export { Drag };
