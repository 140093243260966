// import { CreateButton } from "../components/CreateButton";

const Header: React.FC = () => {
  return (
    <div className="flex justify-between pt-7 pb-4 text-white">
      <h1 className="text-2xl font-bold">Złóż zamówienie</h1>
      {/* <CreateButton /> */}
    </div>
  );
};

export { Header };
