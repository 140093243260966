import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { createContext, useContext, ReactNode } from "react";

import { GRAPHQL_URL } from "./env";

import { GraphQLClient } from "../node_modules/graphql-request/build/entrypoints/main";

interface GqlContext {
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
}

export const GqlContext = createContext<GqlContext | undefined>(undefined);

export const GqlProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queryClient = new QueryClient();
  const graphQLClient = new GraphQLClient(GRAPHQL_URL, {
    credentials: `include`,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <GqlContext.Provider value={{ queryClient, graphQLClient }}>
        {children}
      </GqlContext.Provider>
    </QueryClientProvider>
  );
};

export const useGqlContext = (): GqlContext => {
  const context = useContext(GqlContext);
  if (!context) {
    throw new Error("useGqlContext must be used within a GqlProvider");
  }
  return context;
};
