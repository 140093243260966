import React, { useCallback, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData } from "react-router";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { Input } from "Shadcn/Input";
import { Popover, PopoverContent, PopoverTrigger } from "Shadcn/Popover";

import {
  GetCreateOrderPageDataQuery,
  GetProductsForOrderCreationDocument,
  GetProductsForOrderCreationQuery,
  GetProductsForOrderCreationQueryVariables,
} from "type";

import { formSchema } from "../formSchema";

import { useStateContext } from "../State";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "Shadcn/Tooltip";
import { ActionData } from "../route";

// Why is the main

const Select: React.FC<{
  zMainProduct: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "mainProduct"
  >;
}> = ({ zMainProduct }) => {
  const nameInputRef = useRef<HTMLInputElement | null>(null);

  const actionData = useActionData() as ActionData;
  const { queryClient, graphQLClient } = useStateContext();

  type SelectProduct = GetCreateOrderPageDataQuery["allProducts"][0];
  const allProducts = useLoaderData() as SelectProduct[];

  const [products, setProducts] = useState<SelectProduct[]>(allProducts);

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [inputValue, setInputValue] = useState<string>(
    zMainProduct.value ? zMainProduct.value.name : ""
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  useEffect(() => {
    if (actionData) {
      setInputValue("");
    }
  }, [actionData]);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      return;
    }
    if (isOpen) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < products.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : products.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < products.length) {
          handleSelect(products[highlightedIndex]);
        }
      }
      return;
    }
    if (["ArrowDown", "Enter", "ArrowUp"].some((key) => key === event.key)) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  const refetch = useCallback(
    async (value: string) => {
      queryClient.cancelQueries({ queryKey: ["menuItemProducts"] }, {});

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(async () => {
        try {
          const products = (
            await queryClient.fetchQuery({
              queryKey: ["menuItemProducts"],
              queryFn: ({ signal }) => {
                return graphQLClient.request<
                  GetProductsForOrderCreationQuery,
                  GetProductsForOrderCreationQueryVariables
                >({
                  document: GetProductsForOrderCreationDocument,
                  variables: {
                    where: {
                      name: { contains: value.trim() },
                    },
                  },
                  signal,
                });
              },
              staleTime: 0,
            })
          ).products;
          setProducts(products);
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 200);

      setTimeoutId(newTimeoutId);
    },
    [timeoutId]
  );

  const selectProduct = (product: SelectProduct) => {
    zMainProduct.onChange({
      ...product,
      quantity: 1,
      details: {
        price: product.price,
        weight: product.weight,
        kilocalories: product.kilocalories,
        fat: product.fat,
        saturatedFat: product.saturatedFat,
        carbohydrates: product.carbohydrates,
        sugars: product.sugars,
        fiber: product.fiber,
        protein: product.protein,
        salt: product.salt,
      },
    });
  };

  const handleSelect = (product: SelectProduct) => {
    setInputValue(product.name);
    selectProduct(product);
    setIsOpen(false);
  };

  useEffect(() => {
    refetch(inputValue);
  }, [zMainProduct.value]);

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    refetch(value);
    setIsOpen(true);
  };

  const onChange = (value: string) => {
    if (value.length === 2) {
      value = value[0] + value[1].replace(/[^1-9]/g, "");
      if (value.length === 2) {
        value = value[1];
      }
    } else {
      value = value.replace(/[^1-9]/g, "");
    }
    zMainProduct.onChange({
      ...zMainProduct.value,
      quantity: parseInt(value ? value : "0"),
    });
  };

  return (
    <div className="flex w-full items-center">
      <Popover open={isOpen} onOpenChange={() => setHighlightedIndex(0)}>
        <PopoverTrigger asChild>
          <Input
            ref={nameInputRef}
            type="text"
            className="bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black"
            placeholder="Wybierz produkt główny..."
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            onBlur={() => setIsOpen(false)}
            onChange={handleInputChange}
            value={inputValue}
          />
        </PopoverTrigger>
        <PopoverContent
          className="w-[--radix-popover-trigger-width] rouned-none top-0 p-0"
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <div className="flex flex-col max-h-[256px] overflow-auto">
            {products.length > 0 ? (
              products.map((product, index) => (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        key={product.id}
                        className={`text-left p-1 hover:bg-bgray-100 ${
                          highlightedIndex === index ? "bg-bgray-100" : ""
                        }`}
                        onMouseEnter={() => setHighlightedIndex(index)}
                        onClick={() => handleSelect(product)}
                      >
                        {product.name}
                      </button>
                    </TooltipTrigger>
                    {product.ingredientNames.length > 0 ? (
                      <TooltipContent
                        side="right"
                        align="start"
                        sideOffset={16}
                        className="bg-bgray-100 z-50"
                      >
                        {product.ingredientNames.map((name) => (
                          <p className="text-base">{name}</p>
                        ))}
                      </TooltipContent>
                    ) : null}
                  </Tooltip>
                </TooltipProvider>
              ))
            ) : (
              <div className="text-center p-1">Brak produktów</div>
            )}
          </div>
        </PopoverContent>
      </Popover>{" "}
      <Input
        className="w-6 h-8 rounded-none text-center p-0 font-medium ml-1 text-white bg-bgray-200 border-bgray-300  focus-visible:ring-0 focus-visible:outline-0 placeholder:text-white"
        placeholder="..."
        disabled={zMainProduct.value ? false : true}
        value={
          zMainProduct.value
            ? zMainProduct.value.quantity
              ? zMainProduct.value.quantity
              : ""
            : ""
        }
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};

export { Select as MainProductSelect };
