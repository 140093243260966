import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import {
  AddIngredientMutation,
  AddIngredientMutationVariables,
  AddIngredientDocument,
} from "type";

import { pathing } from "pages/staff/pathing";
import { Form } from "./components/Form";

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }) => {
    const ingredientFormData = await request.json();
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["ingredients"],
        queryFn: async () =>
          graphQLClient.request<
            AddIngredientMutation,
            AddIngredientMutationVariables
          >({
            document: AddIngredientDocument,
            variables: {
              addInput: {
                name: ingredientFormData.name,
                kilocalories: ingredientFormData.kilocalories,
                fat: ingredientFormData.fat,
                saturatedFat: ingredientFormData.saturatedFat,
                carbohydrates: ingredientFormData.carbohydrates,
                sugars: ingredientFormData.sugars,
                fiber: ingredientFormData.fiber,
                protein: ingredientFormData.protein,
                salt: ingredientFormData.salt,
                details: ingredientFormData.details,
                description: ingredientFormData.description,
              },
            },
          }),
        staleTime: 0,
      })
    ).ingredient.add;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "IngredientNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
      }
      return { error: error };
    }
    return { error: null, searchValue: ingredientFormData.searchValue };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.AddIngredient as string,
    action: getAction(queryClient, graphQLClient),
    element: <Form />,
  };
};

export { getRoute as getAddIngredientRoute };
