import { z } from "zod";

const requiredError = "Pole wymagane.";
const invalidValue = "Nieprawidłowa wartość.";

export const preprocess = (val: unknown) => {
  if (typeof val === "string") {
    let value;
    value = val.replace(",", ".");
    return parseFloat(parseFloat(value).toFixed(1));
  }
  return val;
};

export const preprocessSalt = (val: unknown) => {
  if (typeof val === "string") {
    let value;
    value = val.replace(",", ".");
    return parseFloat(parseFloat(value).toFixed(2));
  }
  return val;
};
export const formSchema = z.object({
  name: z
    .string({ required_error: requiredError })
    .min(2, { message: "Nazwa musi zawierać conajmniej 2 litery." })
    .regex(/^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s0-9-(),]+$/, {
      message: "Niedozwolone znaki.",
    }),
  kilocalories: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .nonnegative()
      .gte(0, {
        message: invalidValue,
      })
  ),
  fat: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  saturatedFat: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  carbohydrates: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  sugars: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .nonnegative()
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  fiber: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: invalidValue,
      })
      .nonnegative()
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  protein: z.preprocess(
    preprocess,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: requiredError,
      })
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  salt: z.preprocess(
    preprocessSalt,
    z
      .number({
        required_error: requiredError,
        invalid_type_error: requiredError,
      })
      .gte(0, {
        message: invalidValue,
      })
      .lte(100, {
        message: invalidValue,
      })
  ),
  details: z.string().optional(),
  description: z.string(),
});
