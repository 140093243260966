import React, { ReactNode } from "react";

import { useAuthContext } from "AuthContext";

import { Role, StaffMember } from "type";

interface RequireProps {
  children: ReactNode;
}

const Require: React.FC<
  RequireProps & Partial<Record<keyof typeof Role, boolean>>
> = ({ children, ...roles }) => {
  const { user } = useAuthContext();

  if (user === undefined) return null;
  if (user.__typename !== "StaffMember") return null;

  const staffMember = user as StaffMember;
  const requiredRoles = Object.keys(roles).map(
    (role) => Role[role as keyof typeof Role]
  );

  return <>{requiredRoles.includes(staffMember.role) ? children : null}</>;
};

export { Require };
