import React, { useCallback, useEffect, useState } from "react";
import { useLoaderData } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  GetProductsForMenuItemQuery,
  GetProductsForMenuItemDocument,
  GetProductsForMenuItemQueryVariables,
  DeleteSubProductListMutation,
  DeleteSubProductListMutationVariables,
  DeleteSubProductListDocument,
  AddProductToSubProductListMutation,
  AddProductToSubProductListMutationVariables,
  AddProductToSubProductListDocument,
  RemoveProductFromSubProductListMutation,
  RemoveProductFromSubProductListMutationVariables,
  RemoveProductFromSubProductListDocument,
} from "type";

import { Input } from "Shadcn/Input";
import { Popover, PopoverContent, PopoverTrigger } from "Shadcn/Popover";

import { LoaderData, Product } from "../state/types";
import { SubProductList } from "../state/types";
import { useStateContext } from "../state/StateProvider";

const ComboBox: React.FC<{
  subProductList: SubProductList;
  productsUpdateComplete: boolean;
  setProductsUpdateComplete: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  subProductList,
  productsUpdateComplete,
  setProductsUpdateComplete,
}) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const { initialProducts } = useLoaderData() as LoaderData;
  const [products, setProducts] = useState<Product[]>(
    initialProducts.filter(
      (initialProduct) =>
        !subProductList.products.some(
          (existingProduct) => initialProduct.id === existingProduct.id
        )
    )
  );
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      return;
    }
    if (isOpen) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < products.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : products.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < products.length) {
          handleSelect(products[highlightedIndex]);
        }
      }
      return;
    }
    if (["ArrowDown", "Enter", "ArrowUp"].some((key) => key === event.key)) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  const refetch = useCallback(
    async (value: string) => {
      setInputValue(value);

      queryClient.cancelQueries({ queryKey: ["menuItemProducts"] }, {});

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(async () => {
        try {
          const products = (
            await queryClient.fetchQuery({
              queryKey: ["menuItemProducts"],
              queryFn: ({ signal }) => {
                return graphQLClient.request<
                  GetProductsForMenuItemQuery,
                  GetProductsForMenuItemQueryVariables
                >({
                  document: GetProductsForMenuItemDocument,
                  variables: {
                    where: {
                      and: [
                        {
                          name: { contains: value.trim() },
                          id: {
                            nin: subProductList.products.map(
                              (product) => product.id
                            ),
                          },
                        },
                      ],
                    },
                  },
                  signal,
                });
              },
              staleTime: 0,
            })
          ).products;
          setProducts(products);
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 200);

      setTimeoutId(newTimeoutId);
    },
    [timeoutId, subProductList.products]
  );

  const addSubProduct = (product: Product) => {
    queryClient
      .fetchQuery({
        queryKey: ["addProductToSubProductList"],
        queryFn: async () =>
          graphQLClient.request<
            AddProductToSubProductListMutation,
            AddProductToSubProductListMutationVariables
          >({
            document: AddProductToSubProductListDocument,
            variables: {
              subProductListId: subProductList.id,
              productId: product.id,
            },
          }),
        staleTime: 0,
      })
      .then(() => {
        const dayKey = subProductList.dayKey;
        const dayOfWeek = week[dayKey];
        const itemIndex = dayOfWeek.items.findIndex(
          (_item) => _item.id === subProductList.itemId
        );
        const item = dayOfWeek.items[itemIndex];
        const subProductListIndex = item.subProductLists.findIndex(
          (list) => list.id === subProductList.id
        );
        const updatedProducts = [...subProductList.products, product];
        const updatedSubProductList = {
          ...subProductList,
          products: updatedProducts,
        };
        const updatedSubProductLists = [
          ...item.subProductLists.slice(0, subProductListIndex),
          updatedSubProductList,
          ...item.subProductLists.slice(subProductListIndex + 1),
        ];
        const updatedItems = [
          ...dayOfWeek.items.slice(0, itemIndex),
          {
            ...item,
            subProductLists: updatedSubProductLists,
          },
          ...dayOfWeek.items.slice(itemIndex + 1),
        ];
        setWeek({
          ...week,
          [dayKey]: {
            ...dayOfWeek,
            items: updatedItems,
          },
        });
        setProductsUpdateComplete(true);
      });
  };

  useEffect(() => {
    if (productsUpdateComplete) {
      refetch("");
      setProductsUpdateComplete(false); // Reset the flag
    }
  }, [productsUpdateComplete, refetch]);

  const handleSelect = (product: Product) => {
    setInputValue("");
    addSubProduct(product);
    refetch("");
    setIsOpen(false);
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refetch(event.target.value);
    setIsOpen(true);
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setHighlightedIndex(0)}>
      <PopoverTrigger asChild>
        <Input
          type="text"
          className="w-full rounded-none border-none h-fit px-3 py-2 bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black"
          placeholder="Wybierz produkt..."
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={() => setIsOpen(false)}
          onChange={handleInputChange}
          value={inputValue}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[--radix-popover-trigger-width] rouned-none p-0"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div className="flex flex-col ">
          {products.length > 0 ? (
            products.map((product, index) => (
              <button
                key={product.id}
                className={`text-left p-1 hover:bg-gray-200 ${
                  highlightedIndex === index ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  handleSelect(product);
                }}
              >
                {product.name}
              </button>
            ))
          ) : (
            <div className="text-center p-1">Brak produktów</div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const SubProductListComponent: React.FC<{
  index: number;
  subProductList: SubProductList;
}> = ({ index, subProductList }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const [productsUpdateComplete, setProductsUpdateComplete] =
    useState<boolean>(false);

  const deleteSubProductList = () => {
    queryClient
      .fetchQuery({
        queryKey: ["deleteSubProductList"],
        queryFn: async () =>
          graphQLClient.request<
            DeleteSubProductListMutation,
            DeleteSubProductListMutationVariables
          >({
            document: DeleteSubProductListDocument,
            variables: {
              id: subProductList.id,
            },
          }),
        staleTime: 0,
      })
      .then(() => {
        const dayKey = subProductList.dayKey;
        const dayOfWeek = week[dayKey];
        const itemIndex = dayOfWeek.items.findIndex(
          (_item) => _item.id === subProductList.itemId
        );
        const item = dayOfWeek.items[itemIndex];
        const updatedSubProductLists = item.subProductLists.filter(
          (list) => list.id !== subProductList.id
        );
        const updatedItems = [
          ...dayOfWeek.items.slice(0, itemIndex),
          {
            ...item,
            subProductLists: updatedSubProductLists,
          },
          ...dayOfWeek.items.slice(itemIndex + 1),
        ];
        setWeek({
          ...week,
          [dayKey]: {
            ...dayOfWeek,
            items: updatedItems,
          },
        });
      });
  };

  const removeProduct = (productToRemove: Product) => {
    queryClient
      .fetchQuery({
        queryKey: ["removeProductFromSubProductList"],
        queryFn: async () =>
          graphQLClient.request<
            RemoveProductFromSubProductListMutation,
            RemoveProductFromSubProductListMutationVariables
          >({
            document: RemoveProductFromSubProductListDocument,
            variables: {
              subProductListId: subProductList.id,
              productId: productToRemove.id,
            },
          }),
        staleTime: 0,
      })
      .then(() => {
        const dayKey = subProductList.dayKey;
        const dayOfWeek = week[dayKey];
        const itemIndex = dayOfWeek.items.findIndex(
          (_item) => _item.id === subProductList.itemId
        );
        const item = dayOfWeek.items[itemIndex];
        const subProductListIndex = item.subProductLists.findIndex(
          (list) => list.id === subProductList.id
        );
        const updatedProducts = subProductList.products.filter(
          (product) => product.id !== productToRemove.id
        );
        const updatedSubProductList = {
          ...subProductList,
          products: updatedProducts,
        };
        const updatedSubProductLists = [
          ...item.subProductLists.slice(0, subProductListIndex),
          updatedSubProductList,
          ...item.subProductLists.slice(subProductListIndex + 1),
        ];
        const updatedItems = [
          ...dayOfWeek.items.slice(0, itemIndex),
          {
            ...item,
            subProductLists: updatedSubProductLists,
          },
          ...dayOfWeek.items.slice(itemIndex + 1),
        ];
        setWeek({
          ...week,
          [dayKey]: {
            ...dayOfWeek,
            items: updatedItems,
          },
        });
        setProductsUpdateComplete(true);
      });
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <div className="text-white pr-1">{index}.</div>
        <ComboBox
          subProductList={subProductList}
          productsUpdateComplete={productsUpdateComplete}
          setProductsUpdateComplete={setProductsUpdateComplete}
        />
        <button
          className="flex top-2 right-2 h-fit p-2 text-bred hover:text-red-800"
          onClick={deleteSubProductList}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div className="flex flex-col">
        {subProductList.products.map((product) => (
          <div className="flex flex-col pl-5 mt-1" key={product.id}>
            <div className="flex justify-between items-center pl-3 font-medium text-white bg-bgray-400">
              {product.name}
              <button
                className="flex top-2 right-2 h-fit p-2 text-bred hover:text-red-800 "
                onClick={() => {
                  removeProduct(product);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { SubProductListComponent as SubProductList };
