import React, { createContext, useContext, ReactNode, useState } from "react";
import { useLocation } from "react-router";
import { QueryClient } from "@tanstack/react-query";
import { useForm, UseFormReturn } from "react-hook-form";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { GetHalfProductsQuery } from "type";

import { formSchema, HalfProduct, Ingredient } from "./formSchema";
import { CopyState } from "../copyState";

export type HalfProduct = GetHalfProductsQuery["halfProducts"][0];

type Defaults = {
  name: string | undefined;
  ingredients: z.infer<typeof Ingredient>[];
  subHalfProducts: z.infer<typeof HalfProduct>[];
};

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  form: UseFormReturn<z.infer<typeof formSchema>>;
  basedOn: string | null;
  backRef: string | null;
  searchValue: string | undefined;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const location = useLocation();

  const getBasedOn = (
    state: CopyState
  ): { name: string; id: string } | null => {
    if (state.fromBrowse) {
      const halfProduct = state.fromBrowse as HalfProduct;
      return { name: halfProduct.name, id: halfProduct.id };
    } else if (state.fromEdit) {
      const halfProduct = state.fromEdit as z.infer<typeof formSchema> & {
        id: string;
      };
      return { name: halfProduct.name, id: halfProduct.id };
    } else {
      return null;
    }
  };
  const [basedOn] = useState<string | null>(
    location.state ? getBasedOn(location.state as CopyState)?.name! : null
  );
  const [backRef] = useState<string | null>(
    location.state ? getBasedOn(location.state as CopyState)?.id! : null
  );
  const [searchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const getDefaults = (state: CopyState): Defaults => {
    if (state.fromBrowse) {
      const halfProduct = state.fromBrowse as HalfProduct;
      return {
        name: halfProduct.name,
        ingredients: halfProduct.ingredients.map((ingredient) => ({
          id: ingredient.id,
          name: ingredient.name,
          weight: ingredient.weight,
          details: {
            kilocalories: ingredient.kilocalories,
            fat: ingredient.fat,
            saturatedFat: ingredient.saturatedFat,
            carbohydrates: ingredient.carbohydrates,
            sugars: ingredient.sugars,
            fiber: ingredient.fiber,
            protein: ingredient.protein,
            salt: ingredient.salt,
          },
        })),
        subHalfProducts: halfProduct.subHalfProducts.map((halfProduct) => ({
          id: halfProduct.id,
          name: halfProduct.name,
          weight: halfProduct.weight,
          details: {
            kilocalories: halfProduct.kilocalories,
            fat: halfProduct.fat,
            saturatedFat: halfProduct.saturatedFat,
            carbohydrates: halfProduct.carbohydrates,
            sugars: halfProduct.sugars,
            fiber: halfProduct.fiber,
            protein: halfProduct.protein,
            salt: halfProduct.salt,
          },
        })),
      };
    } else if (state.fromEdit) {
      const halfProduct = state.fromEdit as z.infer<typeof formSchema>;
      return halfProduct;
    } else {
      return {
        name: undefined,
        ingredients: [],
        subHalfProducts: [],
      };
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: location.state
      ? getDefaults(location.state as CopyState)
      : {
          name: undefined,
          ingredients: [],
          subHalfProducts: [],
        },
  });

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        form,
        basedOn,
        backRef,
        searchValue,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
