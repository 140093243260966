import { useGqlContext } from "GqlContext";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { GetCategoriesDocument, GetCategoriesQuery } from "type";

export type Category = GetCategoriesQuery["categories"][0];

interface StateContext {
  isLoading: boolean;
  categories: Category[];
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { queryClient, graphQLClient } = useGqlContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    setIsLoading(true);
    queryClient
      .fetchQuery({
        queryKey: ["categories"],
        queryFn: async () => {
          return graphQLClient.request<GetCategoriesQuery>({
            document: GetCategoriesDocument,
          });
        },
        staleTime: 0,
      })
      .then((result) => {
        setCategories(result.categories);
        setIsLoading(false);
      });
  }, []);

  return (
    <StateContext.Provider
      value={{
        isLoading,
        categories,
        setCategories,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
