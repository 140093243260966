import React from "react";
import { Outlet } from "react-router-dom";

import { SideMenu } from "../components/SideMenu";
import { Require } from "Require";
import { useAuthContext } from "AuthContext";
import { Role } from "type";

const Root: React.FC = () => {
  const { user } = useAuthContext();

  return (
    <div className="h-dvh w-dvw flex flex-row">
      <SideMenu />
      <div className="relative flex flex-grow bg-bgray-500">
        <Outlet />
      </div>
    </div>
  );
};

export { Root as RootPC };
