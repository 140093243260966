import React from "react";
import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar";

const Root: React.FC = () => {
  const navbarHeight = "16rem";

  return (
    <div className="h-dvh flex flex-col">
      <Navbar />
      <div className="flex flex-grow flex-col overflow-auto bg-bgray-500">
        <Outlet />
      </div>
    </div>
  );
};

export { Root as RootMobile };
