import React from "react";

import { ProductTree, useStateContext } from "./State";
import { Button } from "Shadcn/Button";
import { useNavigate } from "react-router";
import { pathing } from "pages/staff/pathing";
import { useDeviceContext } from "DeviceContext";
import { ProductTreeHalfProduct, ProductTreeIngredient } from "type";

type HalfProduct = Omit<
  ProductTreeHalfProduct,
  "ingredients" | "halfProducts"
> & {
  weightFull: number;
  ingredients: Array<ProductTreeIngredient & { weightFull: number }>;
  halfProducts: Array<ProductTreeHalfProduct & { weightFull: number }>;
};

const Ingredients: React.FC<{
  ingredients: ProductTree["ingredients"];
}> = ({ ingredients }) => {
  const { quantity } = useStateContext();
  return (
    <>
      {ingredients && ingredients.length > 0 ? (
        <span className="font-medium text-bgray-100">składniki:</span>
      ) : null}
      {ingredients?.map((ingredient) => (
        <div key={ingredient.id} className="ml-4 text-bgray-100">
          {ingredient.name}, {ingredient.weight}g
          {quantity > 1 ? ` (${ingredient.weight * quantity}g)` : null}
        </div>
      ))}
    </>
  );
};

const HalfProducts: React.FC<{
  halfProducts: ProductTree["halfProducts"];
}> = ({ halfProducts }) => {
  const { quantity } = useStateContext();

  const f = (weight: number) => (weight % 1 !== 0 ? weight.toFixed(1) : weight);

  return (
    <>
      {halfProducts && halfProducts.length > 0 ? (
        <span className="font-medium text-bgray-100">pólprodukty:</span>
      ) : null}
      <div className="ml-4">
        {halfProducts
          ?.map((halfProduct) => {
            const totalIngredientsWeight =
              halfProduct?.ingredients?.reduce((sum, ingredient) => {
                return sum + ingredient.weight;
              }, 0) || 0;

            const totalHalfProductsWeight =
              halfProduct?.halfProducts?.reduce((sum, halfProduct) => {
                return sum + halfProduct.weight;
              }, 0) || 0;

            const total = totalIngredientsWeight + totalHalfProductsWeight;

            return {
              ...halfProduct,
              halfProducts: halfProduct.halfProducts.map((subHalfProduct) => ({
                ...subHalfProduct,
                weight: Math.round(
                  (subHalfProduct.weight / total) * halfProduct.weight
                ),
                weightFull:
                  (subHalfProduct.weight / total) * halfProduct.weight,
              })),
              ingredients: halfProduct.ingredients.map((ingredient) => ({
                ...ingredient,
                weight: Math.round(
                  (ingredient.weight / total) * halfProduct.weight
                ),
                weightFull: (ingredient.weight / total) * halfProduct.weight,
              })),
              weightFull:
                "weightFull" in halfProduct
                  ? halfProduct.weightFull
                  : halfProduct.weight,
            } as HalfProduct;
          })
          .map((halfProduct: HalfProduct) => (
            <div key={halfProduct.id}>
              <span className="text-lg font-bold">{halfProduct.name}</span>
              {`, ${f(halfProduct.weightFull)}g`}
              {quantity > 1
                ? ` (${f(halfProduct.weightFull * quantity)}g)`
                : null}
              <br />
              {halfProduct.ingredients && halfProduct.ingredients.length > 0 ? (
                <span className="font-medium text-bgray-100">składniki:</span>
              ) : null}
              {halfProduct.ingredients.map((ingredient) => (
                <div className="ml-4 text-bgray-100">
                  {ingredient.name}, {f(ingredient.weightFull)}g
                  {quantity > 1
                    ? ` (${f(ingredient.weightFull * quantity)}g)`
                    : null}
                </div>
              ))}
              <HalfProducts
                halfProducts={
                  halfProduct.halfProducts as ProductTreeHalfProduct[]
                }
              />
            </div>
          ))}
      </div>
    </>
  );
};

const DetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceContext();
  const { backRef, searchValue, productTree, quantity } = useStateContext();

  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <div className="flex flex-col flex-grow h-full w-full justify-between">
        <div className="text-white py-4 px-5 overflow-auto ">
          <div className="max-w-xl mx-auto">
            <div className="text-xl font-bold pb-1">
              {productTree?.name}{" "}
              {quantity > 1 ? (
                <span className="text-lg font-normal">({quantity} szt.)</span>
              ) : null}
            </div>
            <Ingredients ingredients={productTree?.ingredients} />
            <HalfProducts halfProducts={productTree?.halfProducts} />
          </div>
        </div>
        <div
          className={
            "flex w-full pr-[var(--scrollbar-width)]" +
            (isMobile ? " bg-bgray-600" : "")
          }
        >
          <div className="flex w-full max-w-xl mx-auto py-2 px-5 bg-bgray-600">
            <Button
              className="hover:bg-black"
              onClick={() =>
                backRef
                  ? navigate(backRef)
                  : navigate(pathing.BrowseProducts as string, {
                      state: { searchValue: searchValue },
                    })
              }
            >
              Wróć
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DetailsPage as Page };
