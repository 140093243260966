import { Item } from "./Item";
import { useStateContext } from "../State";
import { useEffect, useState } from "react";
import {
  GetProductionDataDocument,
  GetProductionDataQuery,
  GetProductionDataQueryVariables,
} from "type";
import { toLocaleISOString } from "utils/toLocaleISOString";

const ItemList: React.FC = () => {
  const {
    queryClient,
    graphQLClient,
    isLoading,
    setIsLoading,
    productionItems,
    setProductionItems,
    productionDate,
  } = useStateContext();

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    setIsLoading(true);
    queryClient.cancelQueries({ queryKey: ["productionFiltered"] }, {});

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      try {
        var production = (
          await queryClient.fetchQuery({
            queryKey: ["productionFiltered"],
            queryFn: async ({ signal }) =>
              graphQLClient.request<
                GetProductionDataQuery,
                GetProductionDataQueryVariables
              >({
                document: GetProductionDataDocument,
                variables: {
                  where: productionDate
                    ? {
                        deliveryDate: { eq: toLocaleISOString(productionDate) },
                      }
                    : null,
                },
                signal,
              }),
            staleTime: 0,
          })
        ).production;
        setProductionItems(production);
        setIsLoading(false);
      } catch {
        const controller = new AbortController();
        controller.abort();
      }
    }, 400);

    setTimeoutId(newTimeoutId);

    return () => clearTimeout(newTimeoutId);
  }, [productionDate]);

  return (
    <div className="overflow-auto">
      <ul className="grid gap-y-px">
        {isLoading ? null : productionItems.length == 0 ? (
          <div className="text-center text-white">
            Brak dań do wyprodukowania
          </div>
        ) : (
          productionItems
            .sort((a, b) =>
              a.mainProduct.name.localeCompare(b.mainProduct.name, "pl-PL")
            )
            .map((item) => <Item key={item.dishId} productionItem={item} />)
        )}
      </ul>
    </div>
  );
};

export { ItemList };
