import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ProductionItem, useStateContext } from "../State";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";
import { toLocaleISOString } from "utils/toLocaleISOString";
import { PDFAll } from "./PDFAll";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "Shadcn/Tooltip";
import { Dialog, DialogContent, DialogTrigger } from "Shadcn/Dialog";

const Status: React.FC<{
  children: React.ReactNode;
  isRendering: boolean;
  isConvertingToPdf: boolean;
  isFinished: boolean;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  children,
  isRendering,
  isConvertingToPdf,
  isFinished,
  setIsFinished,
}) => {
  return (
    <Dialog onOpenChange={() => setIsFinished(false)}>
      <DialogTrigger
        className="flex h-10 rounded-md items-center px-3 py-2 text-sm font-semibold bg-bgray-100 hover:bg-white"
        asChild
      >
        {children}
      </DialogTrigger>
      <DialogContent className="border-none text-white bg-bgray-600 ">
        {isRendering ? "Przygotowywanie etykiet..." : null}
        {isConvertingToPdf ? "Konwertowanie do pliku pdf..." : null}
        {isFinished ? "Ukończono!" : null}
      </DialogContent>
    </Dialog>
  );
};

const Pdfs: React.FC<{
  productionItems: ProductionItem[];
  setShouldRenderAll: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConvertingToPdf: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  productionItems,
  setShouldRenderAll,
  setIsConvertingToPdf,
  setIsFinished,
}) => {
  const [pdfRenders, setPdfRenders] = useState<
    {
      id: string;
      quantity: number;
      ready: boolean;
      element: HTMLDivElement | null;
    }[]
  >(
    productionItems.map((item) => ({
      id: item.dishId,
      quantity: item.totalQuantity,
      ready: false,
      element: null,
    }))
  );

  const setPdfRender = (id: string) => (element: HTMLDivElement) => {
    setPdfRenders((prevRenderReadies) =>
      prevRenderReadies.map((item) =>
        item.id === id ? { ...item, ready: true, element: element } : item
      )
    );
  };

  const downloadPdf = async (
    elementsWithQuantities: {
      html: HTMLDivElement;
      quantity: number;
    }[]
  ) => {
    if (elementsWithQuantities.length > 0) {
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }"
      );

      const canvasesWithQuantities: {
        canvas: HTMLCanvasElement;
        quantity: number;
      }[] = await Promise.all(
        elementsWithQuantities.map(async ({ html, quantity }) => ({
          canvas: await html2canvas(html, { scale: 2, logging: false }),
          quantity: quantity,
        }))
      );
      style.remove();

      // Bad but pdfmake does not allow to set w, h per page.
      const width = canvasesWithQuantities[0].canvas.width;
      const height = canvasesWithQuantities[0].canvas.height;
      const dataUrlsWithQuantities: {
        url: string;
        quantity: number;
      }[] = canvasesWithQuantities.map(({ canvas, quantity }) => ({
        url: canvas.toDataURL("image/png"),
        quantity: quantity,
      }));

      const docDefinition: TDocumentDefinitions = {
        pageSize: {
          width: width,
          height: height,
        },
        content: dataUrlsWithQuantities.flatMap(({ url, quantity }, i) =>
          Array.from({ length: quantity }, (_, j) => ({
            image: url,
            width: width,
            height: height,
            absolutePosition: { x: 0, y: 0 },
            pageBreak:
              i === dataUrlsWithQuantities.length - 1 && j === quantity - 1
                ? undefined
                : "after",
          }))
        ),
      };

      pdfMake
        .createPdf(docDefinition)
        .download(
          `Etykiety_${toLocaleISOString(
            new Date(productionItems[0].deliveryDate)
          )
            .split("-")
            .reverse()
            .join("-")}.pdf`
        );
      setIsConvertingToPdf(false);
      setIsFinished(true);
    }
  };

  useEffect(() => {
    const allReady = pdfRenders.every((item) => item.ready);
    if (allReady) {
      setShouldRenderAll(false);
      setIsConvertingToPdf(true);
      downloadPdf(
        pdfRenders
          .map((pdf) =>
            pdf.element !== null
              ? { html: pdf.element, quantity: pdf.quantity }
              : null
          )
          .filter((pdf) => pdf !== null) as {
          html: HTMLDivElement;
          quantity: number;
        }[]
      );
    }
  }, [pdfRenders]);

  return productionItems.length > 0
    ? productionItems.map((item) => (
        <PDFAll
          templateUrl="./crop_no_resize_blank.jpg"
          productionItem={item}
          setPdfRender={setPdfRender(item.dishId)}
        />
      ))
    : null;
};

const PDFDownloadAll: React.FC = () => {
  const { isLoading, productionItems } = useStateContext();
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const [isConvertingToPdf, setIsConvertingToPdf] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  return (
    <>
      <TooltipProvider delayDuration={700}>
        <Tooltip>
          <Status
            isRendering={shouldRender}
            isConvertingToPdf={isConvertingToPdf}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
          >
            <TooltipTrigger asChild>
              <button
                disabled={isLoading || productionItems.length === 0}
                className="flex h-10 rounded-md items-center gap-1.5 px-2 py-2 text-sm font-semibold first-line:bg-bgray-100 hover:bg-white disabled:cursor-not-allowed disabled:opacity-25"
                onClick={() => setShouldRender(true)}
              >
                <FontAwesomeIcon className="h-6 w-6" icon={faPrint} />
              </button>
            </TooltipTrigger>
          </Status>
          <TooltipContent className="text-sm text-black bg-bgray-100">
            Drukuj całość
            <TooltipArrow width={11} height={5} className="fill-bgray-100" />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {shouldRender ? (
        <Pdfs
          productionItems={productionItems}
          setShouldRenderAll={setShouldRender}
          setIsConvertingToPdf={setIsConvertingToPdf}
          setIsFinished={setIsFinished}
        />
      ) : null}
    </>
  );
};

export { PDFDownloadAll };
