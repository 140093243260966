// Dont like it but its simplest while sufficient solution i think.

export type Pathing = {
  [key: string]: string | Pathing;
};

export const pathing: Pathing = {
  Error: "/error",
  Menu: "/", // TODO
  Order: "/:menuItemId/Zamow",
};
