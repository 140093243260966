import { DatePicker } from "../components/DatePicker";
import { ItemList } from "../components/ItemList";
import { Header } from "./Header";

const Container: React.FC = () => {
  return (
    <div className="mx-auto px-5 pb-3 flex flex-grow h-full w-full max-w-xl flex-col ">
      <Header />
      <DatePicker />
      <ItemList />
    </div>
  );
};

export { Container };
