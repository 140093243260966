import React from "react";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "Shadcn/Resizable";

import { ItemList } from "../components/ItemList";
import { OrderPanel } from "../components/Order";
import { Drag } from "../components/Drag";

const Page: React.FC = () => {
  return (
    <div className="mx-auto px-5 pb-3 flex flex-grow h-full w-full max-w-xl flex-col ">
      <ResizablePanelGroup direction="vertical">
        <ResizablePanel defaultSize={70} className="min-h-[30%]">
          <div className="flex flex-grow h-full w-full bg-yellow-500">
            <ItemList />
          </div>
        </ResizablePanel>
        <ResizableHandle className="min-h-2" />
        <ResizablePanel defaultSize={30} className="min-h-[25%] max-h-[70%]">
          <div className="flex flex-grow h-full w-full bg-red-500">
            {/* <OrderPanel /> */}
            <Drag />
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );

  return (
    <div className="mx-auto px-5 pb-3 flex flex-grow h-full w-full max-w-xl flex-col ">
      <ItemList />
    </div>
  );
};

export { Page as PageMobile };
