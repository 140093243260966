import React, { createContext, useContext, ReactNode, useState } from "react";
import { useLoaderData, useLocation } from "react-router";
import { QueryClient } from "@tanstack/react-query";
import { useForm, UseFormReturn } from "react-hook-form";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { formSchema } from "./formSchema";
import { LoaderData } from "./route";

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  form: UseFormReturn<z.infer<typeof formSchema>>;
  currentProductId: string;
  backRef: string | null;
  searchValue: string | undefined;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const { currentProduct } = useLoaderData() as LoaderData;
  const currentProductId = currentProduct.id;

  const location = useLocation();

  const [backRef] = useState<string | null>(
    location.state?.backRef ? location.state.backRef : null
  );
  const [searchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues:
      currentProduct !== null && currentProduct !== undefined
        ? {
            id: currentProduct.id,
            name: currentProduct.name,
            price: currentProduct.price,
            image: currentProduct.image,
            ingredients: currentProduct.ingredients.map((ingredient) => ({
              id: ingredient.id,
              name: ingredient.name,
              weight: ingredient.weight,
              details: {
                kilocalories: ingredient.kilocalories,
                fat: ingredient.fat,
                saturatedFat: ingredient.saturatedFat,
                carbohydrates: ingredient.carbohydrates,
                sugars: ingredient.sugars,
                fiber: ingredient.fiber,
                protein: ingredient.protein,
                salt: ingredient.salt,
              },
            })),
            halfProducts: currentProduct.halfProducts.map((halfProduct) => ({
              id: halfProduct.id,
              name: halfProduct.name,
              weight: halfProduct.weight,
              details: {
                kilocalories: halfProduct.kilocalories,
                fat: halfProduct.fat,
                saturatedFat: halfProduct.saturatedFat,
                carbohydrates: halfProduct.carbohydrates,
                sugars: halfProduct.sugars,
                fiber: halfProduct.fiber,
                protein: halfProduct.protein,
                salt: halfProduct.salt,
              },
            })),
            tags: currentProduct.tags.map((tag) => tag.value),
            categoryId: currentProduct.category?.id,
          }
        : {
            image: "",
            ingredients: [],
            halfProducts: [],
            tags: [],
          },
  });

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        form,
        currentProductId,
        backRef,
        searchValue,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
