import { RouteObject } from "react-router-dom";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./State";
import { Page } from "./Page";

const Route: RouteObject = {
  path: pathing.Categories as string,
  element: (
    <StateProvider>
      <Page />
    </StateProvider>
  ),
};

export { Route as CategoriesRoute };
