import React, { useCallback, useEffect, useState } from "react";
import { useLoaderData } from "react-router";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { Input } from "Shadcn/Input";
import { Popover, PopoverContent, PopoverTrigger } from "Shadcn/Popover";

import {
  GetProductsForOrderCreationDocument,
  GetProductsForOrderCreationQuery,
  GetProductsForOrderCreationQueryVariables,
} from "type";

import { formSchema, Product } from "../formSchema";

import { useStateContext } from "../State";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "Shadcn/Tooltip";

const ComboBox: React.FC<{
  zSubProducts: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "subProducts"
  >;
}> = ({ zSubProducts }) => {
  const { queryClient, graphQLClient } = useStateContext();

  type SelectProduct = GetProductsForOrderCreationQuery["products"][0];
  const allProducts = useLoaderData() as SelectProduct[];
  const [products, setProducts] = useState<SelectProduct[]>(allProducts);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      return;
    }
    if (isOpen) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < products.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : products.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < products.length) {
          handleSelect(products[highlightedIndex]);
        }
      }
      return;
    }
    if (["ArrowDown", "Enter", "ArrowUp"].some((key) => key === event.key)) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  const refetch = useCallback(
    async (value: string) => {
      setInputValue(value);
      queryClient.cancelQueries({ queryKey: ["orderCreationProducts"] }, {});
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(async () => {
        try {
          const fetchedProducts = (
            await queryClient.fetchQuery({
              queryKey: ["orderCreationProducts"],
              queryFn: ({ signal }) => {
                return graphQLClient.request<
                  GetProductsForOrderCreationQuery,
                  GetProductsForOrderCreationQueryVariables
                >({
                  document: GetProductsForOrderCreationDocument,
                  variables: {
                    where: {
                      and: [
                        {
                          name: { contains: value.trim() },
                          id: {
                            nin: zSubProducts.value.map(
                              (product) => product.id
                            ),
                          },
                        },
                      ],
                    },
                  },
                  signal,
                });
              },
              staleTime: 0,
            })
          ).products;
          setProducts(fetchedProducts);
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 200);
      setTimeoutId(newTimeoutId);
    },
    [timeoutId, zSubProducts]
  );

  const selectProduct = (product: SelectProduct) => {
    const updatedProducts = [
      ...zSubProducts.value,
      {
        ...product,
        quantity: 1,
        details: {
          price: product.price,
          weight: product.weight,
          kilocalories: product.kilocalories,
          fat: product.fat,
          saturatedFat: product.saturatedFat,
          carbohydrates: product.carbohydrates,
          sugars: product.sugars,
          fiber: product.fiber,
          protein: product.protein,
          salt: product.salt,
        },
      },
    ];
    zSubProducts.onChange(updatedProducts);
  };

  const handleSelect = (product: SelectProduct) => {
    setInputValue("");
    selectProduct(product);
    setIsOpen(false);
  };

  useEffect(() => {
    refetch("");
  }, [zSubProducts.value]);

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refetch(event.target.value);
    setIsOpen(true);
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setHighlightedIndex(0)}>
      <PopoverTrigger asChild>
        <Input
          type="text"
          className="bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black"
          placeholder="Wybierz produkty dodatkowe..."
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={() => setIsOpen(false)}
          onChange={handleInputChange}
          value={inputValue}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[--radix-popover-trigger-width] rouned-none p-0"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div className="flex flex-col max-h-[256px] overflow-auto">
          {products.length > 0 ? (
            products.map((product, index) => (
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      key={product.id}
                      className={`text-left p-1 hover:bg-bgray-100 ${
                        highlightedIndex === index ? "bg-bgray-100" : ""
                      }`}
                      // onMouseEnter={() => setHighlightedIndex(index)} dont remember if this should be or not
                      onClick={() => handleSelect(product)}
                    >
                      {product.name}
                    </button>
                  </TooltipTrigger>
                  {product.ingredientNames.length > 0 ? (
                    <TooltipContent
                      side="right"
                      align="start"
                      sideOffset={16}
                      className="bg-bgray-100 z-50"
                    >
                      {product.ingredientNames.map((name) => (
                        <p className="text-base">{name}</p>
                      ))}
                    </TooltipContent>
                  ) : null}
                </Tooltip>
              </TooltipProvider>
            ))
          ) : (
            <div key={0} className="text-center p-1">
              Brak produktów
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const Select: React.FC<{
  zSubProducts: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "subProducts"
  >;
}> = ({ zSubProducts }) => {
  const discardProduct = (productToDiscard: z.infer<typeof Product>) => {
    zSubProducts.onChange(
      zSubProducts.value.filter((product) => product.id !== productToDiscard.id)
    );
  };

  const onChange = (value: string, product: z.infer<typeof Product>) => {
    if (value.length === 2) {
      value = value[0] + value[1].replace(/[^1-9]/g, "");
      if (value.length === 2) {
        value = value[1];
      }
    } else {
      value = value.replace(/[^1-9]/g, "");
    }
    const products = zSubProducts.value;
    const index = products.findIndex((_product) => _product.id === product.id);
    const updatedProducts = [
      ...products.slice(0, index),
      { ...product, quantity: parseInt(value ? value : "0") },
      ...products.slice(index + 1),
    ];
    zSubProducts.onChange(updatedProducts);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <ComboBox zSubProducts={zSubProducts} />
      </div>
      <div
        className={`flex flex-col w-full gap-0.5 ${
          zSubProducts.value.length !== 0 ? "mt-2" : ""
        }`}
      >
        {zSubProducts.value.map((product) => (
          <div
            className="flex w-full justify-between items-center font-medium p-1.5 text-white bg-bgray-400"
            key={product.id}
          >
            {product.name}
            <div className="flex items-center gap-4 ">
              <Input
                className="w-6 h-8 rounded-none text-center p-0 font-medium text-white bg-bgray-200 border-bgray-300  focus-visible:ring-0 focus-visible:outline-0 placeholder:text-white"
                placeholder="..."
                value={product.quantity ? product.quantity : ""}
                onChange={(event) => onChange(event.target.value, product)}
              />
              <button
                className="flex text-bred hover:text-red-800 "
                onClick={() => {
                  discardProduct(product);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Select as SubProductsSelect };
