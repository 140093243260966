import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { Kanban } from "./kanban";

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: "/kanban",
    element: <Kanban />,
  };
};

export { getRoute as getKanbanRoute };
