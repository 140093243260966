import {
  faPencilAlt,
  faTrash,
  faCopy,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pathing } from "pages/staff/pathing";
import { useNavigate, useSubmit } from "react-router-dom";
import { Badge } from "Shadcn/Badge";

import { Product, useStateContext } from "../State";
import { Admin } from "Admin";
import { Require } from "Require";
import { PC } from "PC";
import { useRef, useState } from "react";

const Item: React.FC<{
  product: Product;
}> = ({ product }) => {
  const submit = useSubmit();
  const navigate = useNavigate();
  const { searchValue } = useStateContext();

  const formatPrice = (price: number): string => {
    const priceString = price.toFixed(2);
    return priceString.includes(".")
      ? priceString.replace(".", " zł ").concat(" gr")
      : priceString.concat(" zł");
  };

  const f = (number: number, decimalPoint: number): string => {
    return number.toFixed(decimalPoint).toString().replace(".", ",");
  };

  const liRef = useRef<HTMLLIElement | null>(null);

  const deleteProduct = async () => {
    if (liRef?.current) {
      liRef.current.style.opacity = "0.2";
      liRef.current.style.transition = "opacity 0.4s";
    }

    requestAnimationFrame(() => {
      submit(
        { id: product.id },
        {
          method: "delete",
          encType: "application/json",
        }
      );
    });
  };

  return (
    <li
      ref={liRef}
      className="flex items-stretch px-2 pt-2 pb-3 min-h-36 bg-bgray-400 hover:bg-bgray-500"
    >
      <div className="flex max-w-24 p-2 ">
        <img
          src={product.image !== "" ? product.image : "./LogoWhite.png"}
          className="object-contain  w-full"
        />
      </div>
      <div className="flex flex-grow items-center justify-end">
        <div className="flex flex-grow flex-col ">
          <h3 className="text-lg font-medium text-white">{product.name}</h3>
          <h4 className="text-base font-normal text-white">
            {formatPrice(product.price)}, {product.weight}g
          </h4>
          <div className="pb-1">
            {(product.tags ?? []).map((tag) => (
              <Badge key={tag.id} className="text-[10px] mr-1">
                {tag.value}
              </Badge>
            ))}
          </div>
          {product.ingredientNames.length > 0 ? (
            <div className="text-xs text-gray-200">
              {product.ingredientNames.join(", ")}
            </div>
          ) : null}
        </div>
        <PC>
          <div className="flex max-w-content text-gray-200">
            <table className="table-auto text-xs mx-2">
              <tbody>
                <tr>
                  <td className="text-nowrap text-end py-0">
                    Wartość energetyczna:
                  </td>
                  <td className="pl-1 py-0">
                    {f(product.kilocalories, 0)}kcal
                  </td>
                </tr>
                <tr>
                  <td className="text-end py-0">Tłuszcz:</td>
                  <td className="pl-1 py-0">{f(product.fat, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">w tym kwasy tłuszczowe:</td>
                  <td className="pl-1 py-0">{f(product.saturatedFat, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">Węglowodany:</td>
                  <td className="pl-1 py-0">{f(product.carbohydrates, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">w tym cukry:</td>
                  <td className="pl-1 py-0">{f(product.sugars, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">Błonnik:</td>
                  <td className="pl-1 py-0">{f(product.fiber, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">Białko:</td>
                  <td className="pl-1 py-0">{f(product.protein, 1)}g</td>
                </tr>
                <tr>
                  <td className="text-end py-0">Sól:</td>
                  <td className="pl-1 py-0">{f(product.salt, 2)}g</td>
                </tr>
              </tbody>
            </table>
          </div>
        </PC>
        <div className="flex flex-col justify-around h-full py-4">
          <Admin>
            <button
              className="flex text-bred hover:text-red-800 p-1"
              onClick={deleteProduct}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              className="flex text-bgreen-100 hover:text-bgreen-300 p-1"
              onClick={() =>
                navigate(
                  (pathing.EditProduct as string).replace(
                    ":productId",
                    product.id
                  ),
                  { state: { searchValue: searchValue } }
                )
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button
              className="flex text-white hover:text-bgray-100 p-1"
              onClick={() =>
                navigate(pathing.AddProduct as string, {
                  state: {
                    searchValue: searchValue,
                    fromBrowse: product,
                    fromEdit: null,
                  },
                })
              }
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </Admin>
          <Require Chef Admin>
            <button
              className="flex text-white hover:text-bgray-100 p-1"
              onClick={() =>
                navigate(
                  (pathing.ProductDetails as string).replace(
                    ":productId",
                    product.id
                  ),
                  { state: { searchValue: searchValue } }
                )
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </button>
          </Require>
        </div>
      </div>
    </li>
  );
};

export { Item };
