import { LoaderFunctionArgs, RouteObject, redirect } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import {
  EditIngredientMutation,
  EditIngredientMutationVariables,
  EditIngredientDocument,
} from "type";

import { pathing } from "pages/staff/pathing";

import { Form } from "./components/Form";

type LoaderData = {
  id: string;
  name: string;
  kilocalories: number;
  fat: number;
  saturatedFat: number;
  carbohydrates: number;
  sugars: number;
  fiber: number;
  protein: number;
  salt: number;
  details: string | null;
  description: string;
};

type ActionData = {
  data: LoaderData;
  error: EditIngredientMutation["ingredient"]["edit"]["errors"][0] | null;
};

const load = async ({ params, request }: LoaderFunctionArgs) => {
  return {
    id: params.ingredientId,
    ...Object.fromEntries(new URL(request.url).searchParams.entries()),
  };
};

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }): Promise<ActionData | Response> => {
    const ingredientFormData = await request.json();
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["ingredients"],
        queryFn: async () =>
          graphQLClient.request<
            EditIngredientMutation,
            EditIngredientMutationVariables
          >({
            document: EditIngredientDocument,
            variables: { editInput: { ...ingredientFormData } },
          }),
        staleTime: 0,
      })
    ).ingredient.edit;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "IngredientNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
      }
      return {
        data: ingredientFormData,
        error: error ?? null,
      };
    }

    return {
      data: ingredientFormData,
      error: null,
    };

    const editedUrl = (pathing.EditIngredient as string).replace(
      ":ingredientId",
      ingredientFormData.id
    );
    const redirectUrl = `${editedUrl}
      ?name=${encodeURIComponent(ingredientFormData.name)}
      &kilocalories=${encodeURIComponent(ingredientFormData.kilocalories)}
      &fat=${encodeURIComponent(ingredientFormData.fat)}
      &saturatedFat=${encodeURIComponent(ingredientFormData.saturatedFat)}
      &carbohydrates=${encodeURIComponent(ingredientFormData.carbohydrates)}
      &sugars=${encodeURIComponent(ingredientFormData.sugars)}
      &fiber=${encodeURIComponent(ingredientFormData.fiber)}
      &protein=${encodeURIComponent(ingredientFormData.protein)}
      &salt=${encodeURIComponent(ingredientFormData.salt)}
      &description=${encodeURIComponent(ingredientFormData.description)}
    `.replace(/\s+/g, "");
    return redirect(redirectUrl);
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.EditIngredient as string,
    loader: load,
    action: getAction(queryClient, graphQLClient),
    element: <Form />,
  };
};

export type { LoaderData, ActionData };
export { getRoute as getEditIngredientRoute };
