import { RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { pathing } from "pages/staff/pathing";

import { Page } from "./Page";
import { StateProvider } from "./State";
import {
  DeleteProductMutation,
  DeleteProductMutationVariables,
  DeleteProductDocument,
} from "type";

export type ErrorTypeName =
  | DeleteProductMutation["product"]["delete"]["errors"][0]["__typename"]
  | "UnhandledError";

export type ActionData = {
  id: string | null;
  errors: {
    __typename: ErrorTypeName;
    message: string;
  }[];
};

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }): Promise<ActionData> => {
    const { id } = (await request.json()) as { id: string };
    switch (request.method) {
      case "DELETE":
        return await deleteHalfProduct(queryClient, graphQLClient, id);
    }
    throw new Error("Unexpected method.");
  };

const deleteHalfProduct = async (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient,
  id: string
): Promise<ActionData> => {
  const result = (
    await queryClient.fetchQuery({
      queryKey: ["deleteOrder", id],
      queryFn: async () =>
        graphQLClient.request<
          DeleteProductMutation,
          DeleteProductMutationVariables
        >({
          document: DeleteProductDocument,
          variables: { id: id },
        }),
      staleTime: 0,
    })
  ).product.delete;
  if (result.errors.length != 0) {
    const error = result.errors.find(
      (error) => error.__typename === "ProductNotFoundError"
    );
    if (!error) {
      // TODO Unhandled error, what to do? Probably navigate to some global error page.
      // TODO Request should also have try catch to do the same.
      return {
        id: null,
        errors: [
          {
            __typename: "UnhandledError",
            message: "Unhandled Error", // Probably pass some stuff?
          },
        ],
      };
    }
    return { id: null, errors: [error] };
  }
  return { id: id, errors: [] };
};

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.BrowseProducts as string,
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider>
        <Page />
      </StateProvider>
    ),
  };
};

export { getRoute as getBrowseProductsRoute };
