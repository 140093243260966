import React, { useCallback, useEffect, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";

import { z } from "zod";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { Input } from "Shadcn/Input";
import { Popover, PopoverContent, PopoverTrigger } from "Shadcn/Popover";

import {
  GetHalfProductsForHalfProductCreationDocument,
  GetHalfProductsForHalfProductCreationQuery,
  GetHalfProductsForHalfProductCreationQueryVariables,
} from "type";

import { formSchema, HalfProduct } from "../formSchema";

import { useStateContext } from "../State";

const ComboBox: React.FC<{
  zSubHalfProducts: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "subHalfProducts"
  >;
}> = ({ zSubHalfProducts }) => {
  const { queryClient, graphQLClient } = useStateContext();

  type SelectHalfProduct =
    GetHalfProductsForHalfProductCreationQuery["halfProducts"][0];
  const [halfProducts, setHalfProducts] = useState<SelectHalfProduct[]>([]);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      return;
    }
    if (isOpen) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < halfProducts.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : halfProducts.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < halfProducts.length) {
          handleSelect(halfProducts[highlightedIndex]);
        }
      }
      return;
    }
    if (["ArrowDown", "Enter", "ArrowUp"].some((key) => key === event.key)) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  const refetch = useCallback(
    async (value: string) => {
      setInputValue(value);
      queryClient.cancelQueries(
        { queryKey: ["halfProductCreationHalfProducts"] },
        {}
      );
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(async () => {
        try {
          const fetchedHalfProducts = (
            await queryClient.fetchQuery({
              queryKey: ["halfProductCreationHalfProducts"],
              queryFn: ({ signal }) => {
                return graphQLClient.request<
                  GetHalfProductsForHalfProductCreationQuery,
                  GetHalfProductsForHalfProductCreationQueryVariables
                >({
                  document: GetHalfProductsForHalfProductCreationDocument,
                  variables: {
                    where: {
                      and: [
                        {
                          name: { contains: value.trim() },
                          id: {
                            nin: zSubHalfProducts.value.map(
                              (product) => product.id
                            ),
                          },
                        },
                      ],
                    },
                  },
                  signal,
                });
              },
              staleTime: 0,
            })
          ).halfProducts;
          setHalfProducts(fetchedHalfProducts);
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 100);
      setTimeoutId(newTimeoutId);
    },
    [timeoutId, zSubHalfProducts]
  );

  const selectHalfProduct = (halfProduct: SelectHalfProduct) => {
    const updatedHalfProducts = [
      ...zSubHalfProducts.value,
      {
        id: halfProduct.id,
        name: halfProduct.name,
        weight: 100,
        details: {
          kilocalories: halfProduct.kilocalories,
          fat: halfProduct.fat,
          saturatedFat: halfProduct.saturatedFat,
          carbohydrates: halfProduct.carbohydrates,
          sugars: halfProduct.sugars,
          fiber: halfProduct.fiber,
          protein: halfProduct.protein,
          salt: halfProduct.salt,
        },
      },
    ];
    zSubHalfProducts.onChange(updatedHalfProducts);
  };

  const handleSelect = (halfProduct: SelectHalfProduct) => {
    setInputValue("");
    selectHalfProduct(halfProduct);
    setIsOpen(false);
  };

  useEffect(() => {
    refetch("");
  }, [zSubHalfProducts.value]);

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refetch(event.target.value);
    setIsOpen(true);
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setHighlightedIndex(0)}>
      <PopoverTrigger asChild>
        <Input
          type="text"
          className="bg-bgray-100 focus-visible:ring-0 focus-visible:outline-0 placeholder:text-black"
          placeholder="Wpisz nazwę półproduktu lub jej część..."
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={() => setIsOpen(false)}
          onChange={handleInputChange}
          value={inputValue}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[--radix-popover-trigger-width] rouned-none p-0"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div className="flex flex-col max-h-[256px] overflow-auto">
          {halfProducts.length > 0 ? (
            halfProducts.map((product, index) => (
              <button
                key={product.id}
                className={`text-left p-1 hover:bg-gray-200 ${
                  highlightedIndex === index ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  handleSelect(product);
                }}
              >
                {product.name}
              </button>
            ))
          ) : (
            <div key={0} className="text-center p-1">
              Brak produktów
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const Select: React.FC<{
  zSubHalfProducts: ControllerRenderProps<
    z.infer<typeof formSchema>,
    "subHalfProducts"
  >;
}> = ({ zSubHalfProducts }) => {
  const discardHalfProduct = (
    halfProductToDiscard: z.infer<typeof HalfProduct>
  ) => {
    zSubHalfProducts.onChange(
      zSubHalfProducts.value.filter(
        (halfProduct) => halfProduct.id !== halfProductToDiscard.id
      )
    );
  };

  const onChange = (
    value: string,
    halfProduct: z.infer<typeof HalfProduct>
  ) => {
    value = value.replace(/[^0-9]/g, "");
    const halfProducts = zSubHalfProducts.value;
    const index = halfProducts.findIndex(
      (_halfProduct) => _halfProduct.id === halfProduct.id
    );
    const updatedHalfProducts = [
      ...halfProducts.slice(0, index),
      { ...halfProduct, weight: parseInt(value ? value : "0") },
      ...halfProducts.slice(index + 1),
    ];
    zSubHalfProducts.onChange(updatedHalfProducts);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <ComboBox zSubHalfProducts={zSubHalfProducts} />
      </div>
      <div
        className={`flex flex-col w-full gap-0.5 ${
          zSubHalfProducts.value.length !== 0 ? "mt-2" : ""
        }`}
      >
        {zSubHalfProducts.value.map((halfProduct) => (
          <div
            className="flex w-full items-center font-medium p-1.5 text-white bg-bgray-400"
            key={halfProduct.id}
          >
            <div className="flex grow text-nowrap">{halfProduct.name}:</div>
            <div className="flex w-full items-center pl-1.5 gap-1.5">
              <Input
                className="h-8 px-1.5 text-[16px] font-medium text-white bg-bgray-400 border-bgray-300  focus-visible:ring-0 focus-visible:outline-0 placeholder:text-white"
                placeholder="..."
                value={halfProduct.weight ? halfProduct.weight : ""}
                onChange={(event) => onChange(event.target.value, halfProduct)}
              />
              g
              <button
                className="flex pl-1 text-bred hover:text-red-800 "
                onClick={() => {
                  discardHalfProduct(halfProduct);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Select as SubHalfProductsSelect };
