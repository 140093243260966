import React, { createContext, useContext, ReactNode, useState } from "react";
import { useLoaderData, useLocation } from "react-router";
import { QueryClient } from "@tanstack/react-query";
import { useForm, UseFormReturn } from "react-hook-form";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { formSchema } from "./formSchema";
import { LoaderData } from "./route";

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  form: UseFormReturn<z.infer<typeof formSchema>>;
  currentHalfProductId: string;
  parentsIds: string[];
  backRef: string | null;
  searchValue: string | undefined;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const { currentHalfProduct, parentsIds } = useLoaderData() as LoaderData;
  const currentHalfProductId = currentHalfProduct.id;

  const location = useLocation();
  const [backRef] = useState<string | null>(
    location.state ? location.state.backRef : null
  );
  const [searchValue] = useState<string | undefined>(
    location.state?.searchValue ? location.state.searchValue : undefined
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues:
      currentHalfProduct !== null && currentHalfProduct !== undefined
        ? {
            id: currentHalfProduct.id,
            name: currentHalfProduct.name,
            ingredients: currentHalfProduct.ingredients.map((ingredient) => ({
              id: ingredient.id,
              name: ingredient.name,
              weight: ingredient.weight,
              details: {
                kilocalories: ingredient.kilocalories,
                fat: ingredient.fat,
                saturatedFat: ingredient.saturatedFat,
                carbohydrates: ingredient.carbohydrates,
                sugars: ingredient.sugars,
                fiber: ingredient.fiber,
                protein: ingredient.protein,
                salt: ingredient.salt,
              },
            })),
            subHalfProducts: currentHalfProduct.subHalfProducts.map(
              (subHalfProduct) => ({
                id: subHalfProduct.id,
                name: subHalfProduct.name,
                weight: subHalfProduct.weight,
                details: {
                  kilocalories: subHalfProduct.kilocalories,
                  fat: subHalfProduct.fat,
                  saturatedFat: subHalfProduct.saturatedFat,
                  carbohydrates: subHalfProduct.carbohydrates,
                  sugars: subHalfProduct.sugars,
                  fiber: subHalfProduct.fiber,
                  protein: subHalfProduct.protein,
                  salt: subHalfProduct.salt,
                },
              })
            ),
          }
        : {
            ingredients: [],
            subHalfProducts: [],
          },
  });

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        form,
        currentHalfProductId,
        parentsIds,
        backRef,
        searchValue,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
