import { useState } from "react";

import { useAuthContext } from "AuthContext";

import { Button } from "Shadcn/Button";
import { Input } from "Shadcn/Input";

const Login: React.FC = () => {
  const { login } = useAuthContext();
  const [password, setPassword] = useState<string>("");
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const onClick = async () => {
    setLoginFailed(!(await login(password)).isAuthenticated);
  };

  return (
    <div className="flex h-dvh w-dvw bg-bgray-600">
      <div className="flex flex-col grow max-w-lg mx-auto">
        <div className="flex basis-1/6"></div>
        <div className="flex items-center basis-2/6">
          <div className="flex flex-col mx-auto w-[300px] gap-4 p-8">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold text-white">Zaloguj się</h1>
            </div>
            <div className="grid gap-2">
              <Input
                id="password"
                type="password"
                className="border-bgray-400 bg-bgray-200 text-white"
                required
                value={password}
                onFocus={() => setLoginFailed(false)}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="relative">
              <Button
                className="w-full bg-bgreen-100 hover:bg-bgreen-300"
                onClick={onClick}
              >
                Zaloguj się
              </Button>
              <div
                hidden={!loginFailed}
                className="absolute top-full left-0 w-full mt-2 text-center text-bred"
              >
                Niepoprawne hasło
              </div>
            </div>
          </div>
        </div>
        <div className="flex basis-3/6"></div>
        {/* <div className="flex basis-3/6 w-[300px] mx-auto p-8">
          <Button
            className="w-full bg-bgreen-100 hover:bg-bgreen-300"
            onClick={onClickCustomer}
          >
            Klient
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export { Login };
