import React, { useCallback, useState } from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  RemoveDailyMenuDocument,
  RemoveDailyMenuMutation,
  RemoveDailyMenuMutationVariables,
  SetDailyMenuQuantityDocument,
  SetDailyMenuQuantityMutation,
  SetDailyMenuQuantityMutationVariables,
} from "type";

import { Input } from "Shadcn/Input";

import { Day } from "../state/types";
import { useStateContext } from "../state/StateProvider";
import { AddItemButton } from "./AddItemButton";
import { ItemComponent } from "./Item";

const QuantityBox: React.FC<{ day: Day }> = ({ day }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const [quantity, setQuantity] = useState<string | null>(
    day.quantity?.toString()!
  );
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const handleInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: string | number | null = event.target.value.replace(
        /[^0-9]/g,
        ""
      );
      setQuantity(value);

      value = value ? parseInt(value) : null;

      queryClient.cancelQueries({ queryKey: ["setDailyMenuQauntity"] }, {});

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(async () => {
        try {
          await queryClient.fetchQuery({
            queryKey: ["setDailyMenuQauntity"],
            queryFn: ({ signal }) => {
              return graphQLClient.request<
                SetDailyMenuQuantityMutation,
                SetDailyMenuQuantityMutationVariables
              >({
                document: SetDailyMenuQuantityDocument,
                variables: {
                  id: day.id,
                  quantity: value,
                },
                signal,
              });
            },
            staleTime: 0,
          });
          const updatedItems = day.items.map((item) => {
            if (item.quantity) {
              if (value === null || value < item.quantity) {
                item.quantity = value;
              }
            }
            return item;
          });
          setWeek({
            ...week,
            [day.key]: {
              ...week[day.key],
              quantity: value,
              items: updatedItems,
            },
          });
        } catch {
          const controller = new AbortController();
          controller.abort();
        }
      }, 500);

      setTimeoutId(newTimeoutId);
    },
    [timeoutId, week]
  );

  return (
    <Input
      value={quantity!}
      onChange={handleInput}
      className="w-7 h-8 rounded-none text-center p-0.5 focus-visible:outline-none
      text-white bg-bgray-600 border-0 border-b-2 border-white placeholder:text-bgray-100"
      // placeholder="—"
      placeholder="..."
      maxLength={3}
    ></Input>
  );
};

const DayComponent: React.FC<{ day: Day }> = ({ day }) => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();

  const removeDay = () => {
    queryClient
      .fetchQuery({
        queryKey: ["removeDailyMenu"],
        queryFn: async () =>
          graphQLClient.request<
            RemoveDailyMenuMutation,
            RemoveDailyMenuMutationVariables
          >({
            document: RemoveDailyMenuDocument,
            variables: {
              id: day.id,
            },
          }),
        staleTime: 0,
      })
      .then(() => {
        setWeek({
          ...week,
          [day.key]: {
            ...week[day.key],
            id: null,
            items: [],
            quantity: null,
          },
        });
      });
  };

  return (
    <div className="flex flex-col relative h-fit w-72">
      <div className="flex justify-between items-center p-2 font-medium bg-black text-white">
        <div className="flex  ">
          {day.name} {day.date}
        </div>
        <div className="flex gap-1 items-center">
          <QuantityBox day={day} />
          <button
            className="flex text-bred hover:text-red-800 "
            onClick={removeDay}
          >
            <FontAwesomeIcon icon={faTrash} className="h-6 w-6 px-1 py-1.5" />
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        {day.items.map((item, index) => (
          <ItemComponent key={item.id} index={index} item={item} />
        ))}
      </div>
      <AddItemButton day={day} />
    </div>
  );
};

export { DayComponent };
