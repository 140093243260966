import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  GetDailyMenusForWeekDocument,
  GetDailyMenusForWeekQuery,
  GetDailyMenusForWeekQueryVariables,
} from "type";

import { toLocaleISOString } from "utils/toLocaleISOString";

import { useStateContext } from "../state/StateProvider";
import { DayKey } from "../state/types";

const hasWeekdayInCurrentMonth = (week: string[], month: number): boolean => {
  for (const dateStr of week) {
    const date = new Date(dateStr);
    const day = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    if (day >= 1 && day <= 5 && date.getMonth() + 1 === month) {
      return true;
    }
  }
  return false;
};

const splitMonthIntoWeeks = (year: number, month: number): string[][] => {
  const weeks: string[][] = [];
  const daysInMonth = new Date(year, month, 0).getDate();
  let currentWeek: string[] = [];

  const firstDay = new Date(year, month - 1, 1).getDay();
  let currentDay = 1 - ((firstDay + 1) % 7); // Start from the Saturday before the first of the month

  while (currentDay <= daysInMonth) {
    const date = new Date(year, month - 1, currentDay);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");

    currentWeek.push(`${yyyy}-${mm}-${dd}`);

    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }

    currentDay++;
  }

  // Add remaining days to the last week if there are any
  if (currentWeek.length > 0) {
    while (currentWeek.length < 7) {
      const date = new Date(year, month - 1, currentDay);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");

      currentWeek.push(`${yyyy}-${mm}-${dd}`);
      currentDay++;
    }

    // Check if the last week contains a weekday in the current month
    if (hasWeekdayInCurrentMonth(currentWeek, month)) {
      weeks.push(currentWeek);
    }
  }

  return weeks;
};

const WeekPicker: React.FC = () => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const today = new Date();
  const [year, setYear] = useState<number>(today.getFullYear());
  const [monthNo, setMonthNo] = useState<number>(today.getMonth() + 1); // 1-12
  const [monthSplit, setMonthSplit] = useState<string[][]>([]);
  const [isInitialSplit, setIsInitialSplit] = useState<boolean>(true);
  const [selectedWeekArray, setSelectedWeekArray] = useState<string[]>([]);

  useEffect(() => {
    const weeks = splitMonthIntoWeeks(year, monthNo);
    setMonthSplit(weeks);
  }, [year, monthNo]);

  useEffect(() => {
    if (monthSplit.length !== 0) {
      if (isInitialSplit) {
        monthSplit.map((week) => {
          if (week.some((date) => date === toLocaleISOString(today))) {
            setSelectedWeekArray(week);
            return;
          }
        });
        setIsInitialSplit(false);
      }
    }
  }, [monthSplit]);

  useEffect(() => {
    if (selectedWeekArray.length !== 0) {
      queryClient
        .fetchQuery({
          queryKey: ["dailyMenusForWeek"],
          queryFn: async () =>
            graphQLClient.request<
              GetDailyMenusForWeekQuery,
              GetDailyMenusForWeekQueryVariables
            >({
              document: GetDailyMenusForWeekDocument,
              variables: {
                startDate: selectedWeekArray[2],
                endDate: selectedWeekArray[6],
              },
            }),
          staleTime: 0,
        })
        .then(({ dailyMenus }) => setWeekData(dailyMenus));
    }
  }, [selectedWeekArray]);

  const setWeekData = (dailyMenus: GetDailyMenusForWeekQuery["dailyMenus"]) => {
    // Kind of cancer, probably fix at some point.
    const mondayDate = selectedWeekArray[2];
    const mondayMenu =
      dailyMenus.find(
        ({ date }) => (date as string).split("T")[0] === mondayDate
      ) ?? null;

    const tuesdayDate = selectedWeekArray[3];
    const tuesdayMenu =
      dailyMenus.find(
        ({ date }) => (date as string).split("T")[0] === tuesdayDate
      ) ?? null;

    const wednesdayDate = selectedWeekArray[4];
    const wednesdayMenu =
      dailyMenus.find(
        ({ date }) => (date as string).split("T")[0] === wednesdayDate
      ) ?? null;

    const thursdayDate = selectedWeekArray[5];
    const thursdayMenu =
      dailyMenus.find(
        ({ date }) => (date as string).split("T")[0] === thursdayDate
      ) ?? null;

    const fridayDate = selectedWeekArray[6];
    const fridayMenu =
      dailyMenus.find(
        ({ date }) => (date as string).split("T")[0] === fridayDate
      ) ?? null;

    const parseMenu = (
      dayDate: string,
      dayKey: DayKey,
      menu: GetDailyMenusForWeekQuery["dailyMenus"][0] | null
    ) => {
      return {
        id: menu === null ? null : menu.id,
        date: dayDate,
        quantity: menu?.quantity as number,
        items:
          menu === null
            ? []
            : menu.items.map((item) => {
                return {
                  id: item.id,
                  dayKey: dayKey,
                  quantity: item.quantity!,
                  mainProduct: item.mainProduct ? item.mainProduct : null,
                  subProductLists: item.subProductLists.map((list) => {
                    return {
                      id: list.id,
                      dayKey: dayKey,
                      itemId: item.id,
                      products: list.products.map((product) => {
                        return {
                          id: product.id,
                          name: product.name,
                        };
                      }),
                    };
                  }),
                };
              }),
      };
    };
    setWeek({
      ...week,
      monday: {
        ...week.monday,
        ...parseMenu(mondayDate, "monday", mondayMenu),
      },
      tuesday: {
        ...week.tuesday,
        ...parseMenu(tuesdayDate, "tuesday", tuesdayMenu),
      },
      wednesday: {
        ...week.wednesday,
        ...parseMenu(wednesdayDate, "wednesday", wednesdayMenu),
      },
      thursday: {
        ...week.thursday,
        ...parseMenu(thursdayDate, "thursday", thursdayMenu),
      },
      friday: {
        ...week.friday,
        ...parseMenu(fridayDate, "friday", fridayMenu),
      },
    });
  };

  const pickWeek = (weekArray: string[]) => {
    setSelectedWeekArray(weekArray);
  };

  const goToPreviousMonth = () => {
    const prevMonth = ((monthNo + 10) % 12) + 1;
    if (prevMonth === 12) {
      setYear(year - 1);
    }
    setMonthNo(prevMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = (monthNo % 12) + 1;
    if (nextMonth === 1) {
      setYear(year + 1);
    }
    setMonthNo((monthNo % 12) + 1);
  };

  return (
    <div className="flex flex-col w-full max-w-3xl mx-auto bg-bgray-500">
      <div className="flex mx-auto p-1 text-2xl capitalize ">
        <h2>
          {new Date(year, monthNo - 1).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
      </div>
      <div className="flex w-full flex-row justify-center mt-1 gap-2 text-black">
        <button className="text-white" onClick={goToPreviousMonth}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {monthSplit.map((week, index) => (
          <button
            key={index}
            className={`h-9 w-24 ${
              // In theory if we go too far wrong one will be highlighted.
              JSON.stringify(selectedWeekArray) === JSON.stringify(week)
                ? "text-black bg-bgray-100"
                : "text-white bg-bgray-600 hover:bg-bgray-400"
            }`}
            onClick={() => pickWeek(week)}
          >
            {week[2].split("-")[2]}-{week[6].split("-")[2]}
          </button>
        ))}
        <button className="text-white" onClick={goToNextMonth}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export { WeekPicker };
