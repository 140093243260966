import { z } from "zod";

export const preprocess = (val: unknown) => {
  if (isNaN(val as number)) {
    return undefined;
  }
  return val;
};

const requiredError = "Pole wymagane.";
const invalidValue = "Nieprawidłowa wartość.";

export const ProductDetails = z.object({
  price: z.number(),
  weight: z.number(),
  kilocalories: z.number(),
  fat: z.number(),
  saturatedFat: z.number(),
  carbohydrates: z.number(),
  sugars: z.number(),
  fiber: z.number(),
  protein: z.number(),
  salt: z.number(),
});

export const Product = z.object({
  id: z.string(),
  name: z.string(),
  quantity: z
    .number({ required_error: "Podaj liczbę sztuk." })
    .gt(0, { message: invalidValue }),
  details: ProductDetails,
});

export const formSchema = z.object({
  mainProduct: z
    .object(Product.shape, {
      required_error: "Wybierz produkt główny.",
    })
    .refine(
      (product) => {
        return (
          product.quantity !== undefined &&
          product.quantity !== null &&
          product.quantity > 0
        );
      },
      { message: "Podaj liczbę sztuk." }
    ),
  subProducts: z.array(Product).refine(
    (products) => {
      return products.every((product) => {
        return (
          product.quantity !== undefined &&
          product.quantity !== null &&
          product.quantity > 0
        );
      });
    },
    { message: "Któryś z produktów ma niepoprawną liczbę sztuk." }
  ),
  totalQuantity: z.preprocess(
    preprocess,
    z.number({ required_error: requiredError }).gt(0, { message: invalidValue })
  ),
  deliveryDate: z.date({ required_error: "Wybierz datę." }),
});
