import { createBrowserRouter } from "react-router-dom";

import { Login } from "./Login";

const getRouter = () =>
  createBrowserRouter(
    [
      {
        path: "/*",
        element: <Login />,
      },
    ],
    {
      future: { v7_relativeSplatPath: true },
    }
  );

export { getRouter as getLoginRouter };
