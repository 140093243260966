import React, { createContext, useContext, useState, ReactNode } from "react";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";
import { useLocation } from "react-router";
import { GetDailyMenusQuery } from "type";
import type { UniqueIdentifier } from "@dnd-kit/core";

//Drag
export interface Row {
  id: UniqueIdentifier;
}

export interface DragProduct {
  id: UniqueIdentifier;
  rowId: UniqueIdentifier;
  content: string;
}

///

export type DailyMenuItem = GetDailyMenusQuery["dailyMenus"][0]["items"][0];
export type Product = NonNullable<DailyMenuItem["mainProduct"]>;

export type Order = {
  products: Product[];
};

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  menuItem: DailyMenuItem;
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  // DRAG
  rows: Row[];
  setRows: React.Dispatch<React.SetStateAction<Row[]>>;
  products: DragProduct[];
  setProducts: React.Dispatch<React.SetStateAction<DragProduct[]>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const location = useLocation();

  const [menuItem, setMenuItem] = useState<DailyMenuItem>(location.state);
  const [order, setOrder] = useState<Order>({ products: [] });

  //DRAG
  const [rows, setRows] = useState<Row[]>([
    // { id: "r1" },
    // { id: "r2" },
    // { id: "r3" },
  ]);
  const [products, setProducts] = useState<DragProduct[]>([
    // { id: "1", rowId: "r1", content: "product1a" },
    // { id: "2", rowId: "r1", content: "product1b" },
    // { id: "3", rowId: "r2", content: "product2a" },
    // { id: "4", rowId: "r2", content: "product2b" },
    // { id: "5", rowId: "r3", content: "product3a" },
    // { id: "6", rowId: "r3", content: "product3b" },
  ]);
  ////

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        menuItem,
        order,
        setOrder,
        //DRAG
        rows,
        setRows,
        products,
        setProducts,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
