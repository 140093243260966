import { AddButton } from "../components/AddButton";

const Header: React.FC = () => {
  return (
    <div className="flex justify-between pt-8 pb-8 text-white">
      <h1 className="text-3xl font-bold">Składniki</h1>
      <AddButton />
    </div>
  );
};

export { Header };
