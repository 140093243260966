import React, { useCallback, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";

import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from "react-dropzone";

import { z } from "zod";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { formSchema } from "../formSchema";

const DropZone: React.FC<{
  zodImage: ControllerRenderProps<z.infer<typeof formSchema>, "image">;
}> = ({ zodImage }) => {
  const [image, setImage] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const img = acceptedFiles[0];
        setImage(img);

        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onloadend = () => {
          const base64String = reader.result?.toString();
          zodImage.onChange(base64String);
        };
      }
    },
    [zodImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const deleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setImage(null);
    zodImage.onChange("");
  };

  return (
    <div className="flex">
      <div
        {...(getRootProps() as DropzoneRootProps)}
        className="z-10 flex flex-col flex-grow border-bgray-300 border-[1px] text-center w-max-l min-h-32"
      >
        <div className="flex-none text-white text-sm font-medium p-2 pb-1">
          Upuść lub kliknij żeby dodać zdjęcie
        </div>
        {(image || zodImage.value) && (
          <div className="flex-grow p-1 overflow-hidden">
            <div className="relative w-fit mx-auto bg-red-700 ">
              <button
                type="button"
                className="z-20 absolute top-1 right-1 h-7 w-7 bg-bred rounded-full"
                onClick={deleteImage}
              >
                <FontAwesomeIcon icon={faTimes} className="text-white" />
              </button>
              <img
                src={image ? URL.createObjectURL(image) : zodImage.value}
                alt="Preview"
                className="object-contain max-h-60 w-full"
              />
            </div>
          </div>
        )}
        <input {...(getInputProps() as DropzoneInputProps)} />
      </div>
    </div>
  );
};

export { DropZone };
