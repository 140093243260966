import { ChangeEvent, useEffect, useRef } from "react";
import { useLoaderData } from "react-router";

import {
  GetIngredientsFilteredQuery,
  GetIngredientsFilteredQueryVariables,
  GetIngredientsFilteredDocument,
} from "type";

import { Input } from "Shadcn/Input";
import { useStateContext } from "../State";

const SearchInput: React.FC =
  // <{
  //   setIngredients: (
  //     ingredients: GetIngredientsFilteredQuery["ingredients"]
  //   ) => void;
  //   setAreIngredientsLoading: (areLoading: boolean) => void;
  // }>
  // ({ setIngredients, setAreIngredientsLoading }) => {
  () => {
    // const { ingredients } = useLoaderData() as {
    //   ingredients: GetIngredientsFilteredQuery["ingredients"];
    // };

    // const { queryClient, graphQLClient, searchValue, setSearchValue } =
    const { searchValue, setSearchValue } = useStateContext();

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        if (searchValue) {
          inputRef.current.value = searchValue;
        }
      }
    }, []);

    // const filterProducts = async (event: ChangeEvent<HTMLInputElement>) => {
    //   const input = event.target.value.trim();
    //   setSearchValue(input);

    //   queryClient.cancelQueries({ queryKey: ["ingredientsFiletered"] }, {});

    //   if (input.length === 0) {
    //     setAreIngredientsLoading(true);
    //     setIngredients(ingredients);
    //     setAreIngredientsLoading(false);
    //     return;
    //   }

    //   try {
    //     setAreIngredientsLoading(true);
    //     setIngredients(
    //       (
    //         await queryClient.fetchQuery({
    //           queryKey: ["ingredientsFiletered"],
    //           queryFn: ({ signal }) => {
    //             return graphQLClient.request<
    //               GetIngredientsFilteredQuery,
    //               GetIngredientsFilteredQueryVariables
    //             >({
    //               document: GetIngredientsFilteredDocument,
    //               variables: { where: { name: { contains: input } } },
    //               signal,
    //             });
    //           },
    //           staleTime: 0,
    //         })
    //       ).ingredients
    //     );
    //     setAreIngredientsLoading(false);
    //   } catch {
    //     const controller = new AbortController();
    //     controller.abort();
    //   }
    // };

    return (
      <div className="pb-4">
        <Input
          placeholder="Wyszukaj składnik..."
          // onChange={filterProducts}
          onChange={(event) => setSearchValue(event.target.value.trim())}
        ></Input>
      </div>
    );
  };

export { SearchInput };
