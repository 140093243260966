"use client";

import { useEffect, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";
import { ControllerRenderProps, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "Shadcn/Form";
import { Input } from "Shadcn/Input";
import { Button } from "Shadcn/Button";
import { Textarea } from "Shadcn/Textarea";

import { useDeviceContext } from "DeviceContext";

import { pathing } from "pages/staff/pathing";

import { formSchema } from "../formSchema";
import { ActionData, LoaderData } from "../route";
import { Header } from "./Header";
import { PC } from "PC";

function EditForm() {
  const { isMobile } = useDeviceContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("searchValue");

  const navigate = useNavigate();
  const submit = useSubmit();

  const loaderData = useLoaderData() as LoaderData;
  const actionData = useActionData() as ActionData;

  type Status = "IDLE" | "IN_PROGRESS" | "FINISHED";
  const [status, setStatus] = useState<Status>("IDLE");

  useEffect(() => {
    if (actionData) {
      if (actionData.error) {
        form.setError("name", {
          type: actionData.error.__typename,
          message: `Istnieje już składnik o nazwie: "${
            form.getValues().name
          }".`,
        });
      } else {
        setStatus("FINISHED");

        const url = `
          ${(pathing.EditIngredient as string).replace(
            ":ingredientId",
            actionData.data.id
          )}
          ?name=${encodeURIComponent(actionData.data.name)}
          &kilocalories=${encodeURIComponent(actionData.data.kilocalories)}
          &fat=${encodeURIComponent(actionData.data.fat)}
          &saturatedFat=${encodeURIComponent(actionData.data.saturatedFat)}
          &carbohydrates=${encodeURIComponent(actionData.data.carbohydrates)}
          &sugars=${encodeURIComponent(actionData.data.sugars)}
          &fiber=${encodeURIComponent(actionData.data.fiber)}
          &protein=${encodeURIComponent(actionData.data.protein)}
          &salt=${encodeURIComponent(actionData.data.salt)}
          &details=${
            actionData.data.details
              ? encodeURIComponent(actionData.data.details)
              : ""
          }
          &description=${encodeURIComponent(actionData.data.description)}
          &searchValue=${encodeURIComponent(searchValue ? searchValue : "")}
        `.replace(/\s+/g, "");
        navigate(url, { replace: true });
      }
    }
  }, [actionData]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: loaderData.name,
      kilocalories: loaderData.kilocalories
        .toString()
        .replace(".", ",") as unknown as number,
      fat: loaderData.fat.toString().replace(".", ",") as unknown as number,
      saturatedFat: loaderData.saturatedFat
        .toString()
        .replace(".", ",") as unknown as number,
      carbohydrates: loaderData.carbohydrates
        .toString()
        .replace(".", ",") as unknown as number,
      sugars: loaderData.sugars
        .toString()
        .replace(".", ",") as unknown as number,
      fiber: loaderData.fiber.toString().replace(".", ",") as unknown as number,
      protein: loaderData.protein
        .toString()
        .replace(".", ",") as unknown as number,
      salt: loaderData.salt.toString().replace(".", ",") as unknown as number,
      details: loaderData.details ? loaderData.details : undefined,
      description: loaderData.description,
    },
  });

  useEffect(() => {
    form.setFocus("name");
  }, []);

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Copy initial state, and remove values that didn't change from data we pass to mutation.
    setStatus("IN_PROGRESS");
    values.name = values.name.trim();
    values.details = values.details ? values.details.trim() : values.details;
    values.description = values.description.trim();
    submit(
      { id: loaderData.id, ...values },
      { method: "post", encType: "application/json" }
    );
  }

  type allowedFields =
    | "kilocalories"
    | "fat"
    | "saturatedFat"
    | "carbohydrates"
    | "sugars"
    | "protein"
    | "fiber"
    | "salt";

  const onChange =
    <T extends allowedFields>(
      field: ControllerRenderProps<z.infer<typeof formSchema>, T>
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      value = event.target.value.replace(/[^0-9.,]/g, "").replace(".", ",");
      // Split the value into the integer and fractional parts (if a decimal is present)
      const parts = value.split(",");
      if (parts[0]) {
        parts[0] = parts[0].replace(/^0+/, "") || "0"; // Ensures "0" if input was "0000"
        if (parts.length === 1) {
          value = parts[0];
        }
      }
      // Ensure there is only one decimal point in the value
      if (parts.length > 2) {
        value = `${parts[0]},${parts[1]}`;
      }
      // Allow only one digit after the decimal point
      if (parts.length === 2 && parts[1].length > 1) {
        value = `${parts[0]},${parts[1].slice(0, 1)}`;
      }

      field.onChange(value);
    };

  //FIX
  const onChangeSalt =
    <T extends allowedFields>(
      field: ControllerRenderProps<z.infer<typeof formSchema>, T>
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      value = event.target.value.replace(/[^0-9.,]/g, "").replace(".", ",");
      // Split the value into the integer and fractional parts (if a decimal is present)
      const parts = value.split(",");
      if (parts[0]) {
        parts[0] = parts[0].replace(/^0+/, "") || "0"; // Ensures "0" if input was "0000"
        if (parts.length === 1) {
          value = parts[0];
        }
      }
      // Ensure there is only one decimal point in the value
      if (parts.length > 2) {
        value = `${parts[0]},${parts[1]}`;
      }
      // Allow only one digit after the decimal point
      if (parts.length === 2 && parts[1].length > 1) {
        value = `${parts[0]},${parts[1].slice(0, 2)}`;
      }

      field.onChange(value);
    };

  const renderStatus = () => {
    switch (status) {
      case "IDLE":
        return null;
      case "IN_PROGRESS":
        return <span className="text-yellow-400">Zapisywanie...</span>;
      case "FINISHED":
        return <span className="text-bgreen-100">Ukończono!</span>;
      default:
        return null;
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onFocus={() => setStatus("IDLE")}
        className="flex flex-col h-full w-full overflow-auto space-y-2"
      >
        <div className="flex flex-col flex-grow h-full w-full overflow-auto justify-between">
          <div className={"overflow-auto " + (isMobile ? "px-5" : "")}>
            <div className="max-w-xl mx-auto pb-5">
              <Header />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input placeholder="Podaj nazwę..." {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="kilocalories"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Wartość energetyczna (kcal)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość kilokalorii w 100 gram składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fat"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tłuszczy (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość tłuszczu w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="saturatedFat"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>w tym kwasy tłuszczowe (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość kwasów tłuszczowych w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="carbohydrates"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Węglowodany (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość węglowodanów w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sugars"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>w tym cukry (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość cukrów w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fiber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Błonnik (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość błonnika w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="protein"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Białko (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość białka w 100 gramach składnika..."
                        onChange={onChange(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="salt"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sól (na 100g)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Podaj ilość soli w 100 gramach składnika..."
                        onChange={onChangeSalt(field)}
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="details"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Skład</FormLabel>
                    <FormControl>
                      <Textarea
                        className="bg-white"
                        placeholder="Podaj skład..."
                        rows={3}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Opis</FormLabel>
                    <FormControl>
                      <Textarea
                        className="bg-white"
                        placeholder="Opisz składnik..."
                        rows={3}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div
            className={
              "relative flex w-full pr-[var(--scrollbar-width)] " +
              (isMobile ? " bg-bgray-600" : "")
            }
          >
            <div className="flex w-full max-w-xl mx-auto justify-between px-3 py-2 bg-bgray-600">
              <Button
                type="button"
                onClick={() =>
                  navigate(pathing.BrowseIngredients as string, {
                    state: {
                      searchValue:
                        searchValue !== "" && searchValue !== null
                          ? searchValue
                          : undefined,
                    },
                  })
                }
                className="hover:bg-black"
              >
                Wróć
              </Button>
              <div className="flex items-center text-lg text-bgray-100">
                {renderStatus()}
              </div>
              <Button
                type="submit"
                className="text-white bg-bgreen-100 hover:bg-bgreen-300"
              >
                Zapisz
              </Button>
              <PC>
                <div className="absolute bottom-0 right-0 h-full w-[var(--scrollbar-width)] bg-bgray-600"></div>
              </PC>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}

export { EditForm as Form };
