import { PagePC } from "./pc/Page";
import { PageMobile } from "./mobile/Page";
import { PC } from "PC";
import { Mobile } from "Mobile";

export const Page: React.FC = () => {
  return (
    <>
      <PC>
        <div className="flex flex-grow">
          <PagePC />
        </div>
      </PC>
      <Mobile>
        <div className="flex flex-grow flex-col overflow-auto">
          <PageMobile />
        </div>
      </Mobile>
    </>
  );
};
