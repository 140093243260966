import { AddButton } from "../components/AddButton";

const Header: React.FC = () => {
  return (
    <div className="flex justify-between pt-7 pb-4 text-white">
      <h1 className="text-2xl font-bold">Składniki</h1>
      <AddButton />
    </div>
  );
};

export { Header };
