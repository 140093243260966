import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import {
  Ingredient,
  RemoveIngredientDocument,
  RemoveIngredientMutation,
  RemoveIngredientMutationVariables,
} from "type";
import { useStateContext } from "../State";
import { useGqlContext } from "GqlContext";
import { pathing } from "pages/staff/pathing";
import { useRef } from "react";

const Item: React.FC<{ ingredient: Ingredient }> = ({ ingredient }) => {
  // const submit = useSubmit();
  const navigate = useNavigate();
  const { queryClient, graphQLClient } = useGqlContext();
  const { searchValue, setIngredients } = useStateContext();

  // const deleteIngredient = async () => {
  //   submit(ingredient, {
  //     method: "delete",
  //     encType: "application/json",
  //   });
  // };

  // const editIngredient = () => {
  //   submit(
  //     { ...ingredient, searchValue: searchValue ? searchValue : "" },
  //     {
  //       method: "patch",
  //       encType: "application/json",
  //     }
  //   );
  // };

  const editIngredient = () => {
    const url = `${(pathing.EditIngredient as string).replace(
      ":ingredientId",
      ingredient.id
    )}
      ?name=${encodeURIComponent(ingredient.name)}
      &kilocalories=${encodeURIComponent(ingredient.kilocalories)}
      &fat=${encodeURIComponent(ingredient.fat)}
      &saturatedFat=${encodeURIComponent(ingredient.saturatedFat)}
      &carbohydrates=${encodeURIComponent(ingredient.carbohydrates)}
      &sugars=${encodeURIComponent(ingredient.sugars)}
      &fiber=${encodeURIComponent(ingredient.fiber)}
      &protein=${encodeURIComponent(ingredient.protein)}
      &salt=${encodeURIComponent(ingredient.salt)}
      &details=${
        ingredient.details ? encodeURIComponent(ingredient.details) : ""
      }
      &description=${encodeURIComponent(ingredient.description)}
      &searchValue=${encodeURIComponent(searchValue ? searchValue : "")}
    `.replace(/\s+/g, "");
    navigate(url);
  };
  const liRef = useRef<HTMLLIElement | null>(null);

  const deleteIngredient = async () => {
    if (liRef?.current) {
      liRef.current.style.opacity = "0.2";
      liRef.current.style.transition = "opacity 0.4s";
    }

    requestAnimationFrame(async () => {
      const result = (
        await queryClient.fetchQuery({
          queryKey: ["deleteIngredients", ingredient.id],
          queryFn: async () =>
            graphQLClient.request<
              RemoveIngredientMutation,
              RemoveIngredientMutationVariables
            >({
              document: RemoveIngredientDocument,
              variables: { id: ingredient.id },
            }),
          staleTime: 0,
        })
      ).ingredient.remove;
      if (result.errors.length === 0) {
        setIngredients((ingredients) =>
          ingredients.filter((_ingredient) => _ingredient.id !== ingredient.id)
        );
      }
    });
  };

  const format = (value: number): string => value.toString().replace(".", ",");

  return (
    <li
      ref={liRef}
      className="flex items-center gap-4 p-4 bg-bgray-400 hover:bg-bgray-500"
    >
      <div className="flex-1">
        <h4 className="font-semibold text-white">{ingredient.name}</h4>
        <p className="text-sm text-gray-200">
          tłuszcz: {format(ingredient.fat)}g, węglowodany:{" "}
          {format(ingredient.carbohydrates)}
          g, białko: {format(ingredient.protein)}g, wartość energetyczna:{" "}
          {format(ingredient.kilocalories)}
          kcal
        </p>
      </div>
      <div className="flex flex-col">
        <button
          onClick={deleteIngredient}
          className="text-bred hover:text-red-800 mb-2"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
        <button
          onClick={editIngredient}
          className="text-bgreen-100 hover:text-bgreen-300"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      </div>
    </li>
  );
};

export { Item };
