import { Link } from "react-router-dom";

import { SideMenuSheet } from "./SideMenuSheet";

const CompanyCard: React.FC = () => {
  return (
    <Link to="/" className="flex items-center gap-2 text-lg font-semibold">
      <img src={"./LogoWhite.png"} className="w-12 h-12"></img>
      <span>Nasze Bistro</span>
    </Link>
  );
};

const Navbar: React.FC = () => {
  return (
    <header className="flex min-h-16 w-full items-center justify-between bg-bgray-600 px-4 shadow-sm text-white drop-shadow-sideMenu">
      <CompanyCard />
      <SideMenuSheet />
    </header>
  );
};

function MountainIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
    </svg>
  );
}

export { Navbar };
