import { Item } from "./Item";
import { useStateContext } from "../State";
import { useEffect, useState } from "react";
import {
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
} from "type";
import { toLocaleISOString } from "utils/toLocaleISOString";
import { useActionData } from "react-router";
import { ActionData } from "../route";

const ItemList: React.FC = () => {
  const actionData = useActionData() as ActionData;

  const { queryClient, graphQLClient, orders, setOrders, deliveryDate } =
    useStateContext();

  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (actionData) {
      if (actionData.id !== null) {
        setOrders(orders.filter((order) => order.id !== actionData.id));
      }
    }
  }, [actionData]);

  useEffect(() => {
    setIsLoading(true);
    queryClient.cancelQueries({ queryKey: ["ordersFiltered"] }, {});

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      try {
        var orders = (
          await queryClient.fetchQuery({
            queryKey: ["ordersFiltered"],
            queryFn: async ({ signal }) =>
              graphQLClient.request<GetOrdersQuery, GetOrdersQueryVariables>({
                document: GetOrdersDocument,
                variables: {
                  where: deliveryDate
                    ? {
                        deliveryDate: { eq: toLocaleISOString(deliveryDate) },
                      }
                    : null,
                },
                signal,
              }),
            staleTime: 0,
          })
        ).orders;
        setOrders(orders);
        setIsLoading(false);
      } catch {
        const controller = new AbortController();
        controller.abort();
      }
    }, 400);

    setTimeoutId(newTimeoutId);

    return () => clearTimeout(newTimeoutId);
  }, [deliveryDate]);

  return (
    <div className="overflow-auto">
      <ul className="grid gap-y-px">
        {isLoading ? null : orders.length == 0 ? (
          <div className="text-center text-white">Brak zamówień</div>
        ) : (
          orders
            .sort((a, b) =>
              a.dishes[0].mainProduct.name.localeCompare(
                b.dishes[0].mainProduct.name,
                "pl-PL"
              )
            )
            .map((order) => <Item key={order.id} order={order} />)
        )}
      </ul>
    </div>
  );
};

export { ItemList };
