import { List } from "./List";
import { Input } from "./Input";

const Page: React.FC = () => {
  return (
    <div className="flex w-full h-full">
      <div className="flex h-full flex-col flex-grow max-w-xl mx-auto">
        <Input />
        <List />
      </div>
    </div>
  );
};

export { Page };
