import { useEffect, useState } from "react";

import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  GetDailyMenusForCopyDialogQuery,
  GetDailyMenusForCopyDialogDocument,
  CopyWeekMutation,
  CopyWeekDocument,
  GetDailyMenusForWeekQuery,
  GetDailyMenusForWeekDocument,
} from "type";

import { Button } from "Shadcn/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "Shadcn/Dialog";

import { toLocaleISOString } from "utils/toLocaleISOString";

import { useStateContext } from "../state/StateProvider";
import { DayKey } from "../state/types";

type DailyMenu = {
  id: string;
  date: Date;
};

type WeekMenu = {
  weekStart: Date;
  weekEnd: Date;
  menus: DailyMenu[];
};

type MonthlyMenus = {
  month: string;
  monthNo: number;
  weeks: WeekMenu[];
};

const CopyWeekButton: React.FC = () => {
  const { queryClient, graphQLClient, week, setWeek } = useStateContext();
  const [dailyMenus, setDailyMenus] = useState<
    GetDailyMenusForCopyDialogQuery["dailyMenus"]
  >([]);
  const [monthlyMenus, setMonthlyMenus] = useState<MonthlyMenus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedWeek, setSelectedWeek] = useState<WeekMenu | null>(null);
  const [copiedDailyMenus, setCopiedDailyMenus] = useState<
    GetDailyMenusForWeekQuery["dailyMenus"]
  >([]);

  const getDailyMenus = () => {
    setLoading(true);
    queryClient
      .fetchQuery({
        queryKey: ["dailyMenusForWeek"],
        queryFn: async () =>
          graphQLClient.request<GetDailyMenusForCopyDialogQuery>({
            document: GetDailyMenusForCopyDialogDocument,
          }),
        staleTime: 0,
      })
      .then(({ dailyMenus }) => {
        setDailyMenus(dailyMenus);
      });
  };

  useEffect(() => {
    if (dailyMenus) {
      const menus = dailyMenus.map((menu) => ({
        id: menu.id,
        date: new Date(menu.date),
      }));

      const groupedByMonth = groupByMonthAndWeek(menus);
      setMonthlyMenus(groupedByMonth);
      setLoading(false);
    }
  }, [dailyMenus]);

  const groupByMonthAndWeek = (menus: DailyMenu[]) => {
    const grouped: { [key: string]: { [week: string]: WeekMenu } } = {};
    const monthToNumber: { [key: string]: number } = {}; // Helper for month numbers

    menus.forEach((menu) => {
      const month = menu.date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const monthNo = new Date(menu.date).getMonth() + 1; // Month number (1-12)
      const weekStart = getMondayOfWeek(menu.date);

      if (
        week.toList().some((day) => day.date == toLocaleISOString(weekStart))
      ) {
        return;
      }

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 4);

      if (!grouped[month]) {
        grouped[month] = {};
      }

      const weekKey = `${toLocaleISOString(weekStart)}_${toLocaleISOString(
        weekEnd
      )}`;

      if (!grouped[month][weekKey]) {
        grouped[month][weekKey] = {
          weekStart,
          weekEnd,
          menus: [],
        };
      }
      grouped[month][weekKey].menus.push(menu);

      // Handle overlapping weeks
      if (weekEnd.getMonth() !== weekStart.getMonth()) {
        const nextMonth = weekEnd.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (!grouped[nextMonth]) {
          grouped[nextMonth] = {};
        }
        if (!grouped[nextMonth][weekKey]) {
          grouped[nextMonth][weekKey] = {
            weekStart,
            weekEnd,
            menus: [],
          };
        }
        grouped[nextMonth][weekKey].menus.push(menu);
      }

      monthToNumber[month] = monthNo; // Store month number
    });

    return Object.keys(grouped).map((month) => ({
      month,
      monthNo: monthToNumber[month], // Add monthNo
      weeks: Object.values(grouped[month]),
    }));
  };

  const getMondayOfWeek = (date: Date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  const getDay = (date: Date): string => {
    return toLocaleISOString(date).split("-")[2];
  };

  const CopyWeek = () => {
    if (selectedWeek) {
      queryClient
        .fetchQuery({
          queryKey: ["copyWeek"],
          queryFn: async () => {
            const endDate = new Date(week.monday.date);
            endDate.setDate(endDate.getDate() + 4);
            try {
              await graphQLClient.batchRequests<
                [
                  { data: CopyWeekMutation },
                  { data: GetDailyMenusForWeekQuery }
                ]
              >(
                [
                  {
                    document: CopyWeekDocument,
                    variables: {
                      from: toLocaleISOString(selectedWeek.weekStart),
                      to: week.monday.date,
                    },
                  },
                  {
                    document: GetDailyMenusForWeekDocument,
                    variables: {
                      startDate: week.monday.date,
                      endDate: toLocaleISOString(endDate),
                    },
                  },
                ],
                {
                  Accept: "multipart/mixed",
                }
              );
            } catch (ex: any) {
              const res: string = ex.message as string;
              if (!res.includes("errors")) {
                return res
                  .split("-----")[0]
                  .split("---")
                  .slice(1, 3)
                  .map((raw: string) =>
                    JSON.parse(raw.slice(raw.indexOf("{")))
                  );
              } else {
                throw ex;
              }
            }
          },
          staleTime: 0,
        })
        .then((response: any) =>
          setCopiedDailyMenus(response[1]["data"]["dailyMenus"])
        );
    }
  };

  const parseMenu = (
    dayDate: string,
    dayKey: DayKey,
    menu: GetDailyMenusForWeekQuery["dailyMenus"][0] | null
  ) => {
    return {
      id: menu === null ? null : menu.id,
      date: dayDate,
      quantity: menu?.quantity as number,
      items:
        menu === null
          ? []
          : menu.items.map((item) => {
              return {
                id: item.id,
                dayKey: dayKey,
                quantity: item.quantity!,
                mainProduct: item.mainProduct ? item.mainProduct : null,
                subProductLists: item.subProductLists.map((list) => {
                  return {
                    id: list.id,
                    dayKey: dayKey,
                    itemId: item.id,
                    products: list.products.map((product) => {
                      return {
                        id: product.id,
                        name: product.name,
                      };
                    }),
                  };
                }),
              };
            }),
    };
  };

  useEffect(() => {
    const daysWithMenus = week.toList().map((day) => {
      let menu =
        copiedDailyMenus.filter(
          (menu) => menu.date.split("T")[0] === day.date
        )[0] ?? null;
      return {
        date: day.date,
        key: day.key,
        menu: menu,
      };
    });

    const monday = daysWithMenus.filter((d) => d.key === "monday")[0];
    const tuesday = daysWithMenus.filter((d) => d.key === "tuesday")[0];
    const wednesday = daysWithMenus.filter((d) => d.key === "wednesday")[0];
    const thursday = daysWithMenus.filter((d) => d.key === "thursday")[0];
    const friday = daysWithMenus.filter((d) => d.key === "friday")[0];

    setWeek({
      ...week,
      monday: {
        ...week.monday,
        ...parseMenu(monday.date, "monday", monday.menu),
      },
      tuesday: {
        ...week.tuesday,
        ...parseMenu(tuesday.date, "tuesday", tuesday.menu),
      },
      wednesday: {
        ...week.wednesday,
        ...parseMenu(wednesday.date, "wednesday", wednesday.menu),
      },
      thursday: {
        ...week.thursday,
        ...parseMenu(thursday.date, "thursday", thursday.menu),
      },
      friday: {
        ...week.friday,
        ...parseMenu(friday.date, "friday", friday.menu),
      },
    });
  }, [copiedDailyMenus]);

  return (
    <div className="flex h-fit w-fit items-center bg-bgray-600">
      <Dialog onOpenChange={() => setSelectedWeek(null)}>
        <DialogTrigger asChild>
          <button
            onClick={getDailyMenus}
            className="items-center flex h-[52px] w-[52px] gap-1 p-3 text-white hover:bg-black"
          >
            <FontAwesomeIcon icon={faCopy} className="h-full w-full" />
          </button>
        </DialogTrigger>
        {loading ? null : (
          <DialogContent
            aria-describedby={undefined}
            className="w-[600px] max-w-full border-none text-white bg-bgray-600"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                Wybierz tydzień do skopiowania
              </DialogTitle>
            </DialogHeader>
            <div className="flex flex-col h-72 overflow-auto">
              {monthlyMenus
                .sort((prev, next) => next.monthNo - prev.monthNo)
                .map((month) => (
                  <div key={month.month} className="flex flex-col">
                    <div className="font-bold mb-1">{month.month}</div>
                    <div className="flex gap-1.5">
                      {month.weeks.map((week) => (
                        <button
                          key={toLocaleISOString(week.weekStart)}
                          className={`flex h-9 w-20 items-center justify-center mb-2 bg-black hover:bg-bgray-400 ${
                            selectedWeek
                              ? selectedWeek.weekStart === week.weekStart &&
                                selectedWeek.weekEnd === week.weekEnd
                                ? "text-black bg-white"
                                : ""
                              : ""
                          }`}
                          onClick={() => setSelectedWeek(week)}
                        >
                          {getDay(week.weekStart)} - {getDay(week.weekEnd)}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <DialogFooter className="flex ">
              <div className="flex w-full items-center justify-center text-lg font-medium">
                {selectedWeek
                  ? `wybrano: ${toLocaleISOString(selectedWeek.weekStart)
                      .split("-")
                      .reverse()
                      .join(".")} 
                      - 
                      ${toLocaleISOString(selectedWeek.weekEnd)
                        .split("-")
                        .reverse()
                        .join(".")}`
                  : ""}
              </div>
              <DialogClose asChild>
                <Button
                  disabled={selectedWeek ? false : true}
                  type="button"
                  className="rounded-lg bg-bgreen-100 hover:bg-bgreen-300 "
                  onClick={CopyWeek}
                >
                  Skopiuj
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export { CopyWeekButton };
