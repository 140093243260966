import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  useRouteError,
} from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { Button } from "Shadcn/Button";

import { useAuthContext } from "AuthContext";
import { getMenuRoute } from "./menu/browse/Route";
import { getOrderRoute } from "./menu/order/Route";
import { getKanbanRoute } from "./menu/kanban/Route";

export const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="text-white">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

const Root: React.FC = () => {
  const { logout } = useAuthContext();
  return (
    <>
      {/* <div className="hidden md:block">
        <RootPC />
      </div> */}
      {/* <div className="md:hidden"> */}
      <div className="">
        <div className="h-screen flex flex-col">
          {/* <Navbar /> */}
          <div className="absolute z-50 bottom-5 left-5 ">
            <Button onClick={logout}>Wyloguj się</Button>
          </div>
          <div className="flex flex-grow flex-col overflow-auto bg-bgray-500">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      getMenuRoute(queryClient, graphQLClient),
      getOrderRoute(queryClient, graphQLClient),
      getKanbanRoute(queryClient, graphQLClient),
    ],
  };
};

const getRouter = (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  createBrowserRouter([getRoute(queryClient, graphQLClient)], {
    future: { v7_relativeSplatPath: true },
  });

export { getRouter as GetCustomerRouter };
