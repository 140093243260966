import { LoaderFunctionArgs, RouteObject } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { z } from "zod";

import {
  EditHalfProductDocument,
  EditHalfProductMutation,
  EditHalfProductMutationVariables,
  GetHalfProductEditPageDataQuery,
  GetHalfProductEditPageDataQueryVariables,
  GetHalfProductEditPageDataDocument,
} from "type";

import { pathing } from "pages/staff/pathing";

import { StateProvider } from "./State";
import { formSchema } from "./formSchema";
import { Form } from "./components/Form";

export type LoaderData = {
  currentHalfProduct: GetHalfProductEditPageDataQuery["halfProducts"][0];
  parentsIds: string[];
};

export type ActionData = {
  data: string | null;
  error: EditHalfProductMutation["halfProduct"]["edit"]["errors"][0] | null;
};

const getLoader =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<LoaderData> => {
    const result = await queryClient.fetchQuery({
      queryKey: ["createHalfProduct"],
      queryFn: async () =>
        graphQLClient.request<
          GetHalfProductEditPageDataQuery,
          GetHalfProductEditPageDataQueryVariables
        >({
          document: GetHalfProductEditPageDataDocument,
          variables: {
            id: params.halfProductId as string,
          },
        }),
      staleTime: 0,
    });
    return {
      currentHalfProduct: result.halfProducts[0],
      parentsIds: result.allParentsIdsForForHalfProduct,
    };
  };

const getAction =
  (queryClient: QueryClient, graphQLClient: GraphQLClient) =>
  async ({ request }: { request: Request }) => {
    const formData = (await request.json()) as z.infer<typeof formSchema>;
    const result = (
      await queryClient.fetchQuery({
        queryKey: ["editHalfProduct"],
        queryFn: async () =>
          graphQLClient.request<
            EditHalfProductMutation,
            EditHalfProductMutationVariables
          >({
            document: EditHalfProductDocument,
            variables: {
              input: {
                id: formData.id,
                name: formData.name,
                ingredients:
                  formData.ingredients.length === 0
                    ? null
                    : formData.ingredients.map((ingredient) => ({
                        id: ingredient.id,
                        weight: ingredient.weight,
                      })),
                subHalfProducts:
                  formData.subHalfProducts.length === 0
                    ? null
                    : formData.subHalfProducts.map((halfProduct) => ({
                        id: halfProduct.id,
                        weight: halfProduct.weight,
                      })),
              },
            },
          }),
        staleTime: 0,
      })
    ).halfProduct.edit;
    if (result.errors.length != 0) {
      const error = result.errors.find(
        (error) => error.__typename === "HalfProductNameTakenError"
      );
      if (!error) {
        // TODO Unhandled error, what to do? Probably navigate to some global error page.
        // TODO Request should also have try catch to do the same.
      }
      return {
        data: null,
        error: error ?? null,
      };
    }
    return {
      data: result.halfProduct?.id,
      error: null,
    };
  };

const getRoute = (
  queryClient: QueryClient,
  graphQLClient: GraphQLClient
): RouteObject => {
  return {
    path: pathing.EditHalfProduct as string,
    loader: getLoader(queryClient, graphQLClient),
    action: getAction(queryClient, graphQLClient),
    element: (
      <StateProvider queryClient={queryClient} graphQLClient={graphQLClient}>
        <Form />
      </StateProvider>
    ),
  };
};

export { getRoute as getEditHalfProductRoute };
