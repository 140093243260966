import { z } from "zod";

const requiredError = "Pole wymagane.";
const invalidValue = "Nieprawidłowa wartość.";

export const Details = z.object({
  kilocalories: z.number(),
  fat: z.number(),
  saturatedFat: z.number(),
  carbohydrates: z.number(),
  sugars: z.number(),
  fiber: z.number(),
  protein: z.number(),
  salt: z.number(),
});

export const HalfProduct = z.object({
  id: z.string(),
  name: z.string(),
  weight: z
    .number({ required_error: "Podaj wagę." })
    .gt(0, { message: invalidValue }),
  details: Details,
});

export const Ingredient = z.object({
  id: z.string(),
  name: z.string(),
  weight: z
    .number({ required_error: "Podaj wagę." })
    .gt(0, { message: invalidValue }),
  details: Details,
});

export const formSchema = z.object({
  id: z.string(),
  name: z
    .string({ required_error: requiredError })
    .min(2, { message: "Nazwa musi zawierać conajmniej 2 litery." })
    .regex(/^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s0-9-(),]+$/, {
      message: "Niedozwolone znaki.",
    }),
  ingredients: z.array(Ingredient).refine(
    (ingredients) => {
      return ingredients.every((ingredient) => {
        return (
          ingredient.weight !== undefined &&
          ingredient.weight !== null &&
          ingredient.weight > 0
        );
      });
    },
    { message: "Któryś z składników ma niepoprawną wagę." }
  ),
  subHalfProducts: z.array(HalfProduct).refine(
    (products) => {
      return products.every((product) => {
        return (
          product.weight !== undefined &&
          product.weight !== null &&
          product.weight > 0
        );
      });
    },
    { message: "Któryś z półproduktów ma niepoprawną wagę." }
  ),
});
