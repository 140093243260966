import React, { createContext, useContext, ReactNode, useState } from "react";
import { QueryClient } from "@tanstack/react-query";

import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { GraphQLClient } from "graphql-request/build/entrypoints/main";

import { formSchema } from "./formSchema";

interface StateContext {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  form: UseFormReturn<z.infer<typeof formSchema>>;
}

const StateContext = createContext<StateContext | undefined>(undefined);

export const StateProvider: React.FC<{
  queryClient: QueryClient;
  graphQLClient: GraphQLClient;
  children: ReactNode;
}> = ({ queryClient, graphQLClient, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mainProduct: undefined,
      subProducts: [],
      totalQuantity: 1,
    },
  });

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        queryClient,
        graphQLClient,
        form,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContext => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
