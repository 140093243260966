import { ProductionItem } from "../State";

import { toLocaleISOString } from "utils/toLocaleISOString";

import { PDFViewer } from "./PDFViewer";
import { PDFDownload } from "./PDFDownload";
import { Admin } from "Admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { pathing } from "pages/staff/pathing";

const Item: React.FC<{
  productionItem: ProductionItem;
}> = ({ productionItem }) => {
  const navigate = useNavigate();

  const format = (date: Date) =>
    toLocaleISOString(date).split("-").reverse().join(".");

  return (
    <li className="flex items-center gap-4 p-4 text-white bg-bgray-400 hover:bg-bgray-500">
      <div className="flex-1">
        <h4 className="font-semibold">
          Na dzień: {format(new Date(productionItem.deliveryDate))}
        </h4>
        <div
          className="inline-block text-sm lowercase text-bgray-100"
          style={{}}
        >
          {productionItem.mainProduct.name} x
          {productionItem.mainProduct.quantity}
          {productionItem.subProducts.length > 0 ? ", " : null}
        </div>{" "}
        {productionItem.subProducts.map((product, index) => (
          <>
            <div
              key={product.id}
              className="inline-block text-sm lowercase text-bgray-100"
            >
              {product.name} x{product.quantity}
            </div>
            {index != productionItem.subProducts.length - 1 ? ", " : null}
          </>
        ))}
      </div>
      <div className="font-semibold">
        Liczba: {productionItem.totalQuantity}
      </div>
      <button
        className="flex text-white hover:text-bgray-100 p-1"
        onClick={() =>
          navigate(
            (pathing.ProductDetails as string).replace(
              ":productId",
              productionItem.mainProduct.id
            ),
            {
              state: {
                backRef: pathing.BrowseProduction as string,
                quantity:
                  productionItem.totalQuantity *
                  productionItem.mainProduct.quantity,
              },
            }
          )
        }
      >
        <FontAwesomeIcon icon={faCircleInfo} />
      </button>
      <Admin>
        <PDFViewer productionItem={productionItem} />
        <PDFDownload productionItem={productionItem} />
      </Admin>
    </li>
  );
};

export { Item };
